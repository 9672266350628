/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 3.1.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";

//Components
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/ribbons";
@import "custom/components/print";
@import "custom/components/preloader";

//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/logout";
@import "custom/pages/faq";
@import "custom/pages/maintenance";
@import "custom/pages/timeline";
@import "custom/pages/email";
@import "custom/pages/sitemap";
@import "custom/pages/search-results";
@import "custom/pages/pricing";
@import "custom/pages/gallery";
@import "custom/pages/coming-soon";
@import "custom/pages/profile";
@import "custom/pages/taskboard";
@import "custom/pages/ecommerce";

// Vendors
@import "vendor/metisMenu";


// Plugins
@import "custom/plugins/waves";
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/calendar";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/slimscroll";
@import "custom/plugins/toaster";
@import "custom/plugins/sweetalert";
@import "custom/plugins/custom-select";
@import "custom/plugins/tippy";
@import "custom/plugins/nestable-list";
@import "custom/plugins/hopscotch";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/chartjs";
@import "custom/plugins/chartist";
@import "custom/plugins/c3";
@import "custom/plugins/ricksaw";
@import "custom/plugins/responsive-table";
@import "custom/plugins/footables";
@import "custom/plugins/bootstrap-tables";
@import "custom/plugins/tablesaw";
@import "custom/plugins/jsgrid";
@import "custom/plugins/multiple-select";
@import "custom/plugins/autocomplete";
@import "custom/plugins/bootstrap-select";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/flatpickr";
@import "custom/plugins/clockpicker";
@import "custom/plugins/summernote";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/dropzone";
@import "custom/plugins/dropify";
@import "custom/plugins/x-editable";
@import "custom/plugins/cropper";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";
@import "custom/plugins/mapeal-maps";


.home {
    background-color: #F8F8FB;
    padding: 0px;
    font-family: Roboto;
}

.imageLogoin {
    width: 100%;
    position: absolute;
    top: -26px;
}

.PCard {
    border-radius: 18px;
    box-shadow: 1px 3px 18px #e3e3e3;
    background: #fff;
}

.inputLogin {
    background: #FAFAFB 0% 0% no-repeat padding-box;
    border: 1px solid #F1F1F5;
    border-radius: 11px;
    height: 40px;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #5100dd;
    border-color: #34038b;
    background-color: #5d00ff;
}

@media only screen and (max-width: 600px) {
    .backgroundLogin {
        display: none;
    }
}

.navbar-custom {
    height: 79px;
    padding-top: 4px;
    background: #ffffff;
}

.navbar-custom .topnav-menu .nav-link {
    padding: 0 15px;
    color: #4E7D96;
    min-width: 32px;
    display: block;
    line-height: 70px;
    text-align: center;
    max-height: 70px;
}


.content-page {
    margin-left: 240px;
    overflow: hidden;
    padding: 0 15px 5px 15px;
    min-height: 80vh;
    margin-top: 100px;
}

.navbar-custom {
    box-shadow: none;
}

.titleheader {
    font-size: 28px;
    color: #6868ab !important;
    font-family: Poppins;
    font-weight: 500;
}

.btn-danger {
    color: #fff;
    background-color: #ff1036;
    border-color: #ff1036;
    border-radius: 7px;
    font-size: 15px;
    padding: 6px 15px;
}

.HeaderstatusContent {
    width: 100%;
    overflow: auto;
    display: -webkit-box;
    margin-top: 15px;
    padding-bottom: 20px;
}

.HeaderstatusContent .boxStatus {
    width: 127px;
    height: 151px;
    border-radius: 10px;
    background: #e9e9e9;
    text-align: center;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    margin-left: 12px;
    padding: 18px;
    -webkit-box-shadow: 1px 1px 8px #4e7d960f;
    box-shadow: 1px 1px 8px #4e7d960f;
    position: relative;
}

.HeaderstatusContent .backGroundWhite {
    background: #fff;
}

.HeaderstatusContent .backGroundWhite svg {
    position: absolute;
    left: 1px;
    top: 0px;
}

.HeaderstatusContent .boxStatus a {
    position: absolute;
    left: 0;
    bottom: 21px;
    width: 100%;
    padding: 14px;
}

.tabelStatus {
    height: 50px;
    background: #fff;
    border: none;
    border-radius: 9px 9px 0 0;
}

.tabelStatus tr th {
    height: 50px;
    border: none;
    padding: 0 48px;
}

.table thead th {
    vertical-align: inherit;
    border-bottom: 2px solid #dee2e6;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-bottom: 0 !important;
    border-radius: 0;
    margin-top: 4px !important;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
    padding-right: 30px;
    height: 60px;
    border: none;
}

.table td,
.table th {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
}

.table img {
    width: 37px;
    height: 37px;
    border-radius: 5px;
    margin-right: 7px;
    border: 1px dashed #7d57c2;
}

.table .Approval {
    background: #e087b71c;
    padding: 6px 35px;
    border-radius: 5px;
    color: #ff61b6;
}

.table .Draft {
    background: #e9e9e9;
    padding: 6px 35px;
    border-radius: 5px;
    color: #898787;
}

.table .TreatmentSetup {
    background: #ebc27142;
    padding: 6px 35px;
    border-radius: 5px;
    color: #f1a206;
}

.table .Fabrication {
    background: #6869ab66;
    padding: 6px 35px;
    border-radius: 5px;
    color: #5f61ad;
}

.table tbody tr:hover {
    background: #efefef;
}

.table .Revision {
    background: #85a1ac17;
    padding: 6px 35px;
    border-radius: 5px;
    color: #85A1AC;
}

.table .Delivered {
    background: #8ad56f18;
    padding: 6px 35px;
    border-radius: 5px;
    color: #8AD56F;
}

.table .Processing {
    background: #ea435c21;
    padding: 6px 35px;
    border-radius: 5px;
    color: #EA435D;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 1.4em !important;
    display: block;
    opacity: 0.3;
}

.dataTables_scrollHeadInner {
    margin-bottom: 2px !important;
}

.table td,
.table th {
    padding: 0.5rem;
    vertical-align: inherit;
    border-top: 1px solid #dee2e6;
    text-align: left;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #7e57c2;
    border-color: #7e57c2;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-start !important;
    margin-top: 16px !important;
}

.pagination-rounded .page-link {
    border-radius: 6px !important;
    margin: 0 3px;
    border: none;
}


div.dataTables_wrapper div.dataTables_info {
    padding-top: 1.85em !important;
    white-space: nowrap;
}

.imageCoverCases {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .4;
    z-index: -1;
}

.tabNavTitleL {
    width: 100%;
    height: 10px;
    background: #fff;
    border-radius: 20px 0px 0px 20px;
    margin-bottom: 5px;
}

.tabNavTitleR {
    width: 100%;
    height: 10px;
    background: #fff;
    border-radius: 0px 7px 7px 0px;
    margin-bottom: 5px;
}

.tabNavTitle {
    width: 100%;
    height: 10px;
    background: #fff;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 5px;
}

.boxTabNavTitle {
    width: 135px;
    display: inline-block;
    cursor: pointer;
}

.boxTabNavTitle a {
    font-family: 'Poppins';
    font-weight: 100;
    font-size: 11px;
}

.navActive a {
    color: #6868AC !important;
    font-weight: 600 !important;
}

.navActive div {
    background: #6868AC;
}


.cardheaderCases {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    color: #6868ab;
    background: #fff;
    margin-bottom: 1px;
    border-radius: 10px 10px 0px 0px;
}

.cardBodyCases {
    background: #fff;
    border-radius: 0px 0px 10px 10px;
}

.btnContinue {
    background: #6868ab;
    color: #fff !important;
    border: 1px solid #6868ab;
    border-radius: 4px;
    padding: 8px 84px;
    font-size: 17px;
    font-family: 'Poppins';
    cursor: pointer;
}

.btnContinue:hover {
    background: #5858ad;
}

.btnContinue:active {
    background: #6161d1;
}

.boxTabsn {
    display: none;
}

.active {
    display: block;
}

.imagePatient {
    width: 100%;
    border-radius: 6px;
    border: 0.4px solid #f1f1f1;
    cursor: pointer;
    height: 136px;
}

.imgAvatar {
    cursor: pointer;
    width: 131px;
    box-shadow: 1px 1px 8px #f1f1f1;
    border-radius: 11px;
}

.itemsOrder {
    background: #fff;
    margin-top: 1px;
    min-height: 52px;
}

.inputCasesorder {
    background: #ffffff 0 0 no-repeat padding-box;
    border-radius: 6px;
    width: fit-content;
    border: none;
    text-align: center;
}

.titleFormCreateOrder {
    font-size: 15px;
    margin: 0;
}

.titleGROUPCREATEORDER {
    justify-content: center;
    align-items: center;
}

.inputItemsCreateOrder {
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 0;
    border-bottom: 1px dashed;
    width: 100%;
    text-align: left;
}

.inputChekbox {
    border: none;
    border-bottom: 1px dashed;
    outline: none;
    width: 89px;
    text-align: center;
}

.higtAuto {
    height: auto !important;
}

.IPRDISGIN svg {
    cursor: pointer;
}

.IPRDISGIN svg:hover {
    -webkit-filter: drop-shadow(1px 1px 5px #6868aa);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.divColome {
    text-align: center;
    padding: 1px;
}

.divColome div {
    width: 0;
    border: 1px dashed;
    height: 36px;
    margin-left: 6.3px;
    margin-right: 7px;
    color: red;
    opacity: 0;
}

.divColome a {
    font-size: 11px;
    font-weight: 500;
    color: #6868aa !important;
    opacity: 0;
}

.boxIPROption {
    width: 100%;
    height: 100%;
    background: #ffffffb3;
    position: absolute;
    top: 0;
    left: 0;
}

.cardIPRBOX {
    z-index: 99999999;
    position: relative;
    box-shadow: 4px 4px 10px #dadaff;
    margin-top: 12px;
}

.activeIPR {
    -webkit-filter: drop-shadow(1px 1px 5px #6868aa);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.boxSVGIPR {
    display: inline-flex;
    width: 100%;
    text-align: center;
    align-items: center;
    text-align: center;
    margin-bottom: 8px;
    border-bottom: 1px solid #f3efef;
    padding-bottom: 4px;
}

.showSVGIPR {
    width: 16px;
}

.titleSGIPR {
    width: 100%;
    font-size: 21px;
}

.btn-outline-darkK:hover {
    color: #fff;
    background-color: #d5d5d5;
    border-color: #7c8087;
}

.dropify-wrapper {
    border: none !important;
    height: 100px !important;
    width: 100% !important;
    padding: 0 !important;
}

.dropify-font-upload:before,
.dropify-wrapper .dropify-message span.file-icon:before {
    color: #6868aa;
}

.boxChat {
    width: 100%;
}

.boxChat .boxInput {
    width: 100%;
    display: inline-flex;
    margin-top: 20px;
    align-items: flex-end;

    .inputMessage {
        width: 100%;
        margin-right: 10px;
        border: 1px solid #cbcbcb;
        border-radius: 32px;
        height: max-content;
        padding: 10px 16px;
        max-height: 150px;

        textarea {
            display: block;
            width: 100%;
            border: 0;
            outline: 0;
            resize: none;
            overflow-y: hidden;
            background: rgba(255, 255, 255, 0);
            font-size: 14px;
            color: #343434;
            height: 25px;
        }
    }

    .btnMessage {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .btnMessage:hover {
        -webkit-filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, .5));
    }

    .btnMessage:active {
        -webkit-filter: drop-shadow(1px 5px 8px rgba(0, 0, 0, .5));
    }

    .btnRercord {
        border: 1px solid #6969ab;
        border-radius: 50%;
        height: 50px;
        width: 54px;
        fill: #6969ab;
    }

    .btnRercord:active {
        background: #6969ab;
        fill: #fff !important;
    }

}

.boxChat .boxItems {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding: 0px 15px;
}

.boxChat .boxItems .itemChat {
    display: inline-flex;
    width: 100%;
    margin-top: 16px;
    direction: rtl;
}

.boxChat .boxItems .itemChat .imageAvatar {
    margin-right: 7px;

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: none;
    }
}

.boxChat .boxItems .itemChat .Message {
    background: #f2f5f7;
    color: #000;
    padding: 11px 18px;
    border-radius: 11px;
    font-size: 11px;
    max-width: 50%;
}

.boxChat .boxItems .dateMessage {
    text-align: right;
    color: #6868ab;
    font-weight: 500;
}

.boxChat .boxItems .other {
    direction: ltr !important;
}

.boxChat .boxItems .other .imageAvatar img {
    display: block !important;
}

.boxChat .boxItems .other .dateMessage {
    text-align: left;
}

.tabPanels {
    margin-top: 28px;
}

.errorInput {
    border: 1px dashed red;
}

.sucssInput {
    border: 1px dashed rgb(21, 214, 4);
}

.imageUserTopBar {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
}

.notifiError {
    width: 100%;
    background: #a5111121;
    color: #767474;
    padding: 3px 24px;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
    font-family: initial;
}

.ladda-button[data-style=expand-right][data-loading] {
    padding-right: 56px;
    background: #000;
    color: #fff !important;
}

.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}


.notFondPage .four_zero_four_bg h1 {
    font-size: 80px;
}

.notFondPage .four_zero_four_bg h3 {
    font-size: 80px;
}

.notFondPage .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}

.notFondPage .contant_box_404 {
    margin-top: -50px;
}

.createOrderImage .dropify-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: #ffffff00;
    border: 1px dashed #dbdbdb !important;
}

.dropify-font-upload:before,
.dropify-wrapper .dropify-message span.file-icon:before {
    content: '' !important;
}

.dropify-wrapper .dropify-message p {
    margin: 5px 0 0;
    display: none;
}

.createOrderImage .divProsess {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000007d;
    border-radius: 10px;
}

.createOrderImage .textProsess {
    position: absolute;
    top: 39%;
    left: 36%;
    color: #fff !important;
    font-size: 19px;
    font-weight: 600;
}

.createOrderImage .loaderProsess {
    position: absolute;
    top: 14%;
    left: 16%;
}

.dragdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    cursor: pointer;
}

.dragdropHover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    background-size: 30px 30px;
    background-image: -webkit-linear-gradient(135deg, #F6F6F6 25%, transparent 25%, transparent 50%, #F6F6F6 50%, #F6F6F6 75%, transparent 75%, transparent);
    background-image: linear-gradient(-45deg, #F6F6F6 25%, transparent 25%, transparent 50%, #F6F6F6 50%, #F6F6F6 75%, transparent 75%, transparent);
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
}

.dragdrop .ErrorFile {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    align-items: center;
    display: inline-flex;
    width: 100%;
    height: 100%;
    background: #d90b0b9c;
    color: #fff;
    text-shadow: 1px 1px 8px #000;
    border-radius: 11px;
}

.dragdrop .imageIcon {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 84px;
    border: 1px dashed #6868ab4a;
    border-radius: 15px;
    text-align: center;
}


.dragdrop .imageIcon .dragDrop-extension {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: -.03em;
    font-size: 13px;
    width: 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dragdrop .imageIcon .icon:before {
    font-family: dropify;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-left: 0.2em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    content: '\e801';
}

#itemsUploadPrograss .icon:before {
    font-family: dropify;
    font-style: normal;
    font-weight: 100;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 0;
    content: '\e801';
    color: #000;
}

#itemsUploadPrograss .dragDrop-extension {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: -.03em;
    font-size: 11px;
    width: 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
}

#itemsUploadPrograss .imageIcon {
    display: block;
    width: 51px;
    height: 58px;
    font-size: 40px;
    border: 1px dashed #6868ab4a;
    border-radius: 15px;
    text-align: center;
    position: relative;
    float: left;
    margin-right: 7px;
}

.dragdrop:hover .SucsessFile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: inline-flex !important;
    display: inline-flex;
    border-radius: 11px;
    background: #00000078;
}

.dragdrop .SucsessFile {
    display: none;
}

.dragdrop .SucsessFile {
    width: 100%;
}

.dragdrop .SucsessFile div {
    width: 100%;
}

.dragdrop .SucsessFile .btnSuccessfuly {
    background: green;
    color: #fff;
    padding: 5px 13px;
    border-radius: 5px;
    margin-right: 15px;
    border: 1px solid;
    position: relative;
    z-index: 99999999999;
}

.dragdrop .SucsessFile .btnRemove {
    background: rgb(201, 4, 37);
    color: #fff;
    padding: 5px 13px;
    border-radius: 5px;
    border: 1px solid;
    position: relative;
    z-index: 99999999999;
}

.dragdrop .SucsessFile .btnSuccessfuly:hover {
    box-shadow: 4px 2px 8px #fff;
    color: #fff;
}

.dragdrop .SucsessFile .btnRemove:hover {
    box-shadow: 4px 2px 8px #fff;
    color: #fff;
}

.viewer-in {
    opacity: 1;
    z-index: 9999999999999 !important;
}

.imageStl {
    object-fit: contain;
    width: 147px;
    height: 66px;
    padding: 12px;
}

.boxSTL {
    border: 1px dashed #d9d8d8;
    border-radius: 10px;
}

.countStl .dragdrop .imageIcon {
    border: none !important;
    font-size: 44px !important;
}

.countStl .avatar-lg {
    width: 50px;
    height: 50px;
    margin: 6px !important;
}

.countStl .textProsess {
    position: absolute;
    left: 45%;
    top: 22px;
}

.countStl .divProsess {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d9d7d763;
}

.shoppings .header {
    color: #7e57c2;
    font-size: 15px;
}

.shoppings .card {
    border-radius: 13px;
    min-height: 136px;
    -webkit-box-shadow: 1px 1px 10px #e3e3e3;
    box-shadow: 1px 5px 10px #ebebeb;
}

.shoppings .body {
    min-height: 53px;
    display: inline-flex;
    -webkit-box-align: end;
    align-items: flex-end;
}

.shoppings .btn-dark {
    width: 100px;
    background: #6868ac42;
    border: 1px solid #6868ac26;
    color: #6868AC;
}

.shoppings .btn-dark:focus {
    border: 1px solid #6868ac;
}

.New .card {
    text-align: center;
    border: 1px solid #8bd670;
    cursor: pointer;
}

.New .card:hover {
    box-shadow: 1px 1px 8px #8bd670;
}

.New .card:active {
    box-shadow: none;
}

.LoaderKiven {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: inline-flex;
    align-items: center;
    text-align: center;
    -webkit-box-align: center;
    padding-left: 41%;
    background: #4969914a;
    top: 0;
    left: 0;
}

.btn-outline-primary {
    color: #6868aa;
    border-color: #6868aa;
    margin-right: 3px;
    padding: 8px 27px;
    font-size: 17px;
    cursor: pointer;
}

.btn-outline-primary:focus {
    color: #fff;
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #6868aa;
    border-color: #6868aa;
}

.selectHide {
    -webkit-appearance: none !important;
    width: 70px;
    padding: 6px;
    margin-right: 13px;
    text-align: center;
}

.divGrop {
    display: inline-flex;
    width: 100%;
}

.iti__flag {
    height: 14px;
    background-position: -5065px 0;
}

.iti {
    width: 100%;
    display: inline-flex !important;
}

.iti__flag-container {
    position: relative !important;
}

.iti__selected-flag {
    background-color: #fafafb !important;
    border-radius: 10px;
    border: 1px solid #f1f1f5;
}

.iti__flag-container {
    margin-right: 8px;
}

.iti__arrow {
    display: none;
}

.iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 3px !important;
    margin-right: 3px;
}

.iti input {
    padding-left: 9px !important;
}

.iti__selected-flag .iti__flag {
    display: none !important;
}

.imageUserConfirm {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #ddd8d8;
}

.btn-primary:hover {
    color: #fff;
    background-color: #4b569d;
    border-color: #4b569d;
}

.btn-primary {
    color: #fff;
    background-color: #4b569d;
    border-color: #4b569d;
}

a {
    color: #4b569d;
    text-decoration: none;
    background-color: transparent;
}

.dataTables_scrollBody {
    min-height: 200px;
}


.tabKiven {
    .tabs {
        display: -webkit-inline-box;
        justify-content: center;
        width: 100%;
        overflow-x: auto;
    }

    .tab {
        font-size: 16px;
        padding: 6px 23px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 24px;
    }

    .tab.active {
        background-color: #6868ab;
        color: #fff;
    }

    .content {
        width: 100%;
        margin-top: 25px;
    }

    .content-item {
        display: none;
        padding: 5px;
        border: 1px solid #ccc;
        min-height: 300px;
    }

    .content-item.active {
        display: flex;
        justify-content: center;
        display: inline-block;
        width: 100%;
    }
}

.textNotfMessage {
    text-overflow: ellipsis;
    width: 70%;
    overflow: hidden;
    white-space: nowrap !important;
}

.tabLevel {
    .tabs {
        display: inline-block;
        justify-content: center;
        width: 100%;
        overflow-x: auto;
    }

    .tab {
        font-size: 14px;
        padding: 13px 15px;
        cursor: pointer;
        margin-top: 4px;
        margin-bottom: 9px;
    }

    .tab.active {
        background-color: #6868ab;
        color: #fff;
    }

    .content {
        width: 100%;
        margin-top: 25px;
    }

    .content-item {
        display: none;
        padding: 5px;
        border: 1px solid #ccc;
        min-height: 300px;
    }

    .content-item.active {
        justify-content: center;
        width: 100%;
        display: inline-flex;
        text-align: center;
        align-items: center;

        h1 {
            color: #6868aa;
        }
    }
}

.color-red {
    color: red;
}

.jq-toast-wrap,
.jq-toast-wrap p {
    font-size: 16px;
    margin-top: 10px;
}

.viewer-footer .viewer-toolbar .viewer-play {
    display: none;
}

.viewer-footer .viewer-toolbar .viewer-next {
    display: none;
}

.viewer-footer .viewer-toolbar .viewer-prev {
    display: none;
}

.viewer-footer .viewer-toolbar .viewer-reset {
    display: none;
}

.viewer-footer .viewer-title {
    display: none;
}

.left-side-menu {
    box-shadow: none;
}

#sidebar-menu>ul>li>a.active {
    color: #7e57c2;
    fill: #835fc5;
}

.NotPeermstion {

    h1 {
        font-size: 30vw;
        text-align: center;
        position: fixed;
        width: 81%;
        z-index: 1;
        color: #ffffff26;
        top: 50%;
        transform: translateY(-50%);
    }

    div {
        background: rgba(0, 0, 0, 0);
        width: 70vw;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        padding: 30px 30px 10px;
        box-shadow: 0 0 150px -20px rgba(0, 0, 0, 0.5);
        z-index: 3;
    }

    P {
        font-family: "Share Tech Mono", monospace;
        color: #f5f5f5;
        margin: 0 0 20px;
        font-size: 17px;
        line-height: 1.2;
    }

    span {
        color: #f0c674;
    }

    i {
        color: #8abeb7;
    }

    div a {
        text-decoration: none;
    }

    b {
        color: #81a2be;
    }

    a.avatar {
        position: fixed;
        bottom: 15px;
        right: -100px;
        animation: slide 0.5s 4.5s forwards;
        display: block;
        z-index: 4
    }

    a.avatar img {
        border-radius: 100%;
        width: 44px;
        border: 2px solid white;
    }

    @keyframes slide {
        from {
            right: -100px;
            transform: rotate(360deg);
            opacity: 0;
        }

        to {
            right: 15px;
            transform: rotate(0deg);
            opacity: 1;
        }
    }

    background-image: linear-gradient(120deg, #6868ab 0, #313158 100%);
    height: 78vh;
}

.DoctorActivted {
    background: #00800040;
    color: green;
    padding: 7px 12px;
    border-radius: 6px;
}

.DoctorStopped {
    background: rgba(255, 0, 0, 0.219);
    color: red;
    padding: 7px 12px;
    border-radius: 6px;
}

.imageUserForm {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
}

.check-1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transfrom: translatex(-50%) translatey(-50%);
    width: 44px;
    height: 26px;
    border-radius: 50px;
    position: relative;
}

.check-1 .inner {
    position: absolute;
    width: 100%;
    height: 24.5px;
    border-radius: 50px;
    border: 2px solid #ff1036;
    background: #ff1036;
    transition: all .2s ease;
}

.check-1 .bullet {
    position: relative;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    transition: all .3s ease;
    top: 2px;
    left: 4px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .15);
}

.check-1 input:checked~.inner {
    border: 12.5px solid #2ecc71;
    transition: all .2s ease;
}

.check-1 input:checked~.bullet {
    left: 23px;
    background: #fff;
    transition: all .3s ease;
    animation: .2s bullet;
}

@keyframes bullet {

    0%,
    100% {
        width: 25px;
    }

    40% {
        width: 30px;
    }
}

div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: right !important;
}

.chatNameONLogo {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
    position: absolute;
    top: -16px;
    left: 59px;
    font-weight: 600;
    color: #6868ab;
}


.uk-timeline .uk-timeline-item .uk-card {
    max-height: 300px;
}

.uk-timeline .uk-timeline-item {
    display: flex;
    position: relative;
}

.uk-timeline .uk-timeline-item::before {
    background: #dadee4;
    content: "";
    height: 100%;
    left: 19px;
    position: absolute;
    top: 20px;
    width: 2px;
    z-index: -1;
}

.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
    margin-top: 20px;
    width: 40px;
    height: 40px;
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 0 0 1rem;
}

.btnAddStart {
    position: absolute;
    top: -4px;
    left: 11px;
    cursor: pointer;
}

.btnAddEnd {
    position: absolute;
    top: 65px;
    left: 11px;
    cursor: pointer;
}

.btnTaskShadow:hover {
    box-shadow: 1px 1px 8px #5f5fad;
    background: #6868ab !important;
}

.LoaderDivTask {
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #ede8e875;
    align-items: center;
    text-align: center;
    -webkit-box-align: center;
    display: inline-flex;
    padding-left: 40%;
}

*+address,
*+dl,
*+fieldset,
*+figure,
*+ol,
*+p,
*+pre,
*+ul {
    margin-top: 0px;
}

.notify-item p {
    margin-top: 0px;
}

.dropdown-menu.show {
    top: 100% !important;
    max-height: 350px;
    overflow-y: auto;
}

body {
    background: none;
}




.kivenBtn {

    .presentation {
        position: fixed;
        right: 88px;
        bottom: 34px;
        text-shadow: 1px 1px 1px #000;
    }

    /* menu */
    nav.float-action-button {
        bottom: 0;
        right: 0;
    }

    a.buttons {
        box-shadow: 0 5px 11px -2px rgba(0, 0, 0, 0.18), 0 4px 12px -7px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: 1px solid;
        color: #6363a1;
        font-size: 2.6em;
        padding: 0px 0px 0px 6px;
        text-align: center;
        display: block;
        margin: 5px auto 0;
        position: relative;
        -webkit-transition: all .1s ease-out;
        transition: all .1s ease-out;
    }

    a.buttons:active,
    a.buttons:focus,
    a.buttons:hover {
        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        text-decoration: none;
    }

    a.buttons:not(:last-child) {
        width: 40px;
        height: 40px;
        margin: 20px auto 0;
        opacity: 0;
        font-size: 1.6em;
        padding-top: 2px;
        -webkit-transform: translateY(50px);
        -ms-transform: translateY(50px);
        transform: translateY(50px);
    }

    a.buttons.main-button>i:first-child {
        position: relative;
        margin-top: 8px;
        margin-left: -6px;
    }

    nav.float-action-button:hover a.buttons:not(:last-child) {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        margin: 5px auto 0;
        padding-left: 1px;
    }

    a.buttons:nth-last-child(1) {
        -webkit-transition-delay: 25ms;
        transition-delay: 25ms;
        background-color: #ffffff;
        /* Button color */
    }

    a.buttons:nth-last-child(1) i.fa {
        transform: rotate3d(0, 0, 1, 0);
        transition: content 0.4s, transform 0.4s, opacity 0.4s;
    }

    a.buttons:nth-last-child(1):hover i.fa {
        transform: rotate3d(0, 0, 1, -180deg);
    }

    a.buttons:nth-last-child(1) i.fa:nth-last-child(1) {
        position: absolute;
        margin: 10px 0px 0px -29px;
    }

    a.buttons:nth-last-child(1) i.fa:nth-last-child(2) {
        opacity: 0;
    }

    a.buttons:nth-last-child(1):hover i.fa:nth-last-child(1) {
        opacity: 0;
    }

    a.buttons:nth-last-child(1):hover i.fa:nth-last-child(2) {
        opacity: 1;
    }

    a.buttons:not(:last-child):nth-last-child(2) {
        -webkit-transition-delay: 50ms;
        transition-delay: 20ms;
        background-color: #3b5998;
        /* Facebook color */
    }

    a.buttons:not(:last-child):nth-last-child(3) {
        -webkit-transition-delay: 75ms;
        transition-delay: 40ms;
        background-color: #1da1f2;
        /* Twitter color */
    }

    a.buttons:not(:last-child):nth-last-child(4) {
        -webkit-transition-delay: 100ms;
        transition-delay: 60ms;
        background-color: #dd4b39;
        /* Google plus color */
    }

    .tooltip.left {
        margin-left: -10px;
    }

    /*#region presentation purpose */
    .credits {
        font-family: sans-serif;
        font-size: 13px;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 9999;
    }

    .credits .code-credits {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        color: rgba(255, 255, 255, .9);
        display: block;
        max-width: 256px;
        padding: 8px;
    }

    .credits .code-credits::after {
        content: "";
        display: block;
        clear: both;
    }

    .credits .code-credits img {
        border-radius: 4px;
        float: left;
        margin-right: 12px;
        width: 64px;
    }

    .credits .code-credits>div {
        float: left;
    }

    .credits .code-credits .cc-name {
        font-weight: bold;
        font-size: 16px;
    }

    .credits .code-credits .cc-follow {
        display: block;
        margin-top: 2px;
    }

    .credits .code-credits .cc-follow a:last-child {
        margin-left: 4px;
    }

    .credits .code-credits .cc-follow a:link,
    .credits .code-credits .cc-follow a:visited {
        background-color: #eff3f6;
        background-image: linear-gradient(-180deg, #fafbfc, #eff3f6 90%);
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        color: #24292e;
        font-weight: bold;
        letter-spacing: -0.3px;
        padding: 4px 10px;
        text-decoration: none;
    }

    .credits .code-credits .cc-follow a:hover,
    .credits .code-credits .cc-follow a:active {
        color: #fff;
        background-color: #3072b3;
        background-image: -webkit-linear-gradient(#599bdc, #3072b3);
        background-image: -moz-linear-gradient(#599bdc, #3072b3);
        background-image: -ms-linear-gradient(#599bdc, #3072b3);
        background-image: linear-gradient(#599bdc, #3072b3);
        border-color: #518cc6 #518cc6 #2a65a0;
        text-decoration: none;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    .credits .code-credits .cc-follow a:first-child:hover,
    .credits .code-credits .cc-follow a:first-child:active {
        color: #fff;
        background-color: #000;
        background-image: -webkit-linear-gradient(#555, #000);
        background-image: -moz-linear-gradient(#555, #000);
        background-image: -ms-linear-gradient(#555, #000);
        background-image: linear-gradient(#555, #000);
        border-color: #555 #555 #000;
        text-decoration: none;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    .bg-credits {
        background-color: #000;
        border-radius: 4px;
        color: white;
        display: inline-block;
        font-family: sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.2;
        padding: 4px 6px;
        position: fixed;
        top: 10px;
        right: 10px;
        text-decoration: none;
    }

    /*#endregion */

}



.loading {
    max-width: 50%;
    line-height: 1.4;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}

.loadWords {
    font-weight: normal;
    font-size: 0.9rem;
    color: rgba(189, 189, 189, 1);
    margin: 0.6rem 0 2rem 0;
    display: block;
}

.loading__anim {
    width: 35px;
    height: 35px;
    border: 5px solid rgba(189, 189, 189, 0.25);
    border-left-color: #6969ac;
    border-top-color: #6969ac;
    border-radius: 50%;
    display: inline-block;
    animation: rotate 600ms infinite linear;
}

@keyframes rotate {
    to {
        transform: rotate(1turn);
    }
}



#itemCreateOrder {
    .buttonDentelNumberSystem {
        padding: 3px 13px;
        cursor: pointer;
    }

    .buttonDentelNumberSystem:hover {
        background: #efefef;
    }

    .activeBtnSystem {
        background: red !important;
        color: #fff !important;

    }

    .boxBtn {
        display: inline-flex;
        border: 1px solid #948e8e;
        border-radius: 4px;
        font-size: 20px;
    }

    .item {
        background-color: #fff;
        margin-top: 30px;
    }

    .item .header {
        background-color: #6868ac;
        color: #fff;
        display: grid;
        border-radius: 0px 0px 28px 0px;
        padding: 0px 0px 0px 50px;

        h3 {
            font-size: 1.2rem;

        }
    }

    .Instructions {
        padding-top: 42px;
        padding-right: 60px;
        padding-left: 60px;
        font-size: 11px;
    }

    .boxSelect {
        width: 130px;
        border: 1px solid;
        height: 153px;
        border-radius: 7px;
        margin-left: 10px;
        margin-top: 10px;
        color: #707070;
        float: left;
        cursor: pointer;
    }

    .boxSelect .content {
        padding: 3px;
    }

    .activeBox {
        color: #6868ac;
    }

    .activeBox .headerSelect {
        background: #6868ac !important;
    }

    .boxSelect img {
        width: 50px;
    }

    .boxSelect .headerSelect {
        background: #707070;
        color: #fff;
        font-size: 17px;
        padding: 5px;
        border-radius: 6px 6px 10px 10px;
    }

    .ContainerBoxs {
        margin: 0 34px;
        margin-top: 41px;
        margin-bottom: 23px;
        position: relative;
        text-align: center !important;
    }

    .ContainerBoxs .content p {
        margin-top: 12px;
        color: #6f7070;
    }

    .scrollLeft {
        width: 0;
        height: 156px;
        position: absolute;
        box-shadow: 0px 0px 15px 19px rgb(223, 223, 225);
        outline-color: transparent;
        outline-width: 2px;
        outline-style: dotted;
        left: 0;
        border-radius: 45px
    }

    .displayGride {
        display: inline-grid;
        margin-right: 8px;
    }

    .inputChekBox {
        width: 25px;
        height: 25px;
        background-color: #c8c8cc;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
    }

    .inputChekBox:checked {
        border: 1px solid #f54f9e;
        background-color: #f54f9e;

    }

    .labelCehkBox {
        font-size: 21px;
        text-align: center;
        color: #69696c;
    }

    .continerTeeth {
        display: inline-flex;
        padding-bottom: 8px;
        padding-left: 6px;
        padding-top: 7px;
    }

    .rigthTeeth {
        position: absolute;
        left: 24px;
        font-size: 36px;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #707070;
    }

    .tethSVG {
        display: none;
    }

    @media screen and (max-width: 1200px) {
        .ContainerBoxs {
            display: none;
        }

        .tethSVG {
            display: block;
        }

        .rigthTeeth {
            display: none;
        }

        .item .header {
            background-color: #6868ac;
            padding: 0px 0px 0px 0px;
            border-radius: 0px 0px 28px 28px;

        }

        .item .header h3 {
            text-align: center !important;
            font-size: 18px;

        }
    }

    .boxCorrect {
        background: #f8f8f8;
        width: fit-content;
        padding: 10px;
        border: 1px solid #dcd4d4;
        margin-top: 10px;
        margin-left: 14px;
    }

    .boxCorrect {
        background: #f8f8f8;
        width: fit-content;
        padding: 10px;
        border: 1px solid #dcd4d4;
        margin-top: 10px;
        margin-left: 14px;
    }

    .boxCorrect {
        background: #f8f8f8;
        width: fit-content;
        padding: 10px;
        border: 1px solid #dcd4d4;
        margin-top: 10px;
        margin-left: 14px;
        max-width: 87%;
    }

    #PosteriorCrossBiteItem .ContainerBoxs {
        text-align: left !important;
    }
}

.form-check-input:disabled~.form-check-label {
    color: #98a6ad;
    margin-bottom: 0.5rem;
    font-size: 14px !important;
}


///kiven style shipping

#kivenShipping {

    .mCustomScrollbar {
        -ms-touch-action: pinch-zoom;
        touch-action: pinch-zoom;
    }

    .mCustomScrollbar.mCS_no_scrollbar,
    .mCustomScrollbar.mCS_touch_action {
        -ms-touch-action: auto;
        touch-action: auto;
    }

    .mCustomScrollBox {
        position: relative;
        overflow: hidden;
        height: 100%;
        max-width: 100%;
        outline: none;
        direction: ltr;
    }

    .mCSB_container {
        overflow: hidden;
        width: auto;
        height: auto;
    }

    .mCSB_inside>.mCSB_container {
        margin-right: 30px;
    }

    .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
        margin-right: 0;
    }

    .mCS-dir-rtl>.mCSB_inside>.mCSB_container {
        margin-right: 0;
        margin-left: 30px;
    }

    .mCS-dir-rtl>.mCSB_inside>.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
        margin-left: 0;
    }

    .mCSB_scrollTools {
        position: absolute;
        width: 16px;
        height: auto;
        left: auto;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .mCSB_outside+.mCSB_scrollTools {
        right: -26px;
    }

    .mCS-dir-rtl>.mCSB_inside>.mCSB_scrollTools,
    .mCS-dir-rtl>.mCSB_outside+.mCSB_scrollTools {
        right: auto;
        left: 0;
    }

    .mCS-dir-rtl>.mCSB_outside+.mCSB_scrollTools {
        left: -26px;
    }

    .mCSB_scrollTools .mCSB_draggerContainer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: auto;
    }

    .mCSB_scrollTools a+.mCSB_draggerContainer {
        margin: 20px 0;
    }

    .mCSB_scrollTools .mCSB_draggerRail {
        width: 2px;
        height: 100%;
        margin: 0 auto;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }

    .mCSB_scrollTools .mCSB_dragger {
        cursor: pointer;
        width: 100%;
        height: 30px;
        z-index: 1;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        position: relative;
        width: 4px;
        height: 100%;
        margin: 0 auto;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        text-align: center;
    }

    .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
    .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
        width: 12px;
    }

    .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
        width: 8px;
    }

    .mCSB_scrollTools .mCSB_buttonUp,
    .mCSB_scrollTools .mCSB_buttonDown {
        display: block;
        position: absolute;
        height: 20px;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
    }

    .mCSB_scrollTools .mCSB_buttonDown {
        bottom: 0;
    }

    .mCSB_horizontal.mCSB_inside>.mCSB_container {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .mCSB_horizontal.mCSB_outside>.mCSB_container {
        min-height: 100%;
    }

    .mCSB_horizontal>.mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
        margin-bottom: 0;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal {
        width: auto;
        height: 16px;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .mCustomScrollBox+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
    .mCustomScrollBox+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
        bottom: -26px;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal a+.mCSB_draggerContainer {
        margin: 0 20px;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        width: 100%;
        height: 2px;
        margin: 7px 0;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
        width: 30px;
        height: 100%;
        left: 0;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        width: 100%;
        height: 4px;
        margin: 6px auto;
    }

    .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
    .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
        height: 12px;
        margin: 2px auto;
    }

    .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
        height: 8px;
        margin: 4px 0;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
        display: block;
        position: absolute;
        width: 20px;
        height: 100%;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
        left: 0;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
        right: 0;
    }

    .mCSB_container_wrapper {
        position: absolute;
        height: auto;
        width: auto;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-right: 30px;
        margin-bottom: 30px;
    }

    .mCSB_container_wrapper>.mCSB_container {
        padding-right: 30px;
        padding-bottom: 30px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_vertical {
        bottom: 20px;
    }

    .mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
        right: 20px;
    }

    .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden+.mCSB_scrollTools.mCSB_scrollTools_vertical {
        bottom: 0;
    }

    .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal,
    .mCS-dir-rtl>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
        right: 0;
    }

    .mCS-dir-rtl>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
        left: 20px;
    }

    .mCS-dir-rtl>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal {
        left: 0;
    }

    .mCS-dir-rtl>.mCSB_inside>.mCSB_container_wrapper {
        margin-right: 0;
        margin-left: 30px;
    }

    .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden>.mCSB_container {
        padding-right: 0;
    }

    .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden>.mCSB_container {
        padding-bottom: 0;
    }

    .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
        margin-right: 0;
        margin-left: 0;
    }

    .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
        margin-bottom: 0;
    }

    .mCSB_scrollTools,
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCSB_scrollTools .mCSB_buttonUp,
    .mCSB_scrollTools .mCSB_buttonDown,
    .mCSB_scrollTools .mCSB_buttonLeft,
    .mCSB_scrollTools .mCSB_buttonRight {
        -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    }

    .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
    .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
    .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
    .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
        -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
        transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    }

    .mCSB_scrollTools {
        opacity: 0.75;
        filter: "alpha(opacity=75)";
        -ms-filter: "alpha(opacity=75)";
    }

    .mCS-autoHide>.mCustomScrollBox>.mCSB_scrollTools,
    .mCS-autoHide>.mCustomScrollBox~.mCSB_scrollTools {
        opacity: 0;
        filter: "alpha(opacity=0)";
        -ms-filter: "alpha(opacity=0)";
    }

    .mCustomScrollbar>.mCustomScrollBox>.mCSB_scrollTools.mCSB_scrollTools_onDrag,
    .mCustomScrollbar>.mCustomScrollBox~.mCSB_scrollTools.mCSB_scrollTools_onDrag,
    .mCustomScrollBox:hover>.mCSB_scrollTools,
    .mCustomScrollBox:hover~.mCSB_scrollTools,
    .mCS-autoHide:hover>.mCustomScrollBox>.mCSB_scrollTools,
    .mCS-autoHide:hover>.mCustomScrollBox~.mCSB_scrollTools {
        opacity: 1;
        filter: "alpha(opacity=100)";
        -ms-filter: "alpha(opacity=100)";
    }

    .mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.4);
        filter: "alpha(opacity=40)";
        -ms-filter: "alpha(opacity=40)";
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.75);
        filter: "alpha(opacity=75)";
        -ms-filter: "alpha(opacity=75)";
    }

    .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.85);
        filter: "alpha(opacity=85)";
        -ms-filter: "alpha(opacity=85)";
    }

    .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.9);
        filter: "alpha(opacity=90)";
        -ms-filter: "alpha(opacity=90)";
    }

    .mCSB_scrollTools .mCSB_buttonUp,
    .mCSB_scrollTools .mCSB_buttonDown,
    .mCSB_scrollTools .mCSB_buttonLeft,
    .mCSB_scrollTools .mCSB_buttonRight {
        background-image: url(https://www.smsaexpress.com/themes/custom/smsa/app/css/mCSB_buttons.png);
        background-repeat: no-repeat;
        opacity: 0.4;
        filter: "alpha(opacity=40)";
        -ms-filter: "alpha(opacity=40)";
    }

    .mCSB_scrollTools .mCSB_buttonUp {
        background-position: 0 0;
    }

    .mCSB_scrollTools .mCSB_buttonDown {
        background-position: 0 -20px;
    }

    .mCSB_scrollTools .mCSB_buttonLeft {
        background-position: 0 -40px;
    }

    .mCSB_scrollTools .mCSB_buttonRight {
        background-position: 0 -56px;
    }

    .mCSB_scrollTools .mCSB_buttonUp:hover,
    .mCSB_scrollTools .mCSB_buttonDown:hover,
    .mCSB_scrollTools .mCSB_buttonLeft:hover,
    .mCSB_scrollTools .mCSB_buttonRight:hover {
        opacity: 0.75;
        filter: "alpha(opacity=75)";
        -ms-filter: "alpha(opacity=75)";
    }

    .mCSB_scrollTools .mCSB_buttonUp:active,
    .mCSB_scrollTools .mCSB_buttonDown:active,
    .mCSB_scrollTools .mCSB_buttonLeft:active,
    .mCSB_scrollTools .mCSB_buttonRight:active {
        opacity: 0.9;
        filter: "alpha(opacity=90)";
        -ms-filter: "alpha(opacity=90)";
    }

    .mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.15);
    }

    .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: rgba(0, 0, 0, 0.85);
    }

    .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -80px 0;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -80px -20px;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -80px -40px;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -80px -56px;
    }

    .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
        width: 4px;
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
    }

    .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 4px;
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.75);
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
    }

    .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        width: 100%;
        height: 4px;
        margin: 6px auto;
    }

    .mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.85);
    }

    .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -32px 0;
    }

    .mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -32px -20px;
    }

    .mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -40px -40px;
    }

    .mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -40px -56px;
    }

    .mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.1);
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
    }

    .mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
    }

    .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.85);
    }

    .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -112px 0;
    }

    .mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -112px -20px;
    }

    .mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -120px -40px;
    }

    .mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -120px -56px;
    }

    .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
        width: 4px;
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }

    .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 6px;
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.75);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }

    .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        width: 100%;
        height: 4px;
        margin: 6px 0;
    }

    .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        width: 100%;
        height: 6px;
        margin: 5px auto;
    }

    .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.85);
    }

    .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -16px 0;
    }

    .mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -16px -20px;
    }

    .mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -20px -40px;
    }

    .mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -20px -56px;
    }

    .mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.1);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }

    .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }

    .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.85);
    }

    .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -96px 0;
    }

    .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -96px -20px;
    }

    .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -100px -40px;
    }

    .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -100px -56px;
    }

    .mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 2px;
    }

    .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        width: 100%;
    }

    .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        width: 100%;
        height: 2px;
        margin: 7px auto;
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.15);
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.85);
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -80px 0;
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -80px -20px;
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -80px -40px;
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -80px -56px;
    }

    .mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.15);
    }

    .mCS-rounded.mCSB_scrollTools .mCSB_dragger,
    .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
    .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
        height: 14px;
    }

    .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 14px;
        margin: 0 1px;
    }

    .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
    .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
    .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
    .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
        width: 14px;
    }

    .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        height: 14px;
        margin: 1px 0;
    }

    .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
    .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
    .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
        width: 16px;
        height: 16px;
        margin: -1px 0;
    }

    .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
    .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
        width: 4px;
    }

    .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
    .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
    .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
        height: 16px;
        width: 16px;
        margin: 0 -1px;
    }

    .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
    .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
        height: 4px;
        margin: 6px 0;
    }

    .mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
        background-position: 0 -72px;
    }

    .mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
        background-position: 0 -92px;
    }

    .mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: 0 -112px;
    }

    .mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
        background-position: 0 -128px;
    }

    .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.15);
    }

    .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.85);
    }

    .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -80px -72px;
    }

    .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -80px -92px;
    }

    .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -80px -112px;
    }

    .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -80px -128px;
    }

    .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
    .mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
        width: 4px;
    }

    .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        background-color: transparent;
        background-position: center;
    }

    .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
        background-repeat: repeat-y;
        opacity: 0.3;
        filter: "alpha(opacity=30)";
        -ms-filter: "alpha(opacity=30)";
    }

    .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        height: 4px;
        margin: 6px 0;
        background-repeat: repeat-x;
    }

    .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -16px -72px;
    }

    .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -16px -92px;
    }

    .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -20px -112px;
    }

    .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -20px -128px;
    }

    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
    }

    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -96px -72px;
    }

    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -96px -92px;
    }

    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -100px -112px;
    }

    .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -100px -128px;
    }

    .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-repeat: repeat-y;
        background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
        background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        background-repeat: repeat-x;
        background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
        background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
    .mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
        height: 70px;
    }

    .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
    .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
        width: 70px;
    }

    .mCS-3d.mCSB_scrollTools,
    .mCS-3d-dark.mCSB_scrollTools {
        opacity: 1;
        filter: "alpha(opacity=30)";
        -ms-filter: "alpha(opacity=30)";
    }

    .mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }

    .mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
        width: 8px;
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
    }

    .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
    .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #555;
    }

    .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 8px;
    }

    .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        width: 100%;
        height: 8px;
        margin: 4px 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
    }

    .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        width: 100%;
        height: 8px;
        margin: 4px auto;
    }

    .mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -40px -128px;
    }

    .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
    }

    .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    }

    .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -120px -128px;
    }

    .mCS-3d-thick.mCSB_scrollTools,
    .mCS-3d-thick-dark.mCSB_scrollTools {
        opacity: 1;
        filter: "alpha(opacity=30)";
        -ms-filter: "alpha(opacity=30)";
    }

    .mCS-3d-thick.mCSB_scrollTools,
    .mCS-3d-thick-dark.mCSB_scrollTools,
    .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

    .mCSB_inside+.mCS-3d-thick.mCSB_scrollTools_vertical,
    .mCSB_inside+.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
        right: 1px;
    }

    .mCS-3d-thick.mCSB_scrollTools_vertical,
    .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
        box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
    }

    .mCS-3d-thick.mCSB_scrollTools_horizontal,
    .mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
        bottom: 1px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
        width: 12px;
        margin: 2px;
        position: absolute;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
    .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #555;
    }

    .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        height: 12px;
        width: auto;
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.05);
        box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
        background-color: transparent;
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -40px -128px;
    }

    .mCS-3d-thick-dark.mCSB_scrollTools {
        box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
    }

    .mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
    }

    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
    }

    .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    }

    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #777;
    }

    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
        background-color: #fff;
        background-color: rgba(0, 0, 0, 0.05);
        box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
    }

    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: transparent;
    }

    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -120px -128px;
    }

    .mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical,
    .mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical {
        right: 0;
        margin: 12px 0;
    }

    .mCustomScrollBox.mCS-minimal+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
    .mCustomScrollBox.mCS-minimal+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
    .mCustomScrollBox.mCS-minimal-dark+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
    .mCustomScrollBox.mCS-minimal-dark+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
        bottom: 0;
        margin: 0 12px;
    }

    .mCS-dir-rtl>.mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical,
    .mCS-dir-rtl>.mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical {
        left: 0;
        right: auto;
    }

    .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: transparent;
    }

    .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
    .mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
        height: 50px;
    }

    .mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
    .mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
        width: 50px;
    }

    .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.2);
        filter: "alpha(opacity=20)";
        -ms-filter: "alpha(opacity=20)";
    }

    .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.5);
        filter: "alpha(opacity=50)";
        -ms-filter: "alpha(opacity=50)";
    }

    .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.2);
        filter: "alpha(opacity=20)";
        -ms-filter: "alpha(opacity=20)";
    }

    .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.5);
        filter: "alpha(opacity=50)";
        -ms-filter: "alpha(opacity=50)";
    }

    .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
        width: 6px;
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 6px;
    }

    .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        width: 100%;
        height: 6px;
        margin: 5px 0;
    }

    .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
    .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
        width: 12px;
    }

    .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
    .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
    .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
        height: 12px;
        margin: 2px 0;
    }

    .mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -40px -128px;
    }

    .mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.85);
    }

    .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -120px -128px;
    }

    .mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
        width: 12px;
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 6px;
        margin: 3px 5px;
        position: absolute;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        height: 6px;
        margin: 5px 3px;
        position: absolute;
        width: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        width: 100%;
        height: 12px;
        margin: 2px 0;
    }

    .mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
    .mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
    .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -32px -72px;
    }

    .mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
    .mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
    .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -32px -92px;
    }

    .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
    .mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
    .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -40px -112px;
    }

    .mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
    .mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
    .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -40px -128px;
    }

    .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.85);
    }

    .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
        background-position: -112px -72px;
    }

    .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
        background-position: -112px -92px;
    }

    .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
        background-position: -120px -112px;
    }

    .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
        background-position: -120px -128px;
    }

    .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: #fff;
        border-color: rgba(255, 255, 255, 0.2);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
        border-color: #000;
        border-color: rgba(0, 0, 0, 0.2);
    }

    .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.6);
    }

    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.85);
    }

    .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.75);
    }

    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.85);
    }

    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .bootstrap-select>select.bs-select-hidden,
    select.bs-select-hidden,
    select.selectpicker {
        display: none !important
    }

    .bootstrap-select {
        width: 220px\0;
        vertical-align: middle
    }

    .bootstrap-select>.dropdown-toggle {
        position: relative;
        width: 100%;
        text-align: right;
        white-space: nowrap;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .bootstrap-select>.dropdown-toggle:after {
        margin-top: -1px
    }

    .bootstrap-select>.dropdown-toggle.bs-placeholder,
    .bootstrap-select>.dropdown-toggle.bs-placeholder:active,
    .bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
    .bootstrap-select>.dropdown-toggle.bs-placeholder:hover {
        color: #999
    }

    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:active,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:focus,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:hover,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:active,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:focus,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:hover,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:active,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:focus,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:hover,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:active,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:focus,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:hover,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:active,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:focus,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:hover,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:active,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:focus,
    .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:hover {
        color: rgba(255, 255, 255, .5)
    }

    .bootstrap-select>select {
        position: absolute !important;
        bottom: 0;
        left: 50%;
        display: block !important;
        width: .5px !important;
        height: 100% !important;
        padding: 0 !important;
        opacity: 0 !important;
        border: none;
        z-index: 0 !important
    }

    .bootstrap-select>select.mobile-device {
        top: 0;
        left: 0;
        display: block !important;
        width: 100% !important;
        z-index: 2 !important
    }

    .bootstrap-select.is-invalid .dropdown-toggle,
    .error .bootstrap-select .dropdown-toggle,
    .has-error .bootstrap-select .dropdown-toggle,
    .was-validated .bootstrap-select .selectpicker:invalid+.dropdown-toggle {
        border-color: #b94a48
    }

    .bootstrap-select.is-valid .dropdown-toggle,
    .was-validated .bootstrap-select .selectpicker:valid+.dropdown-toggle {
        border-color: #28a745
    }

    .bootstrap-select.fit-width {
        width: auto !important
    }

    .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
        width: 220px
    }

    .bootstrap-select .dropdown-toggle:focus,
    .bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
        outline: thin dotted #333 !important;
        outline: 5px auto -webkit-focus-ring-color !important;
        outline-offset: -2px
    }

    .bootstrap-select.form-control {
        margin-bottom: 0;
        padding: 0;
        border: none
    }

    :not(.input-group)>.bootstrap-select.form-control:not([class*=col-]) {
        width: 100%
    }

    .bootstrap-select.form-control.input-group-btn {
        float: none;
        z-index: auto
    }

    .form-inline .bootstrap-select,
    .form-inline .bootstrap-select.form-control:not([class*=col-]) {
        width: auto
    }

    .bootstrap-select:not(.input-group-btn),
    .bootstrap-select[class*=col-] {
        float: none;
        display: inline-block;
        margin-left: 0
    }

    .bootstrap-select.dropdown-menu-right,
    .bootstrap-select[class*=col-].dropdown-menu-right,
    .row .bootstrap-select[class*=col-].dropdown-menu-right {
        float: right
    }

    .form-group .bootstrap-select,
    .form-horizontal .bootstrap-select,
    .form-inline .bootstrap-select {
        margin-bottom: 0
    }

    .form-group-lg .bootstrap-select.form-control,
    .form-group-sm .bootstrap-select.form-control {
        padding: 0
    }

    .form-group-lg .bootstrap-select.form-control .dropdown-toggle,
    .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
        height: 100%;
        font-size: inherit;
        line-height: inherit;
        border-radius: inherit
    }

    .bootstrap-select.form-control-lg .dropdown-toggle,
    .bootstrap-select.form-control-sm .dropdown-toggle {
        font-size: inherit;
        line-height: inherit;
        border-radius: inherit
    }

    .bootstrap-select.form-control-sm .dropdown-toggle {
        padding: .25rem .5rem
    }

    .bootstrap-select.form-control-lg .dropdown-toggle {
        padding: .5rem 1rem
    }

    .form-inline .bootstrap-select .form-control {
        width: 100%
    }

    .bootstrap-select.disabled,
    .bootstrap-select>.disabled {
        cursor: not-allowed
    }

    .bootstrap-select.disabled:focus,
    .bootstrap-select>.disabled:focus {
        outline: 0 !important
    }

    .bootstrap-select.bs-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 0 !important;
        padding: 0 !important
    }

    .bootstrap-select.bs-container .dropdown-menu {
        z-index: 1060
    }

    .bootstrap-select .dropdown-toggle .filter-option {
        position: static;
        top: 0;
        left: 0;
        float: left;
        height: 100%;
        width: 100%;
        text-align: left;
        overflow: hidden;
        -webkit-box-flex: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto
    }

    .bs3.bootstrap-select .dropdown-toggle .filter-option {
        padding-right: inherit
    }

    .input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
        position: absolute;
        padding-top: inherit;
        padding-bottom: inherit;
        padding-left: inherit;
        float: none
    }

    .input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
        padding-right: inherit
    }

    .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
        overflow: hidden
    }

    .bootstrap-select .dropdown-toggle .filter-expand {
        width: 0 !important;
        float: left;
        opacity: 0 !important;
        overflow: hidden
    }

    .bootstrap-select .dropdown-toggle .caret {
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -2px;
        vertical-align: middle
    }

    .input-group .bootstrap-select.form-control .dropdown-toggle {
        border-radius: inherit
    }

    .bootstrap-select[class*=col-] .dropdown-toggle {
        width: 100%
    }

    .bootstrap-select .dropdown-menu {
        min-width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .bootstrap-select .dropdown-menu>.inner:focus {
        outline: 0 !important
    }

    .bootstrap-select .dropdown-menu.inner {
        position: static;
        float: none;
        border: 0;
        padding: 0;
        margin: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .bootstrap-select .dropdown-menu li {
        position: relative
    }

    .bootstrap-select .dropdown-menu li.active small {
        color: rgba(255, 255, 255, .5) !important
    }

    .bootstrap-select .dropdown-menu li.disabled a {
        cursor: not-allowed
    }

    .bootstrap-select .dropdown-menu li a {
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    .bootstrap-select .dropdown-menu li a.opt {
        position: relative;
        padding-left: 2.25em
    }

    .bootstrap-select .dropdown-menu li a span.check-mark {
        display: none
    }

    .bootstrap-select .dropdown-menu li a span.text {
        display: inline-block
    }

    .bootstrap-select .dropdown-menu li small {
        padding-left: .5em
    }

    .bootstrap-select .dropdown-menu .notify {
        position: absolute;
        bottom: 5px;
        width: 96%;
        margin: 0 2%;
        min-height: 26px;
        padding: 3px 5px;
        background: #f5f5f5;
        border: 1px solid #e3e3e3;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        pointer-events: none;
        opacity: .9;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .bootstrap-select .no-results {
        padding: 3px;
        background: #f5f5f5;
        margin: 0 5px;
        white-space: nowrap
    }

    .bootstrap-select.fit-width .dropdown-toggle .filter-option {
        position: static;
        display: inline;
        padding: 0;
        width: auto
    }

    .bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
    .bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
        display: inline
    }

    .bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
        content: '\00a0'
    }

    .bootstrap-select.fit-width .dropdown-toggle .caret {
        position: static;
        top: auto;
        margin-top: -1px
    }

    .bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
        position: absolute;
        display: inline-block;
        right: 15px;
        top: 5px
    }

    .bootstrap-select.show-tick .dropdown-menu li a span.text {
        margin-right: 34px
    }

    .bootstrap-select .bs-ok-default:after {
        content: '';
        display: block;
        width: .5em;
        height: 1em;
        border-style: solid;
        border-width: 0 .26em .26em 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    .bootstrap-select.show-menu-arrow.open>.dropdown-toggle,
    .bootstrap-select.show-menu-arrow.show>.dropdown-toggle {
        z-index: 1061
    }

    .bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
        content: '';
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid rgba(204, 204, 204, .2);
        position: absolute;
        bottom: -4px;
        left: 9px;
        display: none
    }

    .bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        position: absolute;
        bottom: -4px;
        left: 10px;
        display: none
    }

    .bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
        bottom: auto;
        top: -4px;
        border-top: 7px solid rgba(204, 204, 204, .2);
        border-bottom: 0
    }

    .bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
        bottom: auto;
        top: -4px;
        border-top: 6px solid #fff;
        border-bottom: 0
    }

    .bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
        right: 12px;
        left: auto
    }

    .bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
        right: 13px;
        left: auto
    }

    .bootstrap-select.show-menu-arrow.open>.dropdown-toggle .filter-option:after,
    .bootstrap-select.show-menu-arrow.open>.dropdown-toggle .filter-option:before,
    .bootstrap-select.show-menu-arrow.show>.dropdown-toggle .filter-option:after,
    .bootstrap-select.show-menu-arrow.show>.dropdown-toggle .filter-option:before {
        display: block
    }

    .bs-actionsbox,
    .bs-donebutton,
    .bs-searchbox {
        padding: 4px 8px
    }

    .bs-actionsbox {
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .bs-actionsbox .btn-group button {
        width: 50%
    }

    .bs-donebutton {
        float: left;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .bs-donebutton .btn-group button {
        width: 100%
    }

    .bs-searchbox+.bs-actionsbox {
        padding: 0 8px 4px
    }

    .bs-searchbox .form-control {
        margin-bottom: 0;
        width: 100%;
        float: none
    }

    @font-face {
        font-family: "icomoon";
        src: url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/icomoon/icomoon.eot?jh11eu=);
        src: url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/icomoon/icomoon.eot?jh11eu=#iefix) format("embedded-opentype"), url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/icomoon/icomoon.ttf?jh11eu=) format("truetype"), url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/icomoon/icomoon.woff?jh11eu=) format("woff"), url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/icomoon/icomoon.svg?jh11eu=#icomoon) format("svg");
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    [class^="icon-"],
    [class*=" icon-"] {
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-minus:before {
        content: "î¤›";
    }

    .icon-expand:before {
        content: "î¤ˆ";
    }

    .icon-arrow-pointing-to-right:before {
        content: "î¤‡";
    }

    .icon-call:before {
        content: "î¤‚";
    }

    .icon-facebook:before {
        content: "î¤„";
    }

    .icon-instagram-1:before {
        content: "î¤‹";
    }

    .icon-twitter-1:before {
        content: "î¤Œ";
    }

    .icon-youtube-1:before {
        content: "î¤";
    }

    .icon-facebook-1:before {
        content: "î¤ƒ";
    }

    .icon-linkedin:before {
        content: "î¤…";
    }

    .icon-twitter:before {
        content: "î¤†";
    }

    .icon-whatsapp:before {
        content: "î¤‡";
    }

    .icon-search:before {
        content: "î¤ˆ";
    }

    .icon-bottom-arrow:before {
        content: "î¤‰";
    }

    .icon-right-line-arrow:before {
        content: "î¤Š";
    }

    .icon-headphones:before {
        content: "î¤š";
    }

    .icon-location-point:before {
        content: "î¤";
    }

    .icon-paper-plane:before {
        content: "î¤Ž";
    }

    .icon-left_arrow-gray:before {
        content: "î¤€";
    }

    .icon-right_arrow-gray:before {
        content: "î¤";
    }

    .icon-left_arrow-white:before {
        content: "î¤";
    }

    .icon-right_arrow-white:before {
        content: "î¤‘";
    }

    .icon-envelope:before {
        content: "î¤“";
    }

    .icon-location-point-orange:before {
        content: "î¤”";
    }

    .icon-phone-pause:before {
        content: "î¤•";
    }

    .icon-print:before {
        content: "î¤–";
    }

    .icon-calendar:before {
        content: "î¤—";
    }

    .icon-delivery-truck:before {
        content: "î¤˜";
    }

    .icon-locator:before {
        content: "î¤™";
    }

    .icon-worldwide:before {
        content: "î¤š";
    }

    .icon-plus:before {
        content: "î¤œ";
    }

    .icon-minus:before {
        content: "î¤›";
    }

    .icon-up-arrow:before {
        content: "î¤";
    }

    .icon-tick:before {
        content: "î¤ž";
    }

    .icon-out-of-delivery:before {
        content: "î¤¡";
    }

    .icon-delivered:before {
        content: "î¤ ";
    }

    .icon-picked-up:before {
        content: "î¤Ÿ";
    }

    .icon-transit:before {
        content: "î¤¢";
    }

    .icon-arrow-new1-:before {
        content: "î¤’";
    }

    .icon-arrow-new4:before {
        content: "î¤§";
    }

    .icon-maplocation:before {
        content: "î¤¨";
    }

    .fp-enabled body,
    html.fp-enabled {
        margin: 0;
        padding: 0;
        overflow: hidden;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .fp-section {
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .fp-slide {
        float: left;
    }

    .fp-slide,
    .fp-slidesContainer {
        height: 100%;
        display: block;
    }

    .fp-slides {
        z-index: 1;
        height: 100%;
        overflow: hidden;
        position: relative;
        -webkit-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        -moz-transition: all .3s ease-out;
        transition: all .3s ease-out;
    }

    .fp-section.fp-table,
    .fp-slide.fp-table {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .fp-tableCell {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }

    .fp-slidesContainer {
        float: left;
        position: relative;
    }

    .fp-controlArrow {
        -webkit-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -ms-user-select: none;
        position: absolute;
        z-index: 4;
        top: 50%;
        cursor: pointer;
        width: 0;
        height: 0;
        border-style: solid;
        margin-top: -38px;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .fp-controlArrow.fp-prev {
        left: 15px;
        width: 0;
        border-width: 38.5px 34px 38.5px 0;
        border-color: transparent #fff transparent transparent;
    }

    .fp-controlArrow.fp-next {
        right: 15px;
        border-width: 38.5px 0 38.5px 34px;
        border-color: transparent transparent transparent #fff;
    }

    .fp-scrollable {
        overflow: hidden;
        position: relative;
    }

    .fp-scroller {
        overflow: hidden;
    }

    .iScrollIndicator {
        border: 0 !important;
    }

    .fp-notransition {
        -webkit-transition: none !important;
        -o-transition: none !important;
        -moz-transition: none !important;
        transition: none !important;
    }

    #fp-nav {
        position: fixed;
        z-index: 100;
        margin-top: -32px;
        top: 50%;
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
    }

    #fp-nav.fp-right {
        right: 17px;
    }

    #fp-nav.fp-left {
        left: 17px;
    }

    .fp-slidesNav {
        position: absolute;
        z-index: 4;
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0 !important;
        right: 0;
        margin: 0 auto !important;
    }

    .fp-slidesNav.fp-bottom {
        bottom: 17px;
    }

    .fp-slidesNav.fp-top {
        top: 17px;
    }

    #fp-nav ul,
    .fp-slidesNav ul {
        margin: 0;
        padding: 0;
    }

    #fp-nav ul li,
    .fp-slidesNav ul li {
        display: block;
        width: 14px;
        height: 13px;
        margin: 7px;
        position: relative;
    }

    .fp-slidesNav ul li {
        display: inline-block;
    }

    #fp-nav ul li a,
    .fp-slidesNav ul li a {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-decoration: none;
    }

    #fp-nav ul li a.active span,
    #fp-nav ul li:hover a.active span,
    .fp-slidesNav ul li a.active span,
    .fp-slidesNav ul li:hover a.active span {
        height: 12px;
        width: 12px;
        margin: -6px 0 0 -6px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
    }

    #fp-nav ul li a span,
    .fp-slidesNav ul li a span {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        height: 4px;
        width: 4px;
        border: 0;
        background: #333;
        left: 50%;
        top: 50%;
        margin: -2px 0 0 -2px;
        -webkit-transition: all .1s ease-in-out;
        -moz-transition: all .1s ease-in-out;
        -o-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
    }

    #fp-nav ul li:hover a span,
    .fp-slidesNav ul li:hover a span {
        width: 10px;
        height: 10px;
        margin: -5px 0 0 -5px;
    }

    #fp-nav ul li .fp-tooltip {
        position: absolute;
        top: -2px;
        color: #fff;
        font-size: 14px;
        font-family: arial, helvetica, sans-serif;
        white-space: nowrap;
        max-width: 220px;
        overflow: hidden;
        display: block;
        opacity: 0;
        width: 0;
        cursor: pointer;
    }

    #fp-nav ul li:hover .fp-tooltip,
    #fp-nav.fp-show-active a.active+.fp-tooltip {
        -webkit-transition: opacity .2s ease-in;
        -o-transition: opacity .2s ease-in;
        -moz-transition: opacity .2s ease-in;
        transition: opacity .2s ease-in;
        width: auto;
        opacity: 1;
    }

    #fp-nav ul li .fp-tooltip.fp-right {
        right: 20px;
    }

    #fp-nav ul li .fp-tooltip.fp-left {
        left: 20px;
    }

    .fp-auto-height .fp-slide,
    .fp-auto-height .fp-tableCell,
    .fp-auto-height.fp-section {
        height: auto !important;
    }

    .fp-responsive .fp-auto-height-responsive .fp-slide,
    .fp-responsive .fp-auto-height-responsive .fp-tableCell,
    .fp-responsive .fp-auto-height-responsive.fp-section {
        height: auto !important;
    }

    .fp-sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }

    .owl-carousel {
        display: none;
        width: 100%;
        -webkit-tap-highlight-color: transparent;
        position: relative;
        z-index: 1;
    }

    .owl-carousel .owl-stage {
        position: relative;
        -ms-touch-action: pan-Y;
        touch-action: manipulation;
        -moz-backface-visibility: hidden;
    }

    .owl-carousel .owl-stage:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }

    .owl-carousel .owl-stage-outer {
        position: relative;
        overflow: hidden;
        -webkit-transform: translate3d(0px, 0px, 0px);
    }

    .owl-carousel .owl-wrapper,
    .owl-carousel .owl-item {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
    }

    .owl-carousel .owl-item {
        position: relative;
        min-height: 1px;
        float: left;
        -webkit-backface-visibility: hidden;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
    }

    .owl-carousel .owl-item img {
        display: block;
        width: 100%;
    }

    .owl-carousel .owl-nav.disabled,
    .owl-carousel .owl-dots.disabled {
        display: none;
    }

    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next,
    .owl-carousel .owl-dot {
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next,
    .owl-carousel button.owl-dot {
        background: none;
        color: inherit;
        border: none;
        padding: 0 !important;
        font: inherit;
    }

    .owl-carousel.owl-loaded {
        display: block;
    }

    .owl-carousel.owl-loading {
        opacity: 0;
        display: block;
    }

    .owl-carousel.owl-hidden {
        opacity: 0;
    }

    .owl-carousel.owl-refresh .owl-item {
        visibility: hidden;
    }

    .owl-carousel.owl-drag .owl-item {
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .owl-carousel.owl-grab {
        cursor: move;
        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: grab;
    }

    .owl-carousel.owl-rtl {
        direction: rtl;
    }

    .owl-carousel.owl-rtl .owl-item {
        float: right;
    }

    .no-js .owl-carousel {
        display: block;
    }

    .owl-carousel .animated {
        -webkit-animation-duration: 1000ms;
        -moz-animation-duration: 1000ms;
        -o-animation-duration: 1000ms;
        animation-duration: 1000ms;
        -webkit-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .owl-carousel .owl-animated-in {
        z-index: 0;
    }

    .owl-carousel .owl-animated-out {
        z-index: 1;
    }

    .owl-carousel .fadeOut {
        -webkit-animation-name: fadeOut;
        -moz-animation-name: fadeOut;
        -o-animation-name: fadeOut;
        animation-name: fadeOut;
    }

    @-webkit-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @-moz-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @-o-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    .owl-height {
        -webkit-transition: height 500ms ease-in-out;
        -o-transition: height 500ms ease-in-out;
        -moz-transition: height 500ms ease-in-out;
        transition: height 500ms ease-in-out;
    }

    .owl-carousel .owl-item {}

    .owl-carousel .owl-item .owl-lazy {
        opacity: 0;
        -webkit-transition: opacity 400ms ease;
        -o-transition: opacity 400ms ease;
        -moz-transition: opacity 400ms ease;
        transition: opacity 400ms ease;
    }

    .owl-carousel .owl-item .owl-lazy[src^=""],
    .owl-carousel .owl-item .owl-lazy:not([src]) {
        max-height: 0;
    }

    .owl-carousel .owl-item img.owl-lazy {
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    .owl-carousel .owl-video-wrapper {
        position: relative;
        height: 100%;
        background: #000;
    }

    .owl-carousel .owl-video-play-icon {
        position: absolute;
        height: 80px;
        width: 80px;
        left: 50%;
        top: 50%;
        margin-left: -40px;
        margin-top: -40px;
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/css/owl.video.play.png) no-repeat;
        cursor: pointer;
        z-index: 1;
        -webkit-backface-visibility: hidden;
        -webkit-transition: -webkit-transform 100ms ease;
        transition: -webkit-transform 100ms ease;
        -o-transition: -o-transform 100ms ease;
        -moz-transition: transform 100ms ease, -moz-transform 100ms ease;
        transition: transform 100ms ease;
        transition: transform 100ms ease, -webkit-transform 100ms ease, -moz-transform 100ms ease, -o-transform 100ms ease;
    }

    .owl-carousel .owl-video-play-icon:hover {
        -ms-transform: scale(1.3, 1.3);
        -webkit-transform: scale(1.3, 1.3);
        -moz-transform: scale(1.3, 1.3);
        -o-transform: scale(1.3, 1.3);
        transform: scale(1.3, 1.3);
    }

    .owl-carousel .owl-video-playing .owl-video-tn,
    .owl-carousel .owl-video-playing .owl-video-play-icon {
        display: none;
    }

    .owl-carousel .owl-video-tn {
        opacity: 0;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        -o-background-size: contain;
        background-size: contain;
        -webkit-transition: opacity 400ms ease;
        -o-transition: opacity 400ms ease;
        -moz-transition: opacity 400ms ease;
        transition: opacity 400ms ease;
    }

    .owl-carousel .owl-video-frame {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
    }

    .owl-theme .owl-nav {
        margin-top: 10px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
    }

    .owl-theme .owl-nav [class*='owl-'] {
        color: #FFF;
        font-size: 15px;
        margin: 5px;
        padding: 4px 7px;
        background: #D6D6D6;
        display: inline-block;
        cursor: pointer;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }

    .owl-theme .owl-nav [class*='owl-']:hover {
        background: #869791;
        color: #FFF;
        text-decoration: none;
    }

    .owl-theme .owl-nav .disabled {
        opacity: 0.5;
        cursor: default;
    }

    .owl-theme .owl-nav.disabled+.owl-dots {
        margin-top: 10px;
    }

    .owl-theme .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
    }

    .owl-theme .owl-dots .owl-dot {
        display: inline-block;
        zoom: 1;
        *display: inline;
    }

    .owl-theme .owl-dots .owl-dot span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: #D6D6D6;
        display: block;
        -webkit-backface-visibility: visible;
        -webkit-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        -moz-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background: #869791;
    }

    html,
    body {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility\9;
        -ms-text-size-adjust: 100%;
    }

    html {
        font-size: 15px;
    }

    body {
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        background: transparent;
        line-height: 24px;
    }

    :focus {
        outline: 0;
    }

    html[dir="rtl"] body {
        font-family: 'Cairo', sans-serif;
    }

    h1,
    h2,
    .h1,
    .h2 {
        font-weight: 700;
    }

    h1,
    .h1 {
        font-size: 2.9rem;
    }

    h2,
    .h2 {
        font-size: 2.2rem;
    }

    h4,
    .h4 {
        font-weight: 500;
        font-size: 1.2rem;
    }

    a:hover,
    a:focus {
        text-decoration: none;
    }

    .fw-700 {
        font-weight: 700;
    }

    .fw-600 {
        font-weight: 600;
    }

    .fw-500 {
        font-weight: 500;
    }

    .fw-400 {
        font-weight: 400;
    }

    .icon-bottom-arrow--right {
        display: inline-block;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    .alert_block_on_top a {
        text-decoration: underline;
        color: #400099;
    }

    .social-icon {
        position: fixed;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 0;
        background-color: #EFEFEF;
        padding: 0.5rem 0;
        z-index: 100;
        margin-bottom: 0;
    }

    .social-icon>li>a {
        display: block;
        color: #08214C;
        font-size: 1.4rem;
        padding: 0.5rem 1rem;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .social-icon>li>a:hover,
    .social-icon>li>a:focus {
        color: #400099;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    .box-title {
        color: #08214C;
        position: relative;
        margin-bottom: 0.5rem;
    }

    .box-title__subtitle {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    .box-title--style-1 {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .box-title--style-1::after {
        position: absolute;
        content: "";
        width: 70px;
        height: 2px;
        background-color: #400099;
        left: 0;
        bottom: 0;
    }

    .box-title--style-2::before {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        content: "";
        left: -70px;
        width: 64px;
        height: 2px;
        background-color: #400099;
    }

    .box-title--style-3::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: -10px;
        width: 100px;
        height: 2px;
        background-color: #400099;
    }

    .box-title--light {
        color: #fff;
    }

    .common-list--style-1>ul li {
        padding: 1.7rem 0rem 1.7rem 1.7rem;
        border-top: 1px solid rgba(8, 33, 76, 0.2);
    }

    .common-list--style-1>ul li:last-child {
        border-bottom: 1px solid rgba(8, 33, 76, 0.2);
    }

    .common-list--style-2>ul {
        margin: 0rem;
    }

    .common-list--style-2>ul li {
        position: relative;
        padding: 1.7rem 0rem 1.7rem 1.7rem;
        border-top: 2px solid #08214C;
    }

    .common-list--style-2>ul li:hover:before,
    .common-list--style-2>ul li:focus:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(245, 247, 249, 0.4);
    }

    .common-list--style-2>ul li:hover>a,
    .common-list--style-2>ul li:focus>a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);
    }

    .common-list--style-2>ul li>a {
        display: none;
        position: absolute;
        right: -3.5rem;
        bottom: 0;
        width: 50px;
        height: 100%;
        color: #fff;
        padding: 0rem;
        background-color: #400099;
    }

    @media (max-width:991px) {
        .common-list--style-2>ul li>a {
            width: 100%;
            height: 50px;
            right: 0;
        }
    }

    .common-list--style-2>ul li>a span {
        position: absolute;
        font-size: 1.07rem;
        font-weight: 500;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    @media (max-width:991px) {
        .common-list--style-2>ul li>a span {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .common-list--style-2>ul li:last-child {
        border-bottom: 2px solid #08214C;
    }

    .sub_product_links {
        padding: 15px;
    }

    .sub_product_links a {
        color: #400099;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        display: list-item;
        list-style-type: disc;
    }

    .sub_product_links a:hover {
        color: #FF6700;
        text-decoration: underline;
    }

    @font-face {
        font-family: 'droid_arabic_naskh';
        src: url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/droidsans/droidarabicnaskh-webfont.eot);
        src: url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/droidsans/droidarabicnaskh-webfont.eot#iefix) format("embedded-opentype"), url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/droidsans/droidarabicnaskh-webfont.woff2) format("woff2"), url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/droidsans/droidarabicnaskh-webfont.woff) format("woff"), url(https://www.smsaexpress.com/themes/custom/smsa/app/fonts/droidsans/droidarabicnaskh-webfont.ttf) format("truetype");
        font-weight: normal;
        font-style: normal;
    }

    header {
        height: 75px;
    }

    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }

    @media (min-width:992px) {
        .navbar {
            padding: 0;
        }
    }

    .navbar.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        margin: 0 auto;
        left: 0;
        right: 0;
        z-index: 999;
    }

    .navbar-brand {
        margin-right: 5rem;
        padding-top: 1.6rem !important;
    }

    @media screen and (max-width:1299px) {
        .navbar-brand {
            margin-right: 2rem;
        }
    }

    @media (max-width:1199px) {
        .navbar-brand {
            margin-right: 1rem;
        }
    }

    @media (max-width:991px) {
        .navbar-brand {
            margin-left: 4rem;
        }
    }

    .navbar-brand>img {
        height: 60px;
    }

    @media (max-width:1199px) {
        .navbar-brand>img {
            height: 45px;
        }
    }

    .navbar-toggler {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 5px;
    }

    .navbar-toggler:focus {
        outline: none;
    }

    .navbar-toggler-icon {
        width: 30px;
        height: 21px;
        background-image: none;
        position: relative;
        border-bottom: 1px solid #191717;
        -webkit-transition: all 300ms linear;
        -o-transition: all 300ms linear;
        -moz-transition: all 300ms linear;
        transition: all 300ms linear;
    }

    .navbar-toggler-icon::before,
    .navbar-toggler-icon::after {
        width: 100%;
        position: absolute;
        height: 1px;
        background-color: #191717;
        top: 0;
        left: 0;
        content: '';
        z-index: 2;
        -webkit-transition: all 300ms linear;
        -o-transition: all 300ms linear;
        -moz-transition: all 300ms linear;
        transition: all 300ms linear;
    }

    .navbar-toggler-icon::after {
        top: 9px;
    }

    .navbar-collapse {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    @media (max-width:991px) {
        .navbar-collapse {
            position: absolute;
            top: 100%;
            background-color: #fff;
            left: 0;
            border: 1px solid #ccc;
        }
    }

    .navbar-nav .nav-item {
        margin-right: 2rem;
    }

    .navbar-nav .nav-item:last-child {
        margin-right: 0;
    }

    @media screen and (max-width:1299px) {
        .navbar-nav .nav-item {
            margin-right: 1rem;
        }
    }

    @media (max-width:1199px) {
        .navbar-nav .nav-item {
            margin-right: 0;
        }
    }

    .nav-link {
        color: #08214C;
    }

    .nav-link:hover,
    .nav-link:focus {
        color: #400099;
    }

    @media (max-width:1199px) {
        .nav-link {
            font-size: 18px;
        }
    }

    .nav-link.dropdown-toggle::after {
        font-family: "icomoon";
        content: "î¤‰";
        color: #4D4D4F;
        vertical-align: middle;
        margin-left: 0.5rem;
        border: none;
    }

    .nav-right-side-lists {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 0;
    }

    .nav-right-side-lists>li [class*="icon-"] {
        color: #186AA5;
    }

    .nav-right-side-lists>li [class*="icon-"]:hover,
    .nav-right-side-lists>li [class*="icon-"]:focus {
        color: #400099;
    }

    .nav-right-side-lists>li>a {
        display: block;
        font-size: 1.2rem;
        white-space: nowrap;
        padding: 1.9rem 1.5rem;
        color: #08214C;
    }

    .nav-right-side-lists>li>a:hover,
    .nav-right-side-lists>li>a:focus {
        color: #400099;
    }

    @media (max-width:1199px) {
        .nav-right-side-lists>li>a {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    @media (max-width:767px) {

        .nav-right-side-lists>li.req-call,
        .nav-right-side-lists>li.req-quote {
            display: none;
        }
    }

    .nav-right-side-lists>li.req-call>a {
        background-color: #F6F6F6;
        font-size: 1.2rem;
    }

    .nav-right-side-lists>li.req-quote>a {
        background-color: #EFEFEF;
        font-weight: 700;
        text-transform: uppercase;
    }

    .search-box {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #400099;
        color: #fff;
        -webkit-transform: scale(0) !important;
        -moz-transform: scale(0) !important;
        -ms-transform: scale(0) !important;
        -o-transform: scale(0) !important;
        transform: scale(0) !important;
        -webkit-transition: transform 0.5s ease;
        -moz-transition: transform 0.5s ease;
        -o-transition: transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        transition: -webkit-transform 0.5s ease;
        -o-transition: -o-transform 0.5s ease;
        -moz-transition: transform 0.5s ease, -moz-transform 0.5s ease;
        transition: transform 0.5s ease;
        transition: transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease, -o-transform 0.5s ease;
    }

    .search-box.active {
        -webkit-transform: scale(1) !important;
        -moz-transform: scale(1) !important;
        -ms-transform: scale(1) !important;
        -o-transform: scale(1) !important;
        transform: scale(1) !important;
    }

    .search-box__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 2rem;
        line-height: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: #fff;
        color: #000;
        width: 30px;
        height: 30px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
    }

    .search-box .container,
    .search-box__inner {
        height: 100%;
    }

    .search-box .container {
        max-width: 1000px;
    }

    .search-box__inner {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .search-box .box-title {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1rem;
    }

    .search-box .form-group--search {
        position: relative;
    }

    .search-box .form-group--search .form-control {
        padding-right: 3rem;
    }

    .search-box .form-group--search .btn {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 0;
        font-size: 1.4rem;
    }

    .search-box .form-control {
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
    }

    .search-box .form-control.placeholder {
        color: rgba(24, 106, 165, 0.6);
        text-transform: uppercase;
        font-weight: 600;
    }

    .search-box .form-control:-moz-placeholder {
        color: rgba(24, 106, 165, 0.6);
        text-transform: uppercase;
        font-weight: 600;
    }

    .search-box .form-control::-moz-placeholder {
        color: rgba(24, 106, 165, 0.6);
        text-transform: uppercase;
        font-weight: 600;
    }

    .search-box .form-control:-ms-input-placeholder {
        color: rgba(24, 106, 165, 0.6);
        text-transform: uppercase;
        font-weight: 600;
    }

    .search-box .form-control::-webkit-input-placeholder {
        color: rgba(24, 106, 165, 0.6);
        text-transform: uppercase;
        font-weight: 600;
    }

    .search-box .input-group-prepend {
        background-color: #fff;
    }

    .search-box .input-group-prepend .btn {
        border: none;
        -webkit-border-radius: 0 8px 8px 0;
        -moz-border-radius: 0 8px 8px 0;
        border-radius: 0 8px 8px 0;
    }

    .canvas-navi-bar {
        -webkit-transition: all 275ms ease;
        -moz-transition: all 275ms ease;
        -o-transition: all 275ms ease;
        transition: all 275ms ease;
        position: relative;
        z-index: 2;
        padding: 15px 10px;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -moz-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -moz-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        width: 50px;
    }

    .canvas-navi-bar:hover .bar1 {
        width: 28px;
        -webkit-transform: translateY(7px) rotate(90deg);
        -moz-transform: translateY(7px) rotate(90deg);
        -ms-transform: translateY(7px) rotate(90deg);
        -o-transform: translateY(7px) rotate(90deg);
        transform: translateY(7px) rotate(90deg);
    }

    .canvas-navi-bar:hover .bar2 {
        width: 28px;
        margin: 4px 0 0;
    }

    .canvas-navi-bar:hover .bar3 {
        width: 0;
    }

    @media (min-width:992px) {
        .canvas-navi-bar:hover .bar1 {
            width: 28px;
        }

        .canvas-navi-bar:hover .bar2 {
            width: 28px;
        }
    }

    @media (min-width:992px) {
        .canvas-navi-bar:hover .bar1 {
            -webkit-transform: translateY(7px) rotate(90deg);
            -moz-transform: translateY(7px) rotate(90deg);
            -ms-transform: translateY(7px) rotate(90deg);
            -o-transform: translateY(7px) rotate(90deg);
            transform: translateY(7px) rotate(90deg);
        }
    }

    @media (min-width:992px) {
        .canvas-navi-bar:hover .bar3 {
            width: 0;
        }
    }

    .canvas-navi-bar .bar1 {
        width: 25px;
    }

    .canvas-navi-bar .bar2 {
        width: 12px;
    }

    .canvas-navi-bar .bar3 {
        width: 20px;
        margin-bottom: 0;
    }

    @media (max-width:991px) {
        .canvas-navi-bar {
            padding: 19px 5px;
        }
    }

    .canvas-navi-bar>span {
        display: block;
        height: 2px;
        margin-bottom: 5px;
        background-color: #fff;
        z-index: 2;
        margin: 6px 0 0 0;
        display: block;
        -webkit-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
        -o-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
        -moz-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .canvas-navi-bar>span:first-child {
        margin-top: 0;
    }

    .overlay {
        display: none;
    }

    @media (max-width:991px) {
        .overlay {
            display: none;
        }
    }

    .sidenav {
        color: #fff;
        top: 0;
        left: auto;
        right: -webkit-calc(-40% - 15px);
        right: -moz-calc(-40% - 15px);
        right: calc(-40% - 15px);
        width: 100%;
        height: 100%;
        -webkit-transition: all ease-out 1.5s;
        -o-transition: all ease-out 1.5s;
        -moz-transition: all ease-out 1.5s;
        transition: all ease-out 1.5s;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        width: 20%;
        position: fixed;
        opacity: 0;
        visibility: hidden;
        background: white;
        z-index: 999999999;
    }

    .sidenav .header-sidenav-menulist {
        padding: 0 40px 0;
        margin-top: 60%;
    }

    @media (max-width:991px) {
        .sidenav .header-sidenav-menulist {
            margin-top: 0 !important;
        }
    }

    .sidenav .header-sidenav-menulist ul {
        display: block !important;
    }

    @media (max-width:991px) {
        .sidenav .header-sidenav-menulist ul {
            padding-left: 0 !important;
        }
    }

    .sidenav .header-sidenav-menulist ul li {
        text-align: left;
    }

    .sidenav .header-sidenav-menulist ul li a {
        font-size: 1.2rem !important;
        line-height: 1.8;
    }

    .sidenav .header-sidenav-sociallist {
        padding: 0 25px 0;
        margin-top: 10%;
    }

    @media (max-width:991px) {
        .sidenav .header-sidenav-sociallist {
            margin-top: 0 !important;
            padding: 0 40px 20px !important;
        }
    }

    .sidenav .header-sidenav-sociallist ul {
        list-style: none;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        line-height: normal;
        list-style: none;
        padding: 0 15px;
        margin-bottom: 0;
    }

    @media (max-width:991px) {
        .sidenav .header-sidenav-sociallist ul {
            -webkit-box-pack: start !important;
            -webkit-justify-content: flex-start !important;
            -moz-box-pack: start !important;
            -ms-flex-pack: start !important;
            justify-content: flex-start !important;
            padding-left: 0 !important;
        }
    }

    .sidenav .header-sidenav-sociallist ul li a {
        font-size: 24px;
        margin-right: 10px;
        vertical-align: middle;
        padding: 0 2px 0 20px;
        line-height: normal;
        display: block;
        border-bottom: 1px solid #555;
        border-color: rgba(255, 255, 255, 0.15);
        line-height: 1.2;
        padding: 0 2px 0 20px;
        text-indent: -15px;
        text-decoration: none;
    }

    .sidenav .header-sidenav-sociallist ul li a i {
        color: #5D5D5D;
        -webkit-transition: 0.5s all;
        -o-transition: 0.5s all;
        -moz-transition: 0.5s all;
        transition: 0.5s all;
        vertical-align: middle;
    }

    .sidenav .header-sidenav-sociallist ul li a i:hover {
        -webkit-filter: invert(27%) sepia(51%) saturate(2878%);
        filter: invert(27%) sepia(51%) saturate(2878%);
    }

    @media (max-width:991px) {
        .sidenav {
            width: 100%;
            background-color: #43009B;
            color: #fff;
            top: 66px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -moz-box-orient: vertical;
            -moz-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            height: auto;
            position: absolute;
        }
    }

    .sidenav__inner {
        width: 100%;
    }

    @media (max-width:991px) {
        .sidenav__inner {
            height: auto;
            overflow-y: auto;
            max-height: 500px;
        }
    }

    .sidenav__close-btn {
        display: block;
        padding: 10px;
        position: absolute;
        left: 10px;
        top: 10px;
        -webkit-transition: -webkit-transform 0.5s ease;
        transition: -webkit-transform 0.5s ease;
        -o-transition: -o-transform 0.5s ease;
        -moz-transition: transform 0.5s ease, -moz-transform 0.5s ease;
        transition: transform 0.5s ease;
        transition: transform 0.5s ease, -webkit-transform 0.5s ease, -moz-transform 0.5s ease, -o-transform 0.5s ease;
    }

    .sidenav__close-btn:hover {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    @media (max-width:991px) {
        .sidenav__close-btn {
            display: none;
        }
    }

    .sidenav__close-btn>svg {
        width: 20px;
        height: 20px;
    }

    .sidenav__nav {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%;
    }

    @media (max-width:991px) {
        .sidenav__nav {
            -webkit-box-align: start;
            -webkit-align-items: flex-start;
            -moz-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            padding-left: 28px;
            padding-bottom: 20px;
        }
    }

    @media (max-width:991px) {
        .sidenav__nav>li {
            display: inline-block;
            width: 100%;
            margin-top: 7px;
        }
    }

    .sidenav__nav>li>a {
        display: inline-block;
        position: relative;
        color: #08214C;
        font-family: "Montserrat", sans-serif;
        font-size: 2rem;
        line-height: 1.5;
    }

    @media (min-width:992px) {
        .sidenav__nav>li>a {
            text-decoration: none;
            overflow: hidden;
            font-size: 3rem;
            background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #FF6700), color-stop(0, #08214C));
            background-image: -webkit-linear-gradient(left, #FF6700 50%, #08214C 0);
            background-image: -moz-linear-gradient(left, #FF6700 50%, #08214C 0);
            background-image: -o-linear-gradient(left, #FF6700 50%, #08214C 0);
            background-image: linear-gradient(left, #FF6700 50%, #08214C 0);
            background-position: 100% 0;
            -webkit-background-size: 200% 100%;
            -moz-background-size: 200% 100%;
            -o-background-size: 200% 100%;
            background-size: 200% 100%;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-transition: background-position 1s cubic-bezier(0.19, 1, 0.22, 1) !important;
            -o-transition: background-position 1s cubic-bezier(0.19, 1, 0.22, 1) !important;
            -moz-transition: background-position 1s cubic-bezier(0.19, 1, 0.22, 1) !important;
            transition: background-position 1s cubic-bezier(0.19, 1, 0.22, 1) !important;
        }
    }

    @media (max-width:991px) {
        .sidenav__nav>li>a {
            color: #fff;
            font-size: 1.28rem;
            padding: 5px;
            font-family: "Montserrat", sans-serif;
        }
    }

    @media (min-width:992px) {
        .sidenav__nav>li>a::after {
            content: '';
            display: inline-block;
            width: 0;
            height: 3px;
            background-color: #FF6700;
            position: absolute;
            bottom: 0;
            left: 0;
            -webkit-transition: width 1s cubic-bezier(0.19, 1, 0.22, 1) !important;
            -o-transition: width 1s cubic-bezier(0.19, 1, 0.22, 1) !important;
            -moz-transition: width 1s cubic-bezier(0.19, 1, 0.22, 1) !important;
            transition: width 1s cubic-bezier(0.19, 1, 0.22, 1) !important;
        }
    }

    @media (max-width:991px) {

        .sidenav__nav>li>a:hover,
        .sidenav__nav>li>a:focus {
            color: #FF6700;
        }
    }

    @media (min-width:992px) {

        .sidenav__nav>li>a:hover,
        .sidenav__nav>li>a:focus {
            color: #FF6700;
            text-decoration: none;
            background-position: 0 0;
        }

        .sidenav__nav>li>a:hover::after,
        .sidenav__nav>li>a:focus::after {
            width: 100%;
        }
    }

    @media (min-width:992px) {
        .nav-item.active .canvas-navi-bar {
            pointer-events: none;
        }
    }

    .nav-item.active .canvas-navi-bar .bar1 {
        width: 25px;
    }

    .nav-item.active .canvas-navi-bar .bar2 {
        width: 28px;
    }

    .nav-item.active .canvas-navi-bar .bar1 {
        -webkit-transform: translateY(7px) rotate(90deg);
        -moz-transform: translateY(7px) rotate(90deg);
        -ms-transform: translateY(7px) rotate(90deg);
        -o-transform: translateY(7px) rotate(90deg);
        transform: translateY(7px) rotate(90deg);
    }

    .nav-item.active .canvas-navi-bar .bar3 {
        width: 0;
    }

    .nav-item.active .sidenav {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-box-shadow: 0 0 100px rgba(136, 142, 160, 0.4);
        -moz-box-shadow: 0 0 100px rgba(136, 142, 160, 0.4);
        box-shadow: 0 0 100px rgba(136, 142, 160, 0.4);
        -webkit-transition: all ease-in 1.4s;
        -o-transition: all ease-in 1.4s;
        -moz-transition: all ease-in 1.4s;
        transition: all ease-in 1.4s;
        filter: alpha(opacity=100);
        visibility: visible;
        right: 0;
        z-index: 999999999999;
        visibility: visible;
    }

    @media (max-width:767px) {
        .nav-item.active .sidenav {
            max-height: 500px;
            overflow-y: auto;
        }
    }

    .nav-item.active .sidenav .header-sidenav-menulist {
        padding: 0 40px 0;
        margin-top: 60%;
    }

    .nav-item.active .sidenav .header-sidenav-sociallist {
        padding: 0 25px 0;
        margin-top: 10%;
    }

    .hamburger {
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        padding: .5rem 1rem;
        background-color: transparent;
        border: none;
    }

    .sidenav__close-btn {
        left: auto;
        right: 10px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: -webkit-transform 1.6s ease;
        -webkit-transition: -webkit-transform 1.6s ease;
        -o-transition: -o-transform 1.6s ease;
        -moz-transition: transform 1.6s ease, -moz-transform 1.6s ease;
        transition: transform 1.6s ease;
        transition: transform 1.6s ease, -webkit-transform 1.6s ease, -moz-transform 1.6s ease, -o-transform 1.6s ease;
        transition: transform 1.6s ease, -webkit-transform 1.6s ease;
        opacity: .8;
    }

    .sidenav__close-btn:hover {
        -webkit-transform: rotate(-1turn);
        -moz-transform: rotate(-1turn);
        -ms-transform: rotate(-1turn);
        -o-transform: rotate(-1turn);
        transform: rotate(-1turn);
        opacity: 1;
    }

    .sidenav__nav>li {
        text-align: right;
    }

    .sidenav__nav>li>a {
        font-size: 2.2rem !important;
    }

    .user-logged-in header {
        height: 75px;
    }

    .user-logged-in header .navbar.sticky {
        top: 79px;
    }

    html[dir="rtl"] .nav-item.active .sidenav {
        left: 0;
        right: auto;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .nav-item.active .sidenav {
            max-height: 500px;
            overflow-y: auto;
        }
    }

    html[dir="rtl"] .nav-item .overlay {
        left: 0;
        right: auto;
    }

    html[dir="rtl"] .sidenav__close-btn {
        left: 10px;
        right: auto;
    }

    html[dir="rtl"] .sidenav__nav>li {
        text-align: right;
    }

    html[dir="rtl"] .sidenav__nav>li>a {
        font-size: 22px !important;
        font-family: 'Cairo', sans-serif;
        line-height: 1.5;
    }

    html[dir="rtl"] .sidenav__nav>li>a:hover::after {
        left: auto;
        right: 0;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .sidenav__inner {
            height: auto;
            overflow-y: auto;
        }
    }

    @media (max-width:767px) {
        html[dir="rtl"] .header-sidenav-menulist {
            margin-top: 0;
        }
    }

    html[dir="rtl"] .header-sidenav-menulist ul {
        padding-right: 0 !important;
    }

    html[dir="rtl"] .header-sidenav-menulist ul li {
        text-align: right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .header-sidenav-sociallist {
            margin-top: 0;
        }
    }

    .owl-carousel .owl-nav.disabled+.owl-dots {
        margin-top: 1rem;
    }

    .owl-carousel .owl-nav .disabled {
        cursor: no-drop;
    }

    .owl-carousel--style-1 .owl-dots,
    .owl-carousel--style-2 .owl-dots {
        counter-reset: slides-num;
        top: 100%;
        width: 130px;
        line-height: 1;
    }

    .owl-carousel--style-1 .owl-dots::before,
    .owl-carousel--style-2 .owl-dots::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 45%;
    }

    .owl-carousel--style-1 .owl-dots:after,
    .owl-carousel--style-2 .owl-dots:after {
        content: "0" counter(slides-num);
        position: absolute;
        right: -4px;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .owl-carousel--style-1 .owl-dots .owl-dot span,
    .owl-carousel--style-2 .owl-dots .owl-dot span {
        display: none;
    }

    .owl-carousel--style-1 .owl-dot,
    .owl-carousel--style-2 .owl-dot {
        display: inline-block;
        counter-increment: slides-num;
        margin-right: 5px;
    }

    .owl-carousel--style-1 .owl-dot.active:before,
    .owl-carousel--style-2 .owl-dot.active:before {
        content: "0" counter(slides-num);
        font-size: 1.3rem;
        position: absolute;
        left: 0;
        top: 0;
        font-weight: 600;
    }

    .owl-carousel--style-1 .owl-dots {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .owl-carousel--style-1 .owl-dots::before {
        border-top: 2px solid #08214C;
    }

    .owl-carousel--style-1 .owl-dots:after {
        color: #08214C;
    }

    .owl-carousel--style-1 .owl-dot.active:before {
        color: #400099;
    }

    .owl-carousel--style-2 .owl-dots {
        position: absolute;
        left: 0;
    }

    .owl-carousel--style-2 .owl-dots::before {
        border-top: 2px solid #fff;
    }

    .owl-carousel--style-2 .owl-dots:after {
        color: #fff;
    }

    .owl-carousel--style-2 .owl-dot.active:before {
        color: #400099;
    }

    .owl-carousel--style-3 .owl-nav {
        margin-top: 0;
    }

    .owl-carousel--style-3 .owl-nav button[class*='owl-'] {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 30px;
        width: 30px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        background-color: #186AA5;
        margin: 0;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .owl-carousel--style-3 .owl-nav button[class*='owl-']:hover,
    .owl-carousel--style-3 .owl-nav button[class*='owl-']:focus {
        background-color: #08214C;
    }

    .owl-carousel--style-3 .owl-nav button[class*='owl-']::after {
        position: absolute;
        content: "î¤‰";
        font-family: "icomoon";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
    }

    .owl-carousel--style-3 .owl-nav button[class*='owl-']:focus {
        outline: none;
    }

    .owl-carousel--style-3 .owl-nav button[class*='owl-'].disabled:hover,
    .owl-carousel--style-3 .owl-nav button[class*='owl-'].disabled:focus {
        background-color: #186AA5;
    }

    .owl-carousel--style-3 .owl-nav button[class*='owl-']>span {
        display: none;
    }

    .owl-carousel--style-3 .owl-nav .owl-prev {
        left: 1.5rem;
    }

    .owl-carousel--style-3 .owl-nav .owl-prev::after {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    @media (max-width:767px) {
        .owl-carousel--style-3 .owl-nav .owl-prev {
            left: 0;
        }
    }

    .owl-carousel--style-3 .owl-nav .owl-next {
        right: 1.5rem;
    }

    .owl-carousel--style-3 .owl-nav .owl-next::after {
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    @media (max-width:767px) {
        .owl-carousel--style-3 .owl-nav .owl-next {
            right: 0;
        }
    }

    button:focus {
        outline: none;
    }

    .btn-link {
        font-weight: 600;
    }

    .btn-link--arrow {
        padding-left: 0;
    }

    .btn-link--arrow::after {
        content: "î¤‰";
        font-family: "icomoon";
        display: inline-block;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
        margin-left: 0.3rem;
        vertical-align: middle;
    }

    .btn-link--light {
        color: #fff;
    }

    .btn-link--light:hover,
    .btn-link--light:focus {
        color: #fff;
    }

    .btn--primary {
        font-family: "Montserrat", sans-serif;
        font-size: 1.07rem;
        color: #fff;
        font-weight: 500;
        background-color: #400099;
        padding: 1.07rem 1.4rem;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .btn--primary:hover,
    .btn--primary:focus {
        color: #fff;
        background-color: #08214C;
    }

    .btn--primary-lg {
        padding: 27px 66px 27px 44px;
    }

    #main .nav .breadcrumb {
        text-align: right;
        position: relative;
    }

    #main .nav .breadcrumb li {
        width: 100%;
    }

    #main .nav .breadcrumb li a {
        display: inline-block;
        text-align: right;
    }

    .block-system-breadcrumb-block {
        width: 100%;
        position: relative;
    }

    .block-system-breadcrumb-block .container {
        position: relative;
        z-index: 2;
    }

    .block-system-breadcrumb-block .breadcrumb {
        font-size: 1rem;
        position: relative;
        right: 0;
        top: 0px;
        z-index: 999;
        float: left;
    }

    @media (max-width:767px) {
        .block-system-breadcrumb-block .breadcrumb {
            top: 0;
        }
    }

    .block-system-breadcrumb-block nav {
        z-index: 99;
        float: left;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    @media (max-width:767px) {
        .block-system-breadcrumb-block nav {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }

    .page-withbanner .block-system-breadcrumb-block {
        width: 100%;
        position: relative;
    }

    .page-withbanner .block-system-breadcrumb-block .container {
        position: relative;
        z-index: 2;
    }

    .page-withbanner .block-system-breadcrumb-block .breadcrumb {
        font-size: 1rem;
        position: relative;
        right: 0;
        top: 0px;
        z-index: 999;
        float: left;
    }

    @media (max-width:767px) {
        .page-withbanner .block-system-breadcrumb-block .breadcrumb {
            top: -15px;
        }
    }

    .page-withbanner .block-system-breadcrumb-block nav {
        z-index: 99;
        float: left;
        width: 100%;
        margin-top: 20px;
    }

    @media (max-width:767px) {
        .page-withbanner .block-system-breadcrumb-block nav {
            margin-bottom: 10px;
        }
    }

    .breadcrumb {
        background-color: transparent;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        position: absolute;
        top: 5px;
        right: 0;
    }

    .breadcrumb-item a {
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
    }

    .breadcrumb-item a:hover {
        color: #FF6700;
    }

    #block-smsa-breadcrumbs::after {
        display: block;
        clear: both;
        content: "";
    }

    html[dir="rtl"] .breadcrumb-item a {
        font-family: 'Cairo', sans-serif;
        font-weight: 600;
    }

    html[dir="rtl"] .block-system-breadcrumb-block .breadcrumb {
        float: right;
    }

    html[dir="rtl"] .page-withbanner .block-system-breadcrumb-block .breadcrumb {
        float: right;
    }

    .user-logged-in .block-system-breadcrumb-block .breadcrumb {
        z-index: 99;
    }

    .dc-server-uptime-box {
        background-color: #000;
        color: #fff;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    @media (max-width:767px) {
        .dc-server-uptime-box {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    .dc-server-uptime-box .row {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .dc-server-uptime-box__title {
        font-size: 2rem;
        font-weight: 600;
    }

    @media (max-width:991px) {
        .dc-server-uptime-box__title {
            font-size: 1.5rem;
        }
    }

    @media (max-width:767px) {
        .dc-server-uptime-box__title {
            text-align: center;
            margin-bottom: 1rem;
        }
    }

    .dc-server-uptime-box-lists {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }

    @media (max-width:767px) {
        .dc-server-uptime-box-lists {
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
    }

    .dc-server-uptime-box-lists>li {
        padding: 1rem 3rem 0.6rem 3rem;
        border-left: 1px solid #9CD6FF;
    }

    @media (max-width:1199px) {
        .dc-server-uptime-box-lists>li {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    @media (max-width:991px) {
        .dc-server-uptime-box-lists>li {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    @media (max-width:767px) {
        .dc-server-uptime-box-lists>li {
            padding: 0.5rem 0.7rem;
        }

        .dc-server-uptime-box-lists>li:first-child {
            border-left: none;
        }
    }

    .dc-server-uptime-box-lists__number {
        font-size: 2.6rem;
        line-height: 1;
    }

    @media (max-width:991px) {
        .dc-server-uptime-box-lists__number {
            font-size: 2rem;
        }
    }

    @media (max-width:767px) {
        .dc-server-uptime-box-lists__number {
            font-size: 1.5rem;
        }
    }

    .dc-server-uptime-box-lists__lbl {
        font-size: 0.9rem;
        margin-bottom: 0;
        font-weight: 700;
    }

    .contact-info-box {
        color: #000;
        overflow: hidden;
        padding: 5rem 0;
    }

    @media (max-width:991px) {
        .contact-info-box {
            padding: 2rem 0;
        }
    }

    .contact-info-box .container {
        position: relative;
    }

    .contact-info-box__img-box {
        position: absolute;
        width: 70%;
        right: 0;
        top: 70%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    @media (max-width:767px) {
        .contact-info-box__img-box {
            position: static;
            width: 100%;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
        }
    }

    .contact-info-box__text {
        position: relative;
        z-index: 1;
    }

    @media (max-width:767px) {
        .contact-info-box__text {
            margin-right: -15px;
        }
    }

    .contact-info-box__title {
        color: #08214C;
    }

    .contact-info-box__subtitle {
        color: #400099;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .contact-info-box__desc {
        background-color: #08214C;
        padding-top: 4.3rem;
        padding-bottom: 5.6rem;
        padding-right: 3rem;
        margin-top: 4rem;
        margin-bottom: 4rem;
        color: #fff;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
        max-width: 540px;
    }

    @media (max-width:991px) {
        .contact-info-box__desc {
            max-width: 400px;
            padding-right: 1rem;
        }
    }

    @media (max-width:767px) {
        .contact-info-box__desc {
            max-width: 100%;
            padding-right: 4rem;
        }
    }

    @media (max-width:575px) {
        .contact-info-box__desc {
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding-top: 2rem;
            padding-bottom: 3rem;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -moz-box-orient: vertical;
            -moz-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }
    }

    .contact-info-box__desc .btn {
        position: absolute;
        bottom: -2rem;
        right: 1rem;
        padding: 1rem 2rem;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        font-weight: 500;
    }

    .contact-info-box__desc .btn i {
        display: inline-block;
        vertical-align: middle;
        margin-left: 1rem;
        font-size: 85%;
    }

    .contact-info-box__desc address {
        margin-bottom: 0;
    }

    .contact-info-box__desc-left-bg {
        position: absolute;
        background-color: #08214C;
        height: 100%;
        top: 0;
    }

    .contact-info-box__office {
        margin-right: 1rem;
    }

    .contact-info-box__desc-title {
        text-transform: uppercase;
        font-weight: 600;
        color: #400099;
    }

    @media (max-width:575px) {
        .contact-info-box__contact {
            margin-top: 1rem;
        }
    }

    .contact-info-box__contact p {
        margin-bottom: 0;
    }

    .site-footer .contact-info-box__title {
        color: #08214C;
    }

    .accordion .card {
        border: none;
        background-color: transparent;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border-top: 2px solid rgba(8, 33, 76, 0.2);
    }

    .accordion .card:last-of-type {
        border-bottom: 2px solid rgba(8, 33, 76, 0.2);
    }

    .accordion .card-header {
        padding: 0;
        margin-bottom: 0;
        background-color: transparent;
        border-bottom: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    .accordion .card-link {
        font-size: 15px;
        color: #08214C;
        display: block;
        border: none;
        position: relative;
    }

    .accordion .card-link::after {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        content: "î¤ˆ";
        font-family: "icomoon";
        font-size: 21px;
        color: #186AA5;
    }

    .accordion .card-link[aria-expanded="true"]::after {
        content: "î¤›";
        font-size: 10%;
        top: 100%;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    .accordion .card-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    .accordion--style-right .card-link::after {
        right: 0;
    }

    #navbar-main {
        padding: 0 1.5rem;
        height: 75px;
        background-color: #400099 !important;
        z-index: 999999;
        max-width: 1366px;
        margin: 0 auto;
        width: 100%;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-brand {
            margin-left: 0;
            width: 40%;
            float: left;
            margin-right: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-brand {
            margin-left: 0;
            width: 40%;
            float: left;
            margin-right: 0;
        }
    }

    @media (max-width:767px) {
        #navbar-main .navbar-toggler {
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-toggler {
            display: none;
        }
    }

    #navbar-main .navbar-collapse {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
        -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-collapse {
            position: absolute;
            top: 100%;
            background-color: #400099;
            left: 0;
            border: 0;
            padding: 1rem;
            z-index: 9;
        }
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form {
            float: right;
            width: 60%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form {
            float: right;
            width: 60%;
        }
    }

    #navbar-main .navbar-form .region-header-form {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form {
            display: block;
            width: 100%;
            margin: 0;
            text-align: left;
            float: left;
            margin-bottom: 0px;
            font-family: "Montserrat", sans-serif;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form {
            display: block;
            width: 100%;
            margin: 0;
            text-align: left;
            float: left;
            margin-bottom: 0px;
        }
    }

    #navbar-main .navbar-form .region-header-form ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        list-style: none;
        margin: 0;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form ul {
            -webkit-box-pack: end;
            -webkit-justify-content: flex-end;
            -moz-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form ul {
            -webkit-box-pack: end;
            -webkit-justify-content: flex-end;
            -moz-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form ul li:nth-child(2) {
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form ul li:nth-child(2) {
            display: none;
        }
    }

    #navbar-main .navbar-form .region-header-form ul li a.nav-link {
        color: #ffffff;
        line-height: 2;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form ul li a.nav-link {
            padding: 8px;
        }
    }

    #navbar-main .navbar-form .region-header-form ul li a.nav-link span {
        margin-left: 10px;
    }

    #navbar-main .navbar-form .region-header-form ul li a.nav-link::after {
        line-height: 0;
    }

    #navbar-main .navbar-form .region-header-form ul li a.nav-link.dropdown-toggle::after {
        content: "\e909" !important;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form ul li a i {
            font-size: 20px;
            line-height: inherit;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form ul li a i {
            font-size: 20px;
            line-height: inherit;
        }
    }

    #navbar-main .navbar-form .region-header-form ul li a i:hover {
        -webkit-filter: invert(27%) sepia(51%) saturate(2878%);
        filter: invert(27%) sepia(51%) saturate(2878%);
    }

    #navbar-main .navbar-form .region-header-form ul li a.dropdown-item {
        padding: 0 1.5rem;
    }

    #navbar-main .navbar-form .region-header-form ul li a.dropdown-item span {
        margin-left: -30px;
        background-repeat: no-repeat;
        padding-left: 40px;
        background-position: left center;
        font-family: "Montserrat", sans-serif;
        font-weight: inherit;
        font-size: 18px;
        -webkit-background-size: 30px 30px;
        -moz-background-size: 30px;
        -o-background-size: 30px;
        background-size: 30px;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form ul li a.dropdown-item span {
            margin-left: -15px;
        }
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown {
        position: static;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu {
        left: 0;
        right: 0;
        z-index: 9999;
        width: 100%;
        top: 98%;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background-color: #eef1f5;
        padding: 0;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu {
            top: 98%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu {
            top: 98%;
        }
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu.show {
        display: block;
        opacity: 1;
        visibility: visible;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item {
        padding: 0;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form {
        position: relative;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form input {
        width: 100%;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: #ff6600;
        border: 0;
        height: 60px;
        color: #fff;
        padding: 0 25px;
        font-size: 20px;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form input::-webkit-input-placeholder {
        color: #fff;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form input::-moz-placeholder {
        color: #fff;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form input :-ms-input-placeholder {
        color: #fff;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form button.form-submit {
        background-color: transparent;
        color: #ffffff00;
        border: 0;
        position: absolute;
        right: 0;
        top: 0;
        padding: 15px;
        height: 60px;
        width: 60px;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form button.form-submit:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form button span {
        display: none;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form button svg {
        width: 25px;
        height: 25px;
    }

    #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form button svg path {
        stroke: #fff;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock {
            display: block;
            float: left;
            width: 100%;
            padding: 15px 50px 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock {
            display: block;
            float: left;
            width: 100%;
            padding: 15px 50px 0;
        }
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-dropdownlanguage {
            float: left;
            width: 100%;
            padding: 0 50px 15px 40px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-dropdownlanguage {
            float: left;
            width: 100%;
            padding: 0 50px 15px 40px;
        }
    }

    .search-box {
        width: auto;
        height: auto;
        background-color: transparent;
    }

    .dropbutton-wrapper.open .dropbutton-arrow {
        top: 5px !important;
    }

    .dropbutton-wrapper.open .dropbutton-arrow:hover {
        background-color: transparent !important;
    }

    .dropbutton-wrapper .dropbutton-widget {
        background-color: transparent !important;
        background-image: none !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        border: 0 !important;
        color: #ffffff !important;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton {
        border-right: 0;
        border-left: 0;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li:hover {
        background-color: transparent !important;
        color: #FF6700;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-toggle button {
        background-image: none !important;
        background-color: transparent !important;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-toggle button .dropbutton-arrow {
        color: #ffffff !important;
        position: static;
        line-height: normal;
        border-width: 0;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-toggle button .dropbutton-arrow:hover {
        background-color: transparent !important;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-toggle button .dropbutton-arrow:before {
        position: absolute;
        top: 10px;
        left: -5px;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #fff;
        font-size: 10px;
        font-weight: lighter;
    }

    @media (max-width:767px) {
        .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-toggle button .dropbutton-arrow:before {
            right: 0;
            left: auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-toggle button .dropbutton-arrow:before {
            right: 0;
            left: auto;
        }
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.secondary-action {
        border-top-color: transparent !important;
        border-top: 0 !important;
        line-height: 16px;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.secondary-action a {
        color: #ffffff;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.secondary-action a:hover {
        background-color: #e2e0e0 !important;
        color: #FF6700;
        border: 0 !important;
        outline: none;
    }

    .js .block-dropdown-language .dropbutton-wrapper .dropbutton-widget {
        position: relative;
        overflow: visible;
    }

    @media (max-width:767px) {
        .js .block-dropdown-language .dropbutton-wrapper .dropbutton-widget {
            float: left;
            width: 100%;
            padding-right: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .js .block-dropdown-language .dropbutton-wrapper .dropbutton-widget {
            float: left;
            width: 100%;
            padding-right: 0;
        }
    }

    #navbar-main .navbar-form .region-header-form ul.dropdown-language-item {
        overflow: visible;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form ul.dropdown-language-item {
            width: 100% !important;
            display: inline-block;
            float: left;
            position: relative;
            text-align: left;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form ul.dropdown-language-item {
            width: 100% !important;
            display: inline-block;
            float: left;
            position: relative;
            text-align: left;
        }
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action {
        position: absolute;
        bottom: -67px;
        background-color: #FAFAFA;
        color: #4D4D4F;
        width: 100%;
        text-align: left;
        padding-left: 10px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }

    @media (max-width:767px) {
        .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action {
            position: relative;
            bottom: 0;
            border-top: none;
            border: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action {
            position: relative;
            bottom: 0;
            border-top: none;
            border: none;
        }
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action a {
        color: #4D4D4F;
        padding: 10px;
    }

    @media (max-width:767px) {
        .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action a {
            padding: 10px 10px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action a {
            padding: 10px 10px !important;
        }
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action a:hover {
        color: #FF6700;
    }

    .dropbutton-wrapper .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action:hover {
        background-color: #e2e0e0 !important;
        color: #FF6700;
    }

    #header .navbar-form .nav-link.dropdown-toggle::after {
        color: #ffffff;
    }

    #navbar-main .navbar-form .region-header-form {
        font-family: "Montserrat", sans-serif;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock {
            padding: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock {
            padding: 0;
        }
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown {
        width: auto;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown {
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown {
            width: 100%;
        }
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn {
        background: transparent;
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        color: #fff;
        height: 75px;
        position: relative;
        padding: 0 30px;
        -webkit-transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, -webkit-box-shadow 0s ease-in-out;
        transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, -webkit-box-shadow 0s ease-in-out;
        -o-transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out;
        -moz-transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out, -moz-box-shadow 0s ease-in-out;
        transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out;
        transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out, -webkit-box-shadow 0s ease-in-out, -moz-box-shadow 0s ease-in-out;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn {
            height: auto;
            padding: 8px 30px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn {
            height: auto;
            padding: 8px 30px;
        }
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn::after {
        position: absolute;
        left: auto;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn:focus {
        border: 0 !important;
        outline: 0 !important;
        outline-offset: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn .filter-option {
        height: auto !important;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn .filter-option .filter-option-inner-inner {
        font-size: 19px;
        padding-right: 10px;
        font-family: "Montserrat", sans-serif;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn .filter-option .filter-option-inner-inner span {
        background-repeat: no-repeat;
        padding-left: 40px;
        line-height: 2;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn {
        background: #eef1f5;
        height: 75px;
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        padding: 0 30px;
        color: #212529;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn {
            height: auto;
            padding: 8px 30px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn {
            height: auto;
            padding: 8px 30px;
        }
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn:focus {
        outline: 0 !important;
        border: 0 !important;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn .filter-option {
        height: auto !important;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn .filter-option .filter-option-inner-inner {
        font-size: 18px;
        padding-right: 10px;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn .filter-option .filter-option-inner-inner span {
        background-repeat: no-repeat;
        padding-left: 40px;
        line-height: 2;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn::after {
        position: absolute;
        left: auto;
        content: "\e91d";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu {
            top: 98%;
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu {
            top: 98%;
            display: none;
        }
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show {
        width: 380px;
        float: left;
        padding: 10px 0;
        margin: 0;
        border: 0;
        background-color: #eef1f5;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 99999;
        overflow-x: hidden;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show {
            width: 100%;
            display: block;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show {
            width: 100%;
            display: block;
        }
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .bs-searchbox {
        position: relative;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .bs-searchbox input {
        border: 1px solid #ccc;
        outline: 0;
        padding: 0 .5em;
        height: 40px;
        padding-right: 32px;
        margin-bottom: 0;
        width: 100%;
        font-family: "Montserrat", sans-serif;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .bs-searchbox::after {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        color: #ccc;
        z-index: 100;
        font-size: 1em;
        top: 50%;
        margin-top: -.5em;
        position: absolute;
        left: auto;
        right: 20px;
        content: "";
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .inner.show {
        max-height: 300px;
        height: 100%;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .inner.show ul li {
        border: 0;
        outline: 0;
        border-top: 1px solid #ededed;
        padding: 3px 20px;
        line-height: 2;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .inner.show ul li:hover {
        background-color: #e0e6ed;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .inner.show ul li a:hover {
        background-color: transparent;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .inner.show ul li.active {
        background-color: #e0e6ed;
        color: #400099;
    }

    #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .dropdown-menu.show .inner.show ul li.active a {
        background-color: transparent;
        color: #400099;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .language-switcher {
            float: left;
            width: 100%;
            display: block;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .language-switcher {
            float: left;
            width: 100%;
            display: block;
        }
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown-menu {
            top: 19% !important;
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown-menu {
            top: 17% !important;
            display: none;
        }
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown {
        width: auto;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown {
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown {
            width: 100%;
        }
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn {
        background: transparent;
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        color: #fff;
        height: 75px;
        position: relative;
        padding: 0 30px;
        -webkit-transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, -webkit-box-shadow 0s ease-in-out;
        transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, -webkit-box-shadow 0s ease-in-out;
        -o-transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out;
        -moz-transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out, -moz-box-shadow 0s ease-in-out;
        transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out;
        transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out, -webkit-box-shadow 0s ease-in-out, -moz-box-shadow 0s ease-in-out;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn {
            height: auto;
            padding: 8px 30px;
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn {
            height: auto;
            padding: 8px 30px;
            margin-bottom: 10px;
        }
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn:focus {
        border: 0 !important;
        outline: 0 !important;
        outline-offset: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn .filter-option {
        height: auto !important;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn .filter-option .filter-option-inner-inner {
        padding-right: 10px;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn .filter-option .filter-option-inner-inner span {
        background-repeat: no-repeat;
        padding-left: 40px;
        line-height: 2;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn::after {
        position: absolute;
        left: auto;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn {
        background: #eef1f5;
        height: 75px;
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        padding: 0 30px;
        color: #212529;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn {
            height: auto;
            padding: 8px 30px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn {
            height: auto;
            padding: 8px 30px;
        }
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn:focus {
        outline: 0 !important;
        border: 0 !important;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn .filter-option {
        height: auto !important;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn .filter-option .filter-option-inner-inner {
        padding-right: 10px;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn .filter-option .filter-option-inner-inner span {
        background-repeat: no-repeat;
        padding-left: 40px;
        line-height: 2;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn::after {
        position: absolute;
        left: auto;
        content: "\e91d";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show {
        width: 200px;
        padding: 0;
        float: left;
        margin: 0;
        border: 0;
        background-color: #eef1f5;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-height: 280px !important;
        height: auto;
        z-index: 99999;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show {
            display: block;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show {
            display: block;
        }
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li {
        border: 0;
        outline: 0;
        border-top: 1px solid #ededed;
        padding: 8px 20px;
        line-height: 2;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li:first-child a span {
        font-family: "Montserrat", sans-serif !important;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li:hover {
        background-color: #e0e6ed;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li a:hover {
        background-color: transparent;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li.active {
        background-color: #e0e6ed;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li.active a {
        background-color: transparent;
        color: #400099;
        position: relative;
        padding-left: 0;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li.active a::before {
        font-family: "icomoon" !important;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        content: "\e91e";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li a {
        padding-left: 15px;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li a:hover {
        background-color: transparent !important;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li a:focus {
        outline: 0;
        background: transparent;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li:hover {
        background-color: #e0e6ed;
    }

    #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .dropdown-menu.show .inner.show ul li:hover a {
        color: #400099;
    }

    #navbar-main .navbar-form .region-header-form .country-select {
        position: relative;
        display: inline-block;
        margin-top: 0;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .country-select {
            width: 100%;
            margin-right: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .country-select {
            width: 100%;
            margin-right: 0;
        }
    }

    #navbar-main .navbar-form .region-header-form .country-select #countries {
        display: none;
    }

    #navbar-main .navbar-form .region-header-form .country-select .button {
        display: none;
    }

    #navbar-main .navbar-form .region-header-form .country-select .button span {
        padding-left: 40px;
        -webkit-background-size: 30px 30px;
        -moz-background-size: 30px;
        -o-background-size: 30px;
        background-size: 30px;
        background-position: top left;
        background-repeat: no-repeat;
        color: #fff;
    }

    #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link {
        padding: 5px 10px;
        position: relative;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link {
            padding: 5px 10px;
            position: relative;
            right: 0;
            top: -26px;
            height: 24px;
            width: 100%;
            display: block;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link {
            padding: 5px 10px;
            position: relative;
            right: 0;
            top: -26px;
            height: 24px;
            width: 100%;
            display: block;
        }
    }

    #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link:before {
        position: absolute;
        top: 11px;
        right: 0;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #fff;
        font-size: 10px;
        font-weight: lighter;
    }

    #navbar-main .navbar-form .region-header-form .country-select .select-list {
        position: absolute;
        top: 58px;
        left: 0;
        z-index: 9999999999;
        padding: 0;
        background-color: #FAFAFA;
        display: block;
        height: 300px;
        overflow-y: auto;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .country-select .select-list {
            height: 180px;
            width: 100%;
            margin-top: 0;
            position: static;
            overflow-y: auto;
            overflow-x: hidden;
            border: 1px solid rgba(212, 201, 226, 0.37);
            padding: 10px;
            background: #43009b;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .country-select .select-list {
            height: 180px;
            width: 100%;
            margin-top: 0;
            position: static;
        }
    }

    #navbar-main .navbar-form .region-header-form .country-select .select-list li {
        display: none;
    }

    #navbar-main .navbar-form .region-header-form .country-select .select-list li span {
        padding-left: 40px;
        -webkit-background-size: auto auto;
        -moz-background-size: auto;
        -o-background-size: auto;
        background-size: auto;
        background-position: top left;
        background-repeat: no-repeat;
        color: #4D4D4F;
        display: inline-block;
        min-width: -webkit-max-content;
        min-width: -moz-max-content;
        min-width: max-content;
        width: 100%;
        padding: 5px 15px 5px 60px;
        background-color: #FAFAFA;
        background-position: left 10px center;
        background-repeat: no-repeat;
        font-size: 16px;
        text-align: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-background-size: 30px 30px;
        -moz-background-size: 30px;
        -o-background-size: 30px;
        background-size: 30px;
    }

    #navbar-main .navbar-form .region-header-form .country-select .select-list li span:hover {
        background-color: #e2e0e0;
        color: #FF6700;
        cursor: pointer;
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form .country-select .select-list li span:hover {
            background-color: #fff;
            color: #400098;
            padding: 5px 4px 5px 60px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form .country-select .select-list li span:hover {
            background-color: #fff;
            color: #400098;
        }
    }

    #navbar-main .navbar-form .region-header-form .country-select .select-list .active span {
        background-color: #400098;
        color: #fff;
    }

    #navbar-main .navbar-form .region-header-form .country-select .select-list .mCustomScrollBox.mCSB_inside>.mCSB_container {
        margin-right: 20px;
    }

    div.navbar-form div.region-header-form:empty {
        display: none !important;
    }

    @media (max-width:767px) {
        .contextual-region {
            position: static;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .contextual-region {
            position: static;
        }
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form #mySidenav .region-header-form {
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form #mySidenav .region-header-form {
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        }
    }

    @media (max-width:767px) {
        #navbar-main .navbar-form .region-header-form ul li:nth-child(2) {
            display: block;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #navbar-main .navbar-form .region-header-form ul li:nth-child(2) {
            display: block;
        }
    }

    .language-switcher .dropdown .filter-option .filter-option-inner .filter-option-inner-inner:first-child {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
    }

    .language-switcher .dropdown .filter-option .filter-option-inner .filter-option-inner-inner:nth-last-child(1) {
        font-family: 'Cairo', sans-serif;
    }

    .language-switcher .dropdown .filter-option .filter-option-inner .filter-option-inner-inner:nth-last-child(2) {
        font-family: 'Cairo', sans-serif;
    }

    .language-switcher .dropdown.show .dropdown-menu.show .inner.show ul li:first-child a span {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
    }

    .language-switcher .dropdown.show .dropdown-menu.show .inner.show ul li:last-child a span {
        font-family: 'Cairo', sans-serif !important;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn .filter-option-inner:first-child .filter-option-inner-inner {
        font-family: "Montserrat", sans-serif !important;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn .filter-option-inner:first-child .filter-option-inner-inner {
        font-family: "Montserrat", sans-serif !important;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn .filter-option-inner:last-child .filter-option-inner-inner {
        font-family: 'Cairo', sans-serif !important;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .btn .filter-option-inner:last-child .filter-option-inner-inner {
        font-family: 'Cairo', sans-serif !important;
    }

    .slideIn {
        -webkit-animation: menuSlide .5s 1;
        -moz-animation: menuSlide .5s 1;
        -o-animation: menuSlide .5s 1;
        animation: menuSlide .5s 1;
        -webkit-animation-fill-mode: backwards;
        -moz-animation-fill-mode: backwards;
        -o-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
    }

    .slideOut {
        -webkit-animation: menuSlideOut .5s 1;
        -moz-animation: menuSlideOut .5s 1;
        -o-animation: menuSlideOut .5s 1;
        animation: menuSlideOut .5s 1;
    }

    @-webkit-keyframes menuSlide {
        from {
            width: 0px;
            -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        to {
            width: 100%;
            -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }
    }

    @-moz-keyframes menuSlide {
        from {
            width: 0px;
            -moz-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        to {
            width: 100%;
            -moz-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }
    }

    @-o-keyframes menuSlide {
        from {
            width: 0px;
            -o-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        to {
            width: 100%;
            -o-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }
    }

    @keyframes menuSlide {
        from {
            width: 0px;
            -webkit-transition-timing-function: ease-in;
            -moz-transition-timing-function: ease-in;
            -o-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        to {
            width: 100%;
            -webkit-transition-timing-function: ease-in;
            -moz-transition-timing-function: ease-in;
            -o-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }
    }

    @-webkit-keyframes menuSlideOut {
        from {
            width: 100%;
            -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        to {
            width: 0px;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }

    @-moz-keyframes menuSlideOut {
        from {
            width: 100%;
            -moz-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        to {
            width: 0px;
            -moz-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }

    @-o-keyframes menuSlideOut {
        from {
            width: 100%;
            -o-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        to {
            width: 0px;
            -o-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }

    @keyframes menuSlideOut {
        from {
            width: 100%;
            -webkit-transition-timing-function: ease-in;
            -moz-transition-timing-function: ease-in;
            -o-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        to {
            width: 0px;
            -webkit-transition-timing-function: ease-out;
            -moz-transition-timing-function: ease-out;
            -o-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }

    html[dir="rtl"] .navbar-brand {
        margin-right: 0;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .dropbutton-widget {
            padding-left: 10px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .dropbutton-widget {
            padding-left: 10px !important;
        }
    }

    html[dir="rtl"] .dropbutton-widget ul.dropbutton li.dropbutton-toggle button .dropbutton-arrow::before {
        right: -5px;
        left: auto;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .dropbutton-widget ul.dropbutton li.dropbutton-toggle button .dropbutton-arrow::before {
            right: auto;
            left: -20px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .dropbutton-widget ul.dropbutton li.dropbutton-toggle button .dropbutton-arrow::before {
            right: auto;
            left: -20px;
        }
    }

    html[dir="rtl"] .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action {
        left: 30px;
        right: auto;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action {
            left: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .dropbutton-widget ul.dropbutton li.dropbutton-action.secondary-action {
            left: 0;
        }
    }

    @media (max-width:767px) {
        html[dir="rtl"] #navbar-main .navbar-form {
            float: left;
            width: 60%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #navbar-main .navbar-form {
            float: left;
            width: 60%;
        }
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form {
        font-family: 'Cairo', sans-serif;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form {
            width: auto;
            text-align: left;
            float: left;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form {
            width: auto;
            text-align: left;
            float: left;
        }
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn::after {
        right: auto;
        left: 15px;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn .filter-option {
        text-align: right;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn .filter-option .filter-option-inner-inner {
        padding-right: 0;
        padding-left: 15px;
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown.show .btn .filter-option .filter-option-inner-inner span {
        padding-left: 0;
        padding-right: 40px;
        background-position: 100%;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn::after {
        left: 15px;
        right: auto;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn .filter-option {
        text-align: right;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn .filter-option .filter-option-inner-inner {
        padding-right: 0;
        padding-left: 15px;
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-countryselectblock .dropdown .btn .filter-option .filter-option-inner-inner span {
        padding-left: 0;
        padding-right: 40px;
        background-position: 100%;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu {
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu {
            display: none;
        }
    }

    @media (max-width:767px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu.show {
            display: block;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu.show {
            display: block;
        }
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item {
        margin-right: 25px;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item form button.form-submit {
        left: 0;
        right: auto;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown.show {
        -webkit-transition: all 2s ease;
        -moz-transition: all 2s ease;
        -o-transition: all 2s ease;
        transition: all 2s ease;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown.show .btn .filter-option .filter-option-inner-inner {
        padding-right: 0 !important;
        padding-left: 15px !important;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown.show .btn .filter-option .filter-option-inner-inner span {
        padding-left: 0;
        padding-right: 40px;
        background-position: right center;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown.show .btn::after {
        left: 15px;
        right: auto;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown .btn .filter-option {
        padding-right: 0;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown .btn .filter-option .filter-option-inner-inner {
        padding-right: 0 !important;
        padding-left: 15px !important;
        font-family: 'Cairo', sans-serif;
        text-align: right;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown .btn .filter-option .filter-option-inner-inner span {
        font-family: 'Cairo', sans-serif;
        padding-left: 0;
        padding-right: 40px;
        background-position: right center;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown .btn::after {
        left: 0;
        right: auto;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown .btn::after {
            left: 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown .btn::after {
            left: 15px;
        }
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select {
        margin-right: auto;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button {
        display: none;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link {
        padding: 5px 15px;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link {
            float: left;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link {
            float: left;
        }
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link::before {
        left: 0;
        right: auto;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link::before {
            left: -20px;
            right: auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button a.select-list-link::before {
            left: -20px;
            right: auto;
        }
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button span {
        padding-left: 0;
        padding-right: 40px;
        background-position: top right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button span {
            float: right;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .button span {
            float: right;
        }
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .select-list {
        left: auto;
        right: 0px;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .country-select .select-list li span {
        padding: 5px 60px 5px 15px;
        text-align: right;
        background-position: right 10px center;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form ul {
        padding-right: 25px;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form ul.dropdown-language-item {
        text-align: right;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form ul li a {
        text-align: right;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form ul li a.dropdown-item {
        padding: 0 1.5rem;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form ul li a.dropdown-item span {
        padding-left: 0;
        padding-right: 50px;
        margin-left: 0;
        background-position: right center;
        margin-right: -38px;
        font-family: 'Cairo', sans-serif !important;
        font-weight: inherit;
        font-size: 18px;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form ul li:first-child a.dropdown-item span {
        font-size: 18px;
        font-family: "Montserrat", sans-serif !important;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .dropdown-menu.show .inner.show ul li.active a {
        padding-left: 15px !important;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .dropdown-menu.show .inner.show ul li:hover {
        background-color: #e0e6ed;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown.show .dropdown-menu.show .inner.show ul li:first-child a.dropdown-item span {
        font-size: 18px;
        font-family: "Montserrat", sans-serif !important;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn::after {
        left: 15px;
        right: auto;
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form .language-switcher .dropdown .btn .filter-option .filter-option-inner-inner {
        padding-right: 0;
        padding-left: 10px;
        font-size: 18px;
        font-family: 'Cairo', sans-serif;
    }

    .site-footer__top .region-footer-second {
        float: right;
    }

    @media (max-width:767px) {
        .site-footer__top .region-footer-second {
            float: left;
            padding: 0 15px;
        }
    }

    .region-footer-second #block-mailchimpsubscriptionformsubscribe {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .region-footer-second #block-mailchimpsubscriptionformsubscribe h2 {
        font-weight: 500;
        font-size: 16px !important;
        color: #2A2A2A;
        display: inline-block;
        float: left;
        width: 33%;
        text-transform: capitalize;
    }

    @media (max-width:767px) {
        .region-footer-second #block-mailchimpsubscriptionformsubscribe h2 {
            font-size: 14px !important;
            line-height: 2;
        }
    }

    #mailchimp-signup-subscribe-block-subscribe-form {
        width: 100%;
    }

    #mailchimp-signup-subscribe-block-subscribe-form label {
        display: none;
    }

    #mailchimp-signup-subscribe-block-subscribe-form input#edit-mergevars-email {
        border: 0;
        background: transparent;
        border-bottom: 1px solid #E1E1E1;
        font-size: 16px;
    }

    #mailchimp-signup-subscribe-block-subscribe-form input#edit-mergevars-email:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #mailchimp-signup-subscribe-block-subscribe-form .form-actions {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }

    #mailchimp-signup-subscribe-block-subscribe-form .form-actions .btn-primary {
        color: transparent;
        z-index: 99;
        position: relative;
        right: -15px;
        background: transparent;
        border: transparent;
        font-size: 16px;
    }

    #mailchimp-signup-subscribe-block-subscribe-form .form-actions::before {
        content: '\e90e';
        position: absolute;
        right: 14px;
        top: 0;
        width: 20px;
        height: 20px;
        font-family: "icomoon" !important;
        color: #43009B;
        font-size: 20px;
    }

    .path-frontpage .site-footer {
        display: none;
    }

    footer {
        background: transparent;
        font-family: "Montserrat", sans-serif;
        background-color: #F9F9F9;
        padding: 30px 0 0;
        max-width: 1366px;
        margin: 50px auto 0;
    }

    footer .site-footer__top {
        border-bottom: 1px solid #dee2e6;
        padding-bottom: 2rem;
    }

    footer .site-footer__top #block-mailchimpsubscriptionformsubscribe .content {
        margin-top: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
    }

    footer .site-footer__top .region {
        width: 100%;
    }

    @media (max-width:767px) {
        footer .site-footer__top .region {
            margin: 0;
        }
    }

    footer .site-footer__top .region ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        line-height: normal;
        list-style: none;
        padding: 0 15px;
        margin-bottom: 0;
    }

    footer .site-footer__top .region ul span.footer-heading {
        font-weight: 500;
        font-size: 16px;
        color: #2A2A2A;
    }

    @media (max-width:767px) {
        footer .site-footer__top .region ul span.footer-heading {
            font-size: 14px;
        }
    }

    footer .site-footer__top .region ul li a {
        font-size: 24px;
        margin-right: 10px;
        vertical-align: middle;
        padding: 0 2px 0 20px;
        line-height: normal;
    }

    @media (max-width:767px) {
        footer .site-footer__top .region ul li a {
            margin-right: 5px;
        }
    }

    footer .site-footer__top .region ul li a i {
        color: #5D5D5D;
        -webkit-transition: 0.5s all;
        -o-transition: 0.5s all;
        -moz-transition: 0.5s all;
        transition: 0.5s all;
        vertical-align: middle;
    }

    footer .site-footer__top .region ul li a i:hover {
        -webkit-filter: invert(27%) sepia(51%) saturate(2878%);
        filter: invert(27%) sepia(51%) saturate(2878%);
    }

    footer .copyright {
        background: #FC6719;
        font-weight: 500;
        font-size: 15px;
        padding: 15px 0;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }

    footer .copyright h2 {
        display: none;
    }

    footer .copyright p {
        margin: 0 auto;
    }

    footer .site-footer__bottom {
        margin-top: 0;
    }

    footer .site-footer__bottom .region {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 0;
    }

    footer .site-footer__bottom .navbar-nav {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    footer .site-footer__bottom .navbar-nav li.nav-item {
        margin-right: 0;
    }

    @media (max-width:767px) {
        footer .site-footer__bottom .navbar-nav li.nav-item {
            width: 100%;
        }
    }

    footer .site-footer__bottom .navbar-nav li.nav-item a {
        color: #666;
        font-size: 15px;
        position: relative;
        padding: .5rem 1rem !important;
    }

    @media (max-width:767px) {
        footer .site-footer__bottom .navbar-nav li.nav-item a {
            padding: 5px 30px !important;
        }
    }

    footer .site-footer__bottom .navbar-nav li.nav-item a::after {
        height: 10px;
        content: '';
        width: 1px;
        background: #666;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 0;
    }

    @media (max-width:767px) {
        footer .site-footer__bottom .navbar-nav li.nav-item a::after {
            display: none;
        }
    }

    footer .site-footer__bottom .navbar-nav li.nav-item a:hover {
        color: #FC6719;
    }

    footer .site-footer__bottom .navbar-nav li:last-child.nav-item a::after {
        width: 0;
    }

    .footer-top {
        background-color: #F8F8F8;
        padding: 2rem 0;
    }

    .footer-top__title {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        font-weight: 700;
        color: #08214C;
        text-transform: uppercase;
    }

    .footer-top__logo-box--certified {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }

    @media (min-width:992px) {
        .footer-top__logo-box--certified {
            -webkit-box-pack: end;
            -webkit-justify-content: flex-end;
            -moz-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
        }
    }

    @media (max-width:991px) {
        .footer-top__logo-box--certified {
            margin-top: 1.5rem;
            border-top: 1px solid #4D4D4F;
            padding-top: 1.5rem;
        }
    }

    .footer-top__logo-list {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }

    @media (max-width:767px) {
        .footer-top__logo-list {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
    }

    .footer-top__logo-list>li {
        margin-right: 1rem;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @media (max-width:767px) {
        .footer-top__logo-list>li {
            margin-bottom: 1rem;
        }
    }

    .footer-top__logo-list>li img {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        height: auto;
        width: 70px;
        display: block;
    }

    @media (max-width:1199px) {
        .footer-top__logo-list>li img {
            width: 50px;
        }
    }

    .footer-top__logo-list>li:hover img {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }

    .footer-bottom {
        background-color: #08214C;
    }

    .copyright-text {
        color: #fff;
        padding: 1rem 0;
        text-align: center;
    }

    footer .site-footer__top .col-md-6:last-child {
        padding-right: 0;
    }

    @media (max-width:767px) {
        footer .site-footer__top .col-md-6:last-child {
            padding-right: 15px;
            padding-left: 30px;
        }
    }

    #mailchimp-signup-subscribe-block-subscribe-form .js-form-type-email {
        padding-right: 0;
        margin-bottom: 0;
    }

    @media (max-width:767px) {
        footer .site-footer__bottom .region ul li {
            width: 100%;
        }
    }

    html[dir="rtl"] footer {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] footer .site-footer__top .region ul span.footer-heading {
        margin-right: 0 !important;
    }

    html[dir="rtl"] footer .site-footer__top .region ul li a {
        padding: 0 20px 0 2px;
    }

    html[dir="rtl"] footer .site-footer__top .region-footer-second #block-mailchimpsubscriptionformsubscribe h2 {
        float: right;
    }

    html[dir="rtl"] footer .site-footer__top .region-footer-second #mailchimp-signup-subscribe-block-subscribe-form .form-actions {
        right: auto;
        left: 0;
    }

    @media (max-width:767px) {
        html[dir="rtl"] footer .site-footer__top .region-footer-second #mailchimp-signup-subscribe-block-subscribe-form .form-actions {
            bottom: 0;
            top: auto;
        }
    }

    html[dir="rtl"] footer .site-footer__top .region-footer-second #mailchimp-signup-subscribe-block-subscribe-form .form-actions::before {
        right: auto;
        left: 14px;
    }

    html[dir="rtl"] footer .site-footer__top .region-footer-second #mailchimp-signup-subscribe-block-subscribe-form .form-actions .btn-primary {
        right: auto;
        left: -15px;
    }

    html[dir="rtl"] footer .site-footer__bottom .region ul {
        padding-right: 0;
    }

    html[dir="rtl"] footer .site-footer__bottom .region ul li a {
        color: #666;
        font-size: 15px;
        position: relative;
        padding: .5rem 1rem !important;
    }

    @media (max-width:767px) {
        html[dir="rtl"] footer .site-footer__bottom .navbar-nav {
            padding-right: 0;
        }
    }

    @media (max-width:767px) {
        html[dir="rtl"] footer .site-footer__bottom .navbar-nav li.nav-item {
            width: 100%;
        }
    }

    html[dir="rtl"] footer .site-footer__bottom .navbar-nav li:last-child.nav-item a::after {
        width: 1px;
    }

    html[dir="rtl"] footer .site-footer__bottom .navbar-nav li:first-child.nav-item a::after {
        width: 0;
    }

    @media (max-width:767px) {
        html[dir="rtl"] footer .site-footer__bottom .navbar-nav li.nav-item {
            width: 100%s;
        }
    }

    html[dir="rtl"] #mailchimp-signup-subscribe-block-subscribe-form .js-form-type-email {
        padding-right: 15px;
        padding-left: 0;
    }

    html[dir="rtl"] footer .site-footer__top .col-md-6:last-child {
        padding-right: 15px;
        padding-left: 0;
    }

    html[dir="rtl"] footer .site-footer__bottom .navbar-nav {
        padding-right: 0;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    body {
        font-family: "Montserrat", sans-serif;
    }

    body.toolbar-horizontal nav.fixed-top {
        top: 79px;
    }

    #page-wrapper {
        overflow-x: hidden;
    }

    .container--md {
        max-width: 830px;
    }

    @media (max-width:1199px) {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    @media (max-width:767px) {
        .container {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media (min-width:576px) {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    @media (min-width:768px) {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .main-content {
        min-height: 500px;
    }

    .node__content {
        font-size: 16px;
    }

    .full_width_banner {
        position: relative;
    }

    .custom-banner-block {
        width: 100%;
        position: relative;
        overflow: hidden;
        max-width: 1366px;
        margin: 0 auto;
        width: 100%;
    }

    .custom-banner-block img {
        width: 100%;
        height: 280px;
        margin-bottom: 0;
    }

    .custom-banner-block::before {
        display: none;
        content: "";
        position: absolute;
        width: 100%;
        height: 170px;
        background: #ffffff;
        left: 0;
        bottom: -50px;
        z-index: 9;
        -webkit-transform: rotate(-4deg);
        -moz-transform: rotate(-4deg);
        -ms-transform: rotate(-4deg);
        -o-transform: rotate(-4deg);
        transform: rotate(-4deg);
    }

    @media (max-width:767px) {
        .custom-banner-block::before {
            height: 110px;
            bottom: -44px;
        }
    }

    @media (max-width:575px) {
        .custom-banner-block::before {
            height: 94px;
            bottom: -44px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .custom-banner-block::before {
            height: 116px;
        }
    }

    .custom-banner-block .field--name-body {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        color: #ffffff;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background: -webkit-gradient(linear, left top, right top, from(rgba(67, 0, 155, 0.4)), color-stop(18%, rgba(9, 9, 121, 0.4)), to(rgba(239, 147, 84, 0.4)));
        background: -webkit-linear-gradient(left, rgba(67, 0, 155, 0.4) 0%, rgba(9, 9, 121, 0.4) 18%, rgba(239, 147, 84, 0.4) 100%);
        background: -moz-linear-gradient(left, rgba(67, 0, 155, 0.4) 0%, rgba(9, 9, 121, 0.4) 18%, rgba(239, 147, 84, 0.4) 100%);
        background: -o-linear-gradient(left, rgba(67, 0, 155, 0.4) 0%, rgba(9, 9, 121, 0.4) 18%, rgba(239, 147, 84, 0.4) 100%);
        background: linear-gradient(90deg, rgba(67, 0, 155, 0.4) 0%, rgba(9, 9, 121, 0.4) 18%, rgba(239, 147, 84, 0.4) 100%);
        height: 100%;
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .custom-banner-block .field--name-body {
            top: 50%;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .custom-banner-block .field--name-body {
            top: 50%;
        }
    }

    .custom-banner-block .field--name-body h2 {
        color: #ffffff;
        font-size: 45px;
        font-family: "Montserrat", sans-serif;
        line-height: 1;
        font-weight: bold;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .custom-banner-block .field--name-body h2 {
            padding: 20px;
            line-height: 1.2;
            font-size: 18px !important;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .custom-banner-block .field--name-body h2 {
            padding: 20px;
            line-height: 1.2;
            font-size: 25px !important;
        }
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .custom-banner-block .field--name-body h2 {
            padding: 10px;
            margin-bottom: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .custom-banner-block .field--name-body h2 {
            font-size: 35px !important;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .custom-banner-block .field--name-body h2 {
            font-size: 30px !important;
        }
    }

    .custom-banner-block .field--name-body p {
        width: 90%;
        margin-top: 20px;
        font-family: "Montserrat", sans-serif;
    }

    @media (max-width:767px) {
        .custom-banner-block .field--name-body p {
            width: 85%;
            margin-top: 0;
            font-size: 12px !important;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: 16px;
        }
    }

    @media only screen and (min-device-width:320px) and (max-device-width:568px) and (-webkit-min-device-pixel-ratio:2) {
        .custom-banner-block img {
            height: 26vh;
        }

        #block-faqfilterblock.faq-search-box-show {
            width: 100%;
        }
    }

    .h1,
    .h2,
    h1,
    h2 {
        font-family: "Montserrat", sans-serif;
        color: #43009B;
    }

    @media (max-width:767px) {

        .h1,
        .h2,
        h1,
        h2 {
            font-size: 18px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {

        .h1,
        .h2,
        h1,
        h2 {
            font-size: 18px !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {

        .h1,
        .h2,
        h1,
        h2 {
            font-size: 20px !important;
        }
    }

    .h3,
    .h4,
    .h5,
    .h6,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Montserrat", sans-serif;
        color: #43009B;
    }

    @media (max-width:767px) {

        .h3,
        .h4,
        .h5,
        .h6,
        h3,
        h4,
        h5,
        h6 {
            font-size: 18px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {

        .h3,
        .h4,
        .h5,
        .h6,
        h3,
        h4,
        h5,
        h6 {
            font-size: 18px !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {

        .h3,
        .h4,
        .h5,
        .h6,
        h3,
        h4,
        h5,
        h6 {
            font-size: 20px !important;
        }
    }

    p {
        font-family: "Montserrat", sans-serif;
    }

    @media (max-width:767px) {
        p {
            font-size: 14px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        p {
            font-size: 14px !important;
        }
    }

    #main h1.title {
        display: none;
    }

    @media (max-width:767px) {
        .custom-all-btn {
            padding: 8px 30px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .custom-all-btn {
            padding: 10px 40px !important;
        }
    }

    html[dir="rtl"] body {
        font-family: 'Cairo', sans-serif !important;
    }

    html[dir="rtl"] p {
        font-family: 'Cairo', sans-serif !important;
    }

    html[dir="rtl"] .h1,
    html[dir="rtl"] .h2,
    html[dir="rtl"] h1,
    html[dir="rtl"] h2 {
        font-family: 'Cairo', sans-serif !important;
    }

    html[dir="rtl"] .h3,
    html[dir="rtl"] .h4,
    html[dir="rtl"] .h5,
    html[dir="rtl"] .h6,
    html[dir="rtl"] h3,
    html[dir="rtl"] h4,
    html[dir="rtl"] h5,
    html[dir="rtl"] h6 {
        font-family: 'Cairo', sans-serif !important;
    }

    html[dir="rtl"] a {
        font-family: 'Cairo', sans-serif !important;
    }

    body {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        color: #4D4D4F;
        font-size: 18px;
    }

    body.path-frontpage {
        padding-top: 0;
    }

    #main-wrapper {
        margin-bottom: 20px;
    }

    #page-wrapper {
        position: relative;
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 {
        max-width: 1366px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
    }

    #page-wrapper #block-views-block-welcome-slider-block-1::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 220px;
        background: #ffffff;
        left: 0;
        bottom: -60px;
        z-index: 9;
        -webkit-transform: rotate(-4deg);
        -moz-transform: rotate(-4deg);
        -ms-transform: rotate(-4deg);
        -o-transform: rotate(-4deg);
        transform: rotate(-4deg);
        display: none;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1::before {
            height: 130px;
            bottom: -44px;
        }
    }

    @media (max-width:575px) {
        #page-wrapper #block-views-block-welcome-slider-block-1::before {
            height: 94px;
            bottom: -44px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-welcome-slider-block-1::before {
            bottom: -130px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #page-wrapper #block-views-block-welcome-slider-block-1::before {
            bottom: -125px;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-banner-image .field-content img {
        -o-object-fit: cover;
        object-fit: cover;
        height: 580px;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-banner-image .field-content img {
            height: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-banner-image .field-content img {
            height: 100%;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: -webkit-gradient(linear, left top, right top, from(rgba(67, 0, 155, 0.8)), color-stop(18%, rgba(9, 9, 121, 0.8)), to(rgba(239, 147, 84, 0.6)));
        background: -webkit-linear-gradient(left, rgba(67, 0, 155, 0.8) 0%, rgba(9, 9, 121, 0.8) 18%, rgba(239, 147, 84, 0.6) 100%);
        background: -moz-linear-gradient(left, rgba(67, 0, 155, 0.8) 0%, rgba(9, 9, 121, 0.8) 18%, rgba(239, 147, 84, 0.6) 100%);
        background: -o-linear-gradient(left, rgba(67, 0, 155, 0.8) 0%, rgba(9, 9, 121, 0.8) 18%, rgba(239, 147, 84, 0.6) 100%);
        background: linear-gradient(90deg, rgba(67, 0, 155, 0.8) 0%, rgba(9, 9, 121, 0.8) 18%, rgba(239, 147, 84, 0.6) 100%);
        background: none;
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content {
        margin-top: -150px;
    }

    @media (max-width:575px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content {
            padding: 6px;
        }
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content {
            margin-top: 0;
        }
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content {
            margin-top: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content {
            margin-top: -50px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content {
            margin-top: -18%;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content {
            margin-top: -15%;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content img {
        max-width: 100%;
        width: auto;
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 {
        color: #ffffff;
        font-size: 45px;
        font-family: "Montserrat", sans-serif;
        line-height: 1;
        font-weight: bold;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 {
            font-size: 20px !important;
            margin-top: -50px;
            padding: 0 20px;
            float: none;
            display: inline-block;
            line-height: 30px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 {
            font-size: 30px !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 {
            font-size: 40px !important;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span {
        color: #ffffff;
        font-size: 45px;
    }

    @media (max-width:575px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span {
            font-size: 22px !important;
        }
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span {
            font-size: 22px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span {
            font-size: 30px !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span {
            font-size: 40px !important;
            line-height: 1.8;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span.banner-special-wrd {
        letter-spacing: .9px;
        padding: 0 10px;
        color: #555;
        background-color: #ffffff;
        z-index: 1;
        mix-blend-mode: screen;
        font-weight: bold;
        font-size: 45px;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span.banner-special-wrd {
            font-size: 18px !important;
            line-height: 24px;
            display: inline-block;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span.banner-special-wrd {
            font-size: 30px !important;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span::after {
        content: '';
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/Arrow.svg) no-repeat center;
        width: 466px;
        height: 113px;
        position: absolute;
        bottom: 125px;
        left: 115px;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span::after {
            top: -40%;
            left: 0;
            display: none;
        }
    }

    @media (max-width:575px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span::after {
            top: -36%;
            left: 0;
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content h1 span::after {
            bottom: 100px;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content span {
        color: #ffffff;
        font-size: 20px;
        font-family: "Montserrat", sans-serif;
        line-height: 2;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content span {
            font-size: 16px;
            display: block;
        }
    }

    @media (max-width:575px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .views-field-body .banner-content span {
            font-size: 12px;
            line-height: 18px;
            padding: 0 10px;
            display: block;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls {
        position: relative;
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls .owl-pagination {
        position: absolute;
        top: -320px;
        left: 0;
        right: 0;
        background: rgba(64, 0, 153, 0.38);
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        padding-top: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls .owl-pagination {
            top: -130px;
        }
    }

    @media (max-width:575px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls .owl-pagination {
            top: -80px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls .owl-pagination {
            top: -160px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls .owl-pagination {
            top: -220px;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls .owl-pagination .owl-page span {
        background: transparent;
        border: 1px solid #ffffff;
    }

    @media (max-width:575px) {
        #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls .owl-pagination .owl-page span {
            width: 8px;
            height: 8px;
            margin: 5px 3px;
        }
    }

    #page-wrapper #block-views-block-welcome-slider-block-1 .owl-theme .owl-controls .owl-pagination .owl-page.active span {
        background-color: #ffffff;
    }

    #page-wrapper #block-views-block-case-studies-block-1 {
        padding: 50px 0;
    }

    @media (max-width:991px) {
        #page-wrapper #block-views-block-case-studies-block-1 {
            padding: 0;
        }
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-case-studies-block-1 {
            padding: 0;
        }
    }

    #page-wrapper #block-views-block-case-studies-block-1 h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
    }

    #page-wrapper #block-views-block-case-studies-block-1 h3 {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        text-align: center;
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section {
        padding: 50px 0;
        margin-right: 10%;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-case-studies-block-1 .case-study-section {
            margin: 0;
            padding: 30px 0;
        }
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section:hover .case-box .case-img {
        background: transparent;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        width: 100%;
        -webkit-box-shadow: 1px 1px 7px 5px rgba(67, 0, 154, 0.1);
        -moz-box-shadow: 1px 1px 7px 5px rgba(67, 0, 154, 0.1);
        box-shadow: 1px 1px 7px 5px rgba(67, 0, 154, 0.1);
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section:hover .case-box .case-img img {
        -webkit-transform: scale(0.95);
        -moz-transform: scale(0.95);
        -ms-transform: scale(0.95);
        -o-transform: scale(0.95);
        transform: scale(0.95);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        opacity: 1;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-case-studies-block-1 .case-study-section:hover .case-box .case-img img {
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-case-studies-block-1 .case-study-section:hover .case-box .case-img img {
            width: 100%;
        }
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section:hover .case-box .case-content {
        bottom: 50%;
        -webkit-transform: translateY(50%);
        -moz-transform: translateY(50%);
        -ms-transform: translateY(50%);
        -o-transform: translateY(50%);
        transform: translateY(50%);
        background-color: white;
        color: #fff;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        border: 1px solid transparent;
        -webkit-box-shadow: 1px 1px 13px 4px rgba(67, 0, 154, 0.3);
        -moz-box-shadow: 1px 1px 13px 4px rgba(67, 0, 154, 0.3);
        box-shadow: 1px 1px 13px 4px rgba(67, 0, 154, 0.3);
        -webkit-transition-duration: 0.5s !important;
        -moz-transition-duration: 0.5s !important;
        -o-transition-duration: 0.5s !important;
        transition-duration: 0.5s !important;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-case-studies-block-1 .case-study-section:hover .case-box .case-content {
            bottom: 0%;
            -webkit-transform: translateY(5%);
            -moz-transform: translateY(5%);
            -ms-transform: translateY(5%);
            -o-transform: translateY(5%);
            transform: translateY(5%);
        }
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box {
        position: relative;
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-img {
        padding: 5px 0;
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-img img {
        max-width: 100%;
        max-height: 275px;
        height: auto;
        overflow: hidden;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-img img {
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-img img {
            width: 100%;
        }
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content {
        position: absolute;
        background-color: #ffffff;
        bottom: -9%;
        right: -8%;
        width: 330px;
        padding: 15px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid #ffffff;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-bottomright: 5px;
        border-bottom-right-radius: 5px;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
    }

    @media (max-width:767px) {
        #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content {
            position: static;
            right: 0;
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content {
            width: 350px;
        }
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content h4 {
        color: #43009B;
        font-size: 18px;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content p {
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666;
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content a {
        color: #FF6700;
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        position: relative;
        font-weight: 500;
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content a:hover:before {
        width: 100%;
    }

    #page-wrapper #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content a:before {
        position: absolute;
        content: "";
        bottom: -3px;
        left: 0;
        width: 0;
        height: 1px;
        background: #FF6700;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    #block-servicescarouselblock {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-servicescarouselblock {
            padding: 50px 0;
        }
    }

    @media (max-width:767px) {
        #block-servicescarouselblock {
            padding: 10px 0;
            margin: 0 auto;
        }
    }

    #block-servicescarouselblock h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: center;
        margin-bottom: 30px;
    }

    #block-servicescarouselblock .carousel {
        display: -ms-flexbox !important;
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: flex !important;
        -ms-flex-direction: column-reverse !important;
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: reverse !important;
        flex-direction: column-reverse !important;
        width: 95%;
    }

    @media screen and (min-width:1200px) and (max-width:1495px) {
        #block-servicescarouselblock .carousel {
            width: 90%;
        }
    }

    #block-servicescarouselblock .carousel .carousel-indicators {
        right: -6%;
        bottom: 15%;
        left: auto;
        background-color: #ffffff;
        width: 350px;
        height: 100%;
        max-height: 450px;
        margin: 0;
        display: block;
        top: 22%;
        -webkit-transform: translateX(22%);
        -moz-transform: translateX(22%);
        -ms-transform: translateX(22%);
        -o-transform: translateX(22%);
        transform: translateX(22%);
        padding: 30px;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
    }

    @media (max-width:767px) {
        #block-servicescarouselblock .carousel .carousel-indicators {
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
            position: static;
            left: 0;
            right: 0;
            width: 90%;
            margin: -25px auto 0 auto;
            padding: 25px 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-servicescarouselblock .carousel .carousel-indicators {
            bottom: 0;
            top: 7%;
            right: 2%;
            max-height: 350px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-servicescarouselblock .carousel .carousel-indicators {
            top: 16%;
            right: 4%;
        }
    }

    @media screen and (min-width:1200px) and (max-width:1495px) {
        #block-servicescarouselblock .carousel .carousel-indicators {
            right: -4%;
            top: 15%;
            bottom: 0;
        }
    }

    #block-servicescarouselblock .carousel .carousel-indicators li {
        width: 100%;
        height: auto;
        text-indent: 0;
        list-style: none;
        border-bottom: 1px solid #DFDFDF;
    }

    #block-servicescarouselblock .carousel .carousel-indicators li::before {
        top: 0;
        height: 0;
    }

    #block-servicescarouselblock .carousel .carousel-indicators li::after {
        bottom: 0;
        height: 0;
    }

    #block-servicescarouselblock .carousel .carousel-indicators li a {
        color: #4D4D4F;
        display: inline-block;
        width: 100%;
        font-family: "Montserrat", sans-serif;
        padding: 20px 20px 20px 30px;
        position: relative;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        -webkit-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        transition: all ease 0.5s;
        font-weight: 500;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), color-stop(50%, #FF6700));
        background: -webkit-linear-gradient(right, #fff 50%, #FF6700 50%);
        background: -moz-linear-gradient(right, #fff 50%, #FF6700 50%);
        background: -o-linear-gradient(right, #fff 50%, #FF6700 50%);
        background: linear-gradient(to left, #fff 50%, #FF6700 50%);
        -webkit-background-size: 201% 100%;
        -moz-background-size: 201% 100%;
        -o-background-size: 201% 100%;
        background-size: 201% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-servicescarouselblock .carousel .carousel-indicators li a {
            font-size: 14px;
            border: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-servicescarouselblock .carousel .carousel-indicators li a {
            font-size: 14px;
        }
    }

    #block-servicescarouselblock .carousel .carousel-indicators li a::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 15px;
        height: 2px;
        background-color: #FF6700;
        z-index: 9;
    }

    #block-servicescarouselblock .carousel .carousel-indicators li a::after {
        content: "\f105";
        color: #FF6700;
        position: absolute;
        left: 10px;
        right: 0;
        top: 20px;
        font-family: "fontAwesome" !important;
        font-size: 20px;
        z-index: 9;
    }

    #block-servicescarouselblock .carousel .carousel-indicators li a:hover {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        border-color: transparent;
        padding: 20px 20px 20px 30px;
        border-bottom: 0;
        position: relative;
        width: 120%;
        background-position: left bottom;
        margin-left: -60px;
        margin-bottom: 0;
        border-color: #FF6700;
    }

    #block-servicescarouselblock .carousel .carousel-indicators li a:hover::before {
        display: none;
    }

    #block-servicescarouselblock .carousel .carousel-indicators li:last-child {
        border-bottom: 0;
    }

    #block-servicescarouselblock .carousel .carousel-indicators li.active a {
        color: #fff;
        -webkit-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        transition: all ease 0.5s;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        padding-left: 30px;
        font-size: 20px;
        position: relative;
        background-position: left bottom;
        left: -20%;
        width: 120%;
        border-bottom: 0;
    }

    @media (max-width:767px) {
        #block-servicescarouselblock .carousel .carousel-indicators li.active a {
            font-size: 14px;
            border: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-servicescarouselblock .carousel .carousel-indicators li.active a {
            font-size: 14px;
        }
    }

    #block-servicescarouselblock .carousel .carousel-indicators li.active a::before {
        display: none;
        top: 50%;
        left: -50px;
        width: 15px;
        height: 2px;
        background-color: #fff;
    }

    @media (max-width:767px) {
        #block-servicescarouselblock .carousel .carousel-indicators li.active a::before {
            top: 48%;
        }
    }

    #block-servicescarouselblock .carousel .carousel-control-prev {
        top: auto;
        bottom: -3%;
        width: 93%;
        color: #ffffff;
        opacity: 1;
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 78%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 85%;
            bottom: -4%;
        }
    }

    #block-servicescarouselblock .carousel .carousel-control-prev .carousel-control-prev-icon {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-servicescarouselblock .carousel .carousel-control-prev .carousel-control-prev-icon::before {
        content: "\e912";
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
        color: #ffffff;
    }

    #block-servicescarouselblock .carousel .carousel-control-prev .carousel-control-prev-icon:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        width: 70px;
    }

    #block-servicescarouselblock .carousel .carousel-control-prev .carousel-control-prev-icon:hover::before {
        content: "\e912";
        position: absolute;
        left: -11px;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-servicescarouselblock .carousel .carousel-control-prev .carousel-control-prev-icon:hover::after {
        content: "\e900";
        position: absolute;
        left: 11px;
        right: -7px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-servicescarouselblock .carousel .carousel-control-prev .sr-only {
        width: 30px;
        height: 30px;
    }

    #block-servicescarouselblock .carousel .carousel-control-next {
        top: auto;
        bottom: -3%;
        width: 93%;
        color: #ffffff;
        opacity: 1;
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 78%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 85%;
            bottom: -4%;
        }
    }

    #block-servicescarouselblock .carousel .carousel-control-next .carousel-control-next-icon {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-servicescarouselblock .carousel .carousel-control-next .carousel-control-next-icon::before {
        content: "\e927";
        color: #ffffff;
        position: absolute;
        left: 3px;
        right: 0;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
    }

    #block-servicescarouselblock .carousel .carousel-control-next .carousel-control-next-icon:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 70px;
    }

    #block-servicescarouselblock .carousel .carousel-control-next .carousel-control-next-icon:hover::before {
        content: "\e927";
        position: absolute;
        left: 3px;
        right: -15px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-servicescarouselblock .carousel .carousel-control-next .carousel-control-next-icon:hover::after {
        content: "\e910";
        position: absolute;
        left: -7px;
        right: 5px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-servicescarouselblock .carousel .carousel-control-next .sr-only {
        width: 30px;
        height: 30px;
    }

    #block-views-block-industries-block-1 {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-views-block-industries-block-1 {
            padding: 50px 0;
        }
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-1 {
            padding: 40px 0;
        }
    }

    #block-views-block-industries-block-1 .industries {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 -15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-1 .industries {
            display: block;
        }
    }

    #block-views-block-industries-block-1 .industries .view-header {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 45%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
        background-color: #ffffff;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        padding: 40px;
        text-align: center;
        margin-right: 15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-1 .industries .view-header {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-1 .industries .view-header {
            padding: 22px 15px;
            margin-right: 15px;
        }
    }

    #block-views-block-industries-block-1 .industries .view-header h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-1 .industries .view-header p {
        color: #666666;
        line-height: 24px;
        font-size: 17px;
        margin: 15px 0 28px;
        font-family: "Montserrat", sans-serif;
    }

    #block-views-block-industries-block-1 .industries .view-header a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-1 .industries .view-header a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-1 .industries .view-header a.custom-all-btn {
            font-size: 15px;
        }
    }

    #block-views-block-industries-block-1 .industries .view-header a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-1 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-1 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    #block-views-block-industries-block-1 .industries .view-content.row {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 55%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
        padding: 20px 0;
        text-align: center;
        margin: 0;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-1 .industries .view-content.row {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 50px 0 20px;
        }
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item {
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        padding: 10px 0;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item:hover {
        padding: 0px 0 20px;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 150px;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a {
        text-align: center;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a img {
        max-width: 100%;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content {
        text-align: center;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        text-align: center;
        color: #666666;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a:hover {
        color: #ff6700;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
            font-size: 14px;
        }
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls {
        position: relative;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons {
        position: absolute;
        left: 0;
        right: 0;
        top: 25px;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div.owl-prev::before {
        content: "\e912";
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
        color: #ffffff;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        width: 70px;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::before {
        content: "\e912";
        position: absolute;
        left: -11px;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::after {
        content: "\e900";
        position: absolute;
        left: 11px;
        right: -7px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div.owl-next::before {
        content: "\e927";
        color: #ffffff;
        position: absolute;
        left: 3px;
        right: 0;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 60px;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::before {
        content: "\e927";
        position: absolute;
        left: 3px;
        right: -15px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-1 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::after {
        content: "\e910";
        position: absolute;
        left: -7px;
        right: 5px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-2 {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-views-block-industries-block-2 {
            padding: 50px 0;
        }
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-2 {
            padding: 40px 0;
        }
    }

    #block-views-block-industries-block-2 .industries {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 -15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-2 .industries {
            display: block;
        }
    }

    #block-views-block-industries-block-2 .industries .view-header {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 45%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
        background-color: #ffffff;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        padding: 40px;
        text-align: center;
        margin-right: 15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-2 .industries .view-header {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-2 .industries .view-header {
            padding: 22px 15px;
            margin-right: 15px;
        }
    }

    #block-views-block-industries-block-2 .industries .view-header h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-2 .industries .view-header p {
        color: #666666;
        line-height: 24px;
        font-size: 17px;
        margin: 15px 0 28px;
        font-family: "Montserrat", sans-serif;
    }

    #block-views-block-industries-block-2 .industries .view-header a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-2 .industries .view-header a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-2 .industries .view-header a.custom-all-btn {
            font-size: 15px;
        }
    }

    #block-views-block-industries-block-2 .industries .view-header a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-2 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-2 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    #block-views-block-industries-block-2 .industries .view-content.row {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 55%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
        padding: 20px 0;
        text-align: center;
        margin: 0;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-2 .industries .view-content.row {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 50px 0 20px;
        }
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item {
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        padding: 10px 0;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item:hover {
        padding: 0px 0 20px;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 150px;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a {
        text-align: center;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a img {
        max-width: 100%;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content {
        text-align: center;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        text-align: center;
        color: #666666;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a:hover {
        color: #ff6700;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
            font-size: 14px;
        }
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls {
        position: relative;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons {
        position: absolute;
        left: 0;
        right: 0;
        top: 25px;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div.owl-prev::before {
        content: "\e912";
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
        color: #ffffff;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        width: 70px;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::before {
        content: "\e912";
        position: absolute;
        left: -11px;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::after {
        content: "\e900";
        position: absolute;
        left: 11px;
        right: -7px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div.owl-next::before {
        content: "\e927";
        color: #ffffff;
        position: absolute;
        left: 3px;
        right: 0;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 60px;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::before {
        content: "\e927";
        position: absolute;
        left: 3px;
        right: -15px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-2 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::after {
        content: "\e910";
        position: absolute;
        left: -7px;
        right: 5px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-3 {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-views-block-industries-block-3 {
            padding: 50px 0;
        }
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-3 {
            padding: 40px 0;
        }
    }

    #block-views-block-industries-block-3 .industries {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 -15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-3 .industries {
            display: block;
        }
    }

    #block-views-block-industries-block-3 .industries .view-header {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 45%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
        background-color: #ffffff;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        padding: 40px;
        text-align: center;
        margin-right: 15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-3 .industries .view-header {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-3 .industries .view-header {
            padding: 22px 15px;
            margin-right: 15px;
        }
    }

    #block-views-block-industries-block-3 .industries .view-header h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-3 .industries .view-header p {
        color: #666666;
        line-height: 24px;
        font-size: 17px;
        margin: 15px 0 28px;
        font-family: "Montserrat", sans-serif;
    }

    #block-views-block-industries-block-3 .industries .view-header a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-3 .industries .view-header a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-3 .industries .view-header a.custom-all-btn {
            font-size: 15px;
        }
    }

    #block-views-block-industries-block-3 .industries .view-header a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-3 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-3 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    #block-views-block-industries-block-3 .industries .view-content.row {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 55%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
        padding: 20px 0;
        text-align: center;
        margin: 0;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-3 .industries .view-content.row {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 50px 0 20px;
        }
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item {
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        padding: 10px 0;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item:hover {
        padding: 0px 0 20px;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 150px;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a {
        text-align: center;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a img {
        max-width: 100%;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content {
        text-align: center;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        text-align: center;
        color: #666666;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a:hover {
        color: #ff6700;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
            font-size: 14px;
        }
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls {
        position: relative;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons {
        position: absolute;
        left: 0;
        right: 0;
        top: 25px;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div.owl-prev::before {
        content: "\e912";
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
        color: #ffffff;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        width: 70px;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::before {
        content: "\e912";
        position: absolute;
        left: -11px;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::after {
        content: "\e900";
        position: absolute;
        left: 11px;
        right: -7px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div.owl-next::before {
        content: "\e927";
        color: #ffffff;
        position: absolute;
        left: 3px;
        right: 0;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 60px;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::before {
        content: "\e927";
        position: absolute;
        left: 3px;
        right: -15px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-3 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::after {
        content: "\e910";
        position: absolute;
        left: -7px;
        right: 5px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-4 {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-views-block-industries-block-4 {
            padding: 50px 0;
        }
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-4 {
            padding: 40px 0;
        }
    }

    #block-views-block-industries-block-4 .industries {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 -15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-4 .industries {
            display: block;
        }
    }

    #block-views-block-industries-block-4 .industries .view-header {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 45%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
        background-color: #ffffff;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        padding: 40px;
        text-align: center;
        margin-right: 15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-4 .industries .view-header {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-4 .industries .view-header {
            padding: 22px 15px;
            margin-right: 15px;
        }
    }

    #block-views-block-industries-block-4 .industries .view-header h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-4 .industries .view-header p {
        color: #666666;
        line-height: 24px;
        font-size: 17px;
        margin: 15px 0 28px;
        font-family: "Montserrat", sans-serif;
    }

    #block-views-block-industries-block-4 .industries .view-header a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-4 .industries .view-header a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-4 .industries .view-header a.custom-all-btn {
            font-size: 15px;
        }
    }

    #block-views-block-industries-block-4 .industries .view-header a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-4 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-4 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    #block-views-block-industries-block-4 .industries .view-content.row {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 55%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
        padding: 20px 0;
        text-align: center;
        margin: 0;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-4 .industries .view-content.row {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 50px 0 20px;
        }
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item {
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        padding: 10px 0;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item:hover {
        padding: 0px 0 20px;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 150px;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a {
        text-align: center;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a img {
        max-width: 100%;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content {
        text-align: center;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        text-align: center;
        color: #666666;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a:hover {
        color: #ff6700;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
            font-size: 14px;
        }
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls {
        position: relative;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons {
        position: absolute;
        left: 0;
        right: 0;
        top: 25px;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div.owl-prev::before {
        content: "\e912";
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
        color: #ffffff;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        width: 70px;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::before {
        content: "\e912";
        position: absolute;
        left: -11px;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::after {
        content: "\e900";
        position: absolute;
        left: 11px;
        right: -7px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div.owl-next::before {
        content: "\e927";
        color: #ffffff;
        position: absolute;
        left: 3px;
        right: 0;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 60px;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::before {
        content: "\e927";
        position: absolute;
        left: 3px;
        right: -15px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-4 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::after {
        content: "\e910";
        position: absolute;
        left: -7px;
        right: 5px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-5 {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-views-block-industries-block-5 {
            padding: 50px 0;
        }
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-5 {
            padding: 40px 0;
        }
    }

    #block-views-block-industries-block-5 .industries {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 -15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-5 .industries {
            display: block;
        }
    }

    #block-views-block-industries-block-5 .industries .view-header {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 45%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
        background-color: #ffffff;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        padding: 40px;
        text-align: center;
        margin-right: 15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-5 .industries .view-header {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-5 .industries .view-header {
            padding: 22px 15px;
            margin-right: 15px;
        }
    }

    #block-views-block-industries-block-5 .industries .view-header h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-5 .industries .view-header p {
        color: #666666;
        line-height: 24px;
        font-size: 17px;
        margin: 15px 0 28px;
        font-family: "Montserrat", sans-serif;
    }

    #block-views-block-industries-block-5 .industries .view-header a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-5 .industries .view-header a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-5 .industries .view-header a.custom-all-btn {
            font-size: 15px;
        }
    }

    #block-views-block-industries-block-5 .industries .view-header a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-5 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-5 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    #block-views-block-industries-block-5 .industries .view-content.row {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 55%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
        padding: 20px 0;
        text-align: center;
        margin: 0;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-5 .industries .view-content.row {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 50px 0 20px;
        }
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item {
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        padding: 10px 0;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item:hover {
        padding: 0px 0 20px;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 150px;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a {
        text-align: center;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a img {
        max-width: 100%;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content {
        text-align: center;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        text-align: center;
        color: #666666;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a:hover {
        color: #ff6700;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
            font-size: 14px;
        }
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls {
        position: relative;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons {
        position: absolute;
        left: 0;
        right: 0;
        top: 25px;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div.owl-prev::before {
        content: "\e912";
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
        color: #ffffff;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        width: 70px;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::before {
        content: "\e912";
        position: absolute;
        left: -11px;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::after {
        content: "\e900";
        position: absolute;
        left: 11px;
        right: -7px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div.owl-next::before {
        content: "\e927";
        color: #ffffff;
        position: absolute;
        left: 3px;
        right: 0;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 60px;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::before {
        content: "\e927";
        position: absolute;
        left: 3px;
        right: -15px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-5 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::after {
        content: "\e910";
        position: absolute;
        left: -7px;
        right: 5px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-6 {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-views-block-industries-block-6 {
            padding: 50px 0;
        }
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-6 {
            padding: 40px 0;
        }
    }

    #block-views-block-industries-block-6 .industries {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 -15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-6 .industries {
            display: block;
        }
    }

    #block-views-block-industries-block-6 .industries .view-header {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 45%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
        background-color: #ffffff;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        padding: 40px;
        text-align: center;
        margin-right: 15px;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-6 .industries .view-header {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-6 .industries .view-header {
            padding: 22px 15px;
            margin-right: 15px;
        }
    }

    #block-views-block-industries-block-6 .industries .view-header h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-6 .industries .view-header p {
        color: #666666;
        line-height: 24px;
        font-size: 17px;
        margin: 15px 0 28px;
        font-family: "Montserrat", sans-serif;
    }

    #block-views-block-industries-block-6 .industries .view-header a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-6 .industries .view-header a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-6 .industries .view-header a.custom-all-btn {
            font-size: 15px;
        }
    }

    #block-views-block-industries-block-6 .industries .view-header a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-industries-block-6 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-industries-block-6 .industries .view-header a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    #block-views-block-industries-block-6 .industries .view-content.row {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 55%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
        padding: 20px 0;
        text-align: center;
        margin: 0;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-6 .industries .view-content.row {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 50px 0 20px;
        }
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item {
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        padding: 10px 0;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item:hover {
        padding: 0px 0 20px;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 150px;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a {
        text-align: center;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-field-image .field-content a img {
        max-width: 100%;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content {
        text-align: center;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        text-align: center;
        color: #666666;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a:hover {
        color: #ff6700;
    }

    @media (max-width:767px) {
        #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
            font-size: 14px;
        }
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls {
        position: relative;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons {
        position: absolute;
        left: 0;
        right: 0;
        top: 25px;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div.owl-prev::before {
        content: "\e912";
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
        color: #ffffff;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        width: 70px;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::before {
        content: "\e912";
        position: absolute;
        left: -11px;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div.owl-prev:hover::after {
        content: "\e900";
        position: absolute;
        left: 11px;
        right: -7px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div.owl-next::before {
        content: "\e927";
        color: #ffffff;
        position: absolute;
        left: 3px;
        right: 0;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 60px;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::before {
        content: "\e927";
        position: absolute;
        left: 3px;
        right: -15px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-industries-block-6 .industries .view-content .owl-controls .owl-buttons div.owl-next:hover::after {
        content: "\e910";
        position: absolute;
        left: -7px;
        right: 5px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-smsa-express-news-block-1 {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-views-block-smsa-express-news-block-1 {
            padding: 50px 0;
        }
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 {
            padding: 40px 0;
        }
    }

    #block-views-block-smsa-express-news-block-1 h3 {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        text-align: center;
    }

    #block-views-block-smsa-express-news-block-1 h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 h2 {
            font-size: 18px !important;
        }
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news {
        display: -ms-flexbox !important;
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: flex !important;
        -ms-flex-direction: column-reverse !important;
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: reverse !important;
        flex-direction: column-reverse !important;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header {
        position: absolute;
        top: 20%;
        left: 0;
        right: 0;
        background-color: #ffffff;
        z-index: 99;
        width: 540px;
        min-height: 350px;
        height: auto;
        padding: 40px;
        text-align: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header {
            position: static;
            left: 0;
            right: 0;
            width: 100%;
            height: auto;
            padding: 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header {
            top: 9%;
            width: 300px;
            height: 300px;
            padding: 15px;
            min-height: 300px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header {
            top: 14%;
        }
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: center;
        margin-bottom: 15px;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header p {
        color: #666666;
        line-height: 24px;
        font-size: 17px;
        margin: 15px 0 28px;
        font-family: "Montserrat", sans-serif;
    }

    @media (max-width:991px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header p {
            margin: 0px;
        }
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header p {
            margin: 15px 0 0;
        }
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header a {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header a {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header a {
            font-size: 15px;
        }
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header a:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header a {
            padding: 6px 30px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header a {
            padding: 8px !important;
            width: 82%;
            margin-top: 10px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header a {
            padding: 8px !important;
            width: 82%;
        }
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content {
        margin: 0;
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content {
            display: block;
        }
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content .owl-wrapper-outer {
        width: 80%;
        display: inline-block;
        float: right;
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content .owl-wrapper-outer {
            width: 100%;
            float: none;
        }
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content .owl-wrapper-outer .views-field-field-image .field-content img {
        max-width: 100%;
        height: 50%;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls {
        position: relative;
        height: 100%;
        width: 100%;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons {
        position: absolute;
        left: 20%;
        right: 0;
        bottom: -1%;
    }

    @media (max-width:767px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons {
            left: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons {
            bottom: -3%;
        }
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div {
        width: 60px;
        height: 40px;
        background-color: #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-image: none !important;
        -webkit-box-shadow: inset 0 0 0 0 transparent;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        position: relative;
        z-index: 99;
        opacity: 1;
        color: transparent;
        margin: 0 7px;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div.owl-prev::before {
        content: "\e912";
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
        color: #ffffff;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div.owl-prev:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-right: 20px;
        width: 70px;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div.owl-prev:hover::before {
        content: "\e912";
        position: absolute;
        left: -11px;
        right: 3px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div.owl-prev:hover::after {
        content: "\e900";
        position: absolute;
        left: 11px;
        right: -7px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div.owl-next::before {
        content: "\e927";
        color: #ffffff;
        position: absolute;
        left: 3px;
        right: 0;
        top: 7px;
        font-family: "icomoon" !important;
        font-size: 20px;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div.owl-next:hover {
        background-color: #ffffff;
        border: 1px solid #c2c5c6;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        -moz-box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        box-shadow: 0px 4px 4px 0px rgba(64, 0, 153, 0.28), inset 100px 0 0 0 #400099;
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 60px;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div.owl-next:hover::before {
        content: "\e927";
        position: absolute;
        left: 3px;
        right: -15px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #fff;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .owl-controls .owl-buttons div.owl-next:hover::after {
        content: "\e910";
        position: absolute;
        left: -7px;
        right: 5px;
        top: 7px;
        font-family: "icomoon" !important;
        color: #ff6700;
        font-size: 20px;
        -webkit-transition: all ease 0.8s;
        -o-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }

    .icon-login {
        position: relative;
        width: 20px;
        height: 20px;
        display: inline-block;
    }

    .icon-login:before {
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/customer_white.svg);
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        padding: 10px;
        top: 1px;
    }

    #block-businessmetricsblock {
        padding: 60px 0 100px;
    }

    @media (max-width:767px) {
        #block-businessmetricsblock {
            padding: 10px 0 10px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-businessmetricsblock {
            padding: 50px 0 50px;
        }
    }

    #block-businessmetricsblock h3 {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        text-align: center;
    }

    #block-businessmetricsblock h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: center;
    }

    #block-businessmetricsblock .business-metrix {
        margin: 50px 0;
        width: 100%;
    }

    @media (max-width:767px) {
        #block-businessmetricsblock .business-metrix {
            display: block;
        }
    }

    #block-businessmetricsblock .business-metrix .metrix-box {
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        padding: 20px;
        position: relative;
    }

    @media (max-width:767px) {
        #block-businessmetricsblock .business-metrix .metrix-box {
            margin-bottom: 15px;
        }
    }

    #block-businessmetricsblock .business-metrix .metrix-box .text-right {
        position: absolute;
        right: 10px;
        top: 0;
    }

    #block-businessmetricsblock .business-metrix .metrix-box i {
        font-size: 48px;
        color: #5D5D5D;
        opacity: 0.2;
    }

    #block-businessmetricsblock .business-metrix .metrix-box h3 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: left;
        font-size: 45px !important;
    }

    #block-businessmetricsblock .business-metrix .metrix-box p {
        color: #666666;
        line-height: 24px;
        font-size: 16px;
        margin: 15px 0 0;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
    }

    #block-businessmetricsblock .business-metrix .metrix-box.odd-block {
        position: absolute;
        bottom: -60px;
        left: 0;
        right: 0;
        width: 90%;
        margin: 0 auto;
    }

    @media (max-width:767px) {
        #block-businessmetricsblock .business-metrix .metrix-box.odd-block {
            position: relative;
            bottom: 0;
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-businessmetricsblock .business-metrix .metrix-box.odd-block {
            position: relative;
            width: 100%;
            right: 20px;
            bottom: auto;
            margin-bottom: 20px;
        }
    }

    #block-businessmetricsblock .business-metrix .metrix-box.odd-block::before {
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/arrow-business.png) no-repeat;
        width: 147px;
        height: 61px;
        content: '';
        position: absolute;
        top: -80px;
        right: -62px;
    }

    @media (max-width:767px) {
        #block-businessmetricsblock .business-metrix .metrix-box.odd-block::before {
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-businessmetricsblock .business-metrix .metrix-box.odd-block::before {
            display: none;
        }
    }

    #block-businessmetricsblock .business-metrix .metrix-box.even-block {
        position: relative;
    }

    #block-businessmetricsblock .business-metrix .metrix-box.even-block h3 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        color: #FF6700 !important;
        font-size-adjust: 45px !important;
    }

    #block-businessmetricsblock .business-metrix .metrix-box.even-block::after {
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/arrow-business-down.png) no-repeat;
        width: 137px;
        height: 89px;
        content: '';
        position: absolute;
        bottom: -91px;
        right: -20px;
    }

    @media (max-width:767px) {
        #block-businessmetricsblock .business-metrix .metrix-box.even-block::after {
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-businessmetricsblock .business-metrix .metrix-box.even-block::after {
            display: none;
        }
    }

    #block-businessmetricsblock .business-metrix .row>div:last-child .metrix-box.even-block::after {
        content: none;
    }

    #block-mobileappblock {
        padding: 40px 0;
    }

    @media (max-width:991px) {
        #block-mobileappblock {
            padding: 50px 0 0;
        }
    }

    @media (max-width:767px) {
        #block-mobileappblock {
            padding: 40px 0 0;
        }
    }

    @media (max-width:767px) {
        #block-mobileappblock .row {
            display: block;
        }
    }

    #block-mobileappblock .row .mobile-app {
        width: 100%;
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #ffffff;
        width: 100%;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
    }

    #block-mobileappblock .row .mobile-app::before {
        width: 0;
        height: 100%;
        border-bottom: 435px solid #43009a;
        border-left: 440px solid transparent;
        position: absolute;
        content: "";
        right: 0;
        display: none;
    }

    @media (max-width:991px) {
        #block-mobileappblock .row .mobile-app::before {
            border-bottom: 250px solid #43009a;
            border-left: 250px solid transparent;
        }
    }

    @media (max-width:767px) {
        #block-mobileappblock .row .mobile-app::before {
            border-bottom: 250px solid #43009a;
            border-left: 240px solid transparent;
        }
    }

    @media (max-width:767px) {
        #block-mobileappblock .row .mobile-app {
            display: inline-block;
        }
    }

    #block-mobileappblock .row .mobile-app .mobile-app-section {
        text-align: center;
    }

    @media (max-width:767px) {
        #block-mobileappblock .row .mobile-app .mobile-app-section {
            background: #fff;
            -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
            -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
            box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
            margin-bottom: 20px;
            padding: 20px;
        }
    }

    #block-mobileappblock .row .mobile-app .mobile-app-section h3 {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        text-align: center;
    }

    #block-mobileappblock .row .mobile-app .mobile-app-section h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: center;
    }

    #block-mobileappblock .row .mobile-app .mobile-app-section p {
        font-size: 17px;
        color: #666666;
        font-family: "Montserrat", sans-serif;
        line-height: 24px;
        padding: 10px;
        text-align: center;
        margin: 0;
    }

    #block-mobileappblock .row .mobile-app .mobile-app-section a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        #block-mobileappblock .row .mobile-app .mobile-app-section a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-mobileappblock .row .mobile-app .mobile-app-section a.custom-all-btn {
            font-size: 15px;
        }
    }

    #block-mobileappblock .row .mobile-app .mobile-app-section a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-mobileappblock .row .mobile-app .mobile-app-section a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-mobileappblock .row .mobile-app .mobile-app-section a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media (max-width:991px) {
        #block-mobileappblock .row .mobile-app img {
            width: 350px;
        }
    }

    @media (max-width:767px) {
        #block-mobileappblock .row .mobile-app img {
            max-width: 100%;
        }
    }

    .shipping_tracking .shipping-rate-enquiry {
        z-index: 10 !important;
    }

    .shipping_tracking .shipping-rate-enquiry .js-form-item-from-country .bootstrap-select .dropdown-menu {
        width: 210px !important;
    }

    .shipping_tracking .shipping-rate-enquiry .js-form-item-from-country .bootstrap-select .inner.show {
        overflow-x: hidden;
    }

    .shipping_tracking .shipping-track-box .bootstrap-select .inner.show {
        max-height: 200px;
    }

    .shipping_tracking .shipping-track-box img {
        max-width: 100%;
    }

    .shipping_tracking {
        margin-top: -50px;
        z-index: 9;
        position: relative;
        padding-bottom: 40px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping_tracking {
            margin-top: 0;
            padding: 10px 15px;
        }
    }

    @media (max-width:767px) {
        .shipping_tracking {
            margin-top: 10px;
            padding: 10px 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking {
            margin-top: 0;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking {
            margin-top: -18%;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking {
            margin-top: -15%;
        }
    }

    @media screen and (min-width:1200px) and (max-width:1495px) {
        .shipping_tracking {
            margin-top: -50px;
        }
    }

    .shipping_tracking .shipping-track-box {
        background-color: transparent !important;
        -ms-flex: 0 0 30.66%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 30.66%;
        -moz-box-flex: 0;
        flex: 0 0 30.66%;
        max-width: 30.66%;
        padding: 15px;
        margin-right: 4%;
        min-height: 400px;
        height: auto;
        z-index: 9;
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        -o-background-size: 100% 100%;
        background-size: 100% 100%;
        background-image: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/Subtraction%202.png);
        position: relative;
        text-align: center;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        -webkit-transition-duration: 1.2s !important;
        -moz-transition-duration: 1.2s !important;
        -o-transition-duration: 1.2s !important;
        transition-duration: 1.2s !important;
    }

    .shipping_tracking .shipping-track-box:hover {
        -webkit-transform: scale(1.1) translateZ(0) !important;
        -moz-transform: scale(1.1) translateZ(0) !important;
        transform: scale(1.1) translateZ(0) !important;
        -webkit-box-shadow: 0px 18px 36px 9px rgba(64, 0, 153, 0.1);
        -moz-box-shadow: 0px 18px 36px 9px rgba(64, 0, 153, 0.1);
        box-shadow: 0px 18px 36px 9px rgba(64, 0, 153, 0.1);
    }

    .shipping_tracking .shipping-track-box:hover h2 {
        font-size: 26px;
        line-height: 1.2;
        -webkit-font-smoothing: antialised;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-track-box:hover h2 {
            font-size: 18px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-track-box:hover h2 {
            font-size: 18px !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-track-box:hover h2 {
            font-size: 20px !important;
        }
    }

    .shipping_tracking .shipping-track-box:last-child {
        margin-right: 0;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping_tracking .shipping-track-box {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 15px;
            margin-right: 0 !important;
        }
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-track-box {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 20px;
            margin-right: 0 !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-track-box {
            background-color: #ffffff !important;
        }
    }

    .shipping_tracking .shipping-track-box h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        margin: 15px 0 30px;
        line-height: 1.1;
    }

    .shipping_tracking .shipping-track-box .shipping-track-section {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center img {
        height: 200px;
        margin-top: -65px;
        z-index: -1;
        padding: 20px 0;
    }

    .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center p.shipping-service-center-text {
        padding: 90px 45px 58px;
        line-height: 1.5;
        font-size: 15px;
        color: #6c757d;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center p.shipping-service-center-text {
            padding: 55px 45px 58px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center p.shipping-service-center-text {
            padding: 78px 45px 58px;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center p.shipping-service-center-text {
            padding: 78px 45px 58px;
        }
    }

    .shipping_tracking .shipping-track-box .shipping-track-section img {
        max-width: 100%;
        padding: 15px 0;
    }

    .shipping_tracking .shipping-track-box .shipping-track-section ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        padding: 30px 0;
        width: 100%;
    }

    .shipping_tracking .shipping-track-box .shipping-track-section ul li {
        list-style-type: none;
        text-align: center;
        margin-right: 10px;
        line-height: 18px;
    }

    .shipping_tracking .shipping-track-box .shipping-track-section ul li a {
        color: #4D4D4F;
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-track-box .shipping-track-section ul li a {
            font-size: 12px;
            line-height: 1;
        }
    }

    .shipping_tracking .shipping-track-box .shipping-track-section ul li a:hover {
        color: #FF6700;
    }

    .shipping_tracking .shipping-track-box .shipping-track-section ul li:nth-child {
        border-right: 1px solid #ccc;
    }

    .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
        text-transform: uppercase;
        width: 70%;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        padding: 10px 40px;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            font-size: 15px;
        }
    }

    .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            padding: 10px !important;
            width: 82%;
        }
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form {
        padding: 30px 0;
        text-align: center;
        width: 100%;
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-type-textarea {
        margin-bottom: 30px;
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form label {
        display: none;
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea {
        height: 50px;
        text-align: left;
        border: 1px solid #B277FF;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        padding: 13px 15px;
        color: #495057;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 600;
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea::-webkit-input-placeholder {
        color: #A0A0A0;
        text-align: left;
        font-weight: 400;
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea:-moz-placeholder {
        color: #A0A0A0;
        text-align: left;
        font-weight: 400;
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea::-moz-placeholder {
        color: #A0A0A0;
        text-align: left;
        font-weight: 400;
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea:-ms-input-placeholder {
        color: #A0A0A0;
        text-align: left;
        font-weight: 400;
    }

    @media (max-width:767px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea {
            font-size: 12px;
            line-height: 22px;
            padding: 13px 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea {
            font-size: 12px;
            padding: 6px 10px;
            line-height: 20px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea {
            padding: 13px 25px;
            font-size: 12px;
            line-height: 22px;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea {
            padding: 13px 8px;
            font-size: 14px;
        }
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .description p {
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 20px;
        min-height: 70px;
        overflow-y: auto;
        height: auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
        width: 80%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -42px;
        margin: 0 auto;
        text-align: center;
    }

    @media (max-width:767px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .custom-all-btn {
            font-size: 15px;
        }
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .custom-all-btn {
            padding: 10px !important;
            width: 82%;
        }
    }

    @media (max-width:767px) {
        .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form {
            padding: 30px 0px;
        }
    }

    .shipping_tracking #block-awbtrackingblock .shipping-track-section img {
        height: auto;
    }

    .shipping_tracking .shipping-rate-enquiry form {
        padding: 10px 20px 0;
        float: left;
    }

    .shipping_tracking .shipping-rate-enquiry form label {
        position: absolute;
        font-size: 14px;
        color: #212529;
        text-transform: uppercase;
        left: 55px;
        right: auto;
        top: 30px;
        -webkit-font-smoothing: antialised;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;
        font-variant: all-small-caps;
        line-height: 21px;
        font-weight: 500;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form label {
            left: auto;
            right: auto;
            position: relative;
            top: 50px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form label {
            left: 30px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-rate-enquiry form label {
            left: 42px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form label.form-required::after {
        -webkit-background-size: 4px 4px;
        -moz-background-size: 4px 4px;
        -o-background-size: 4px 4px;
        background-size: 4px 4px;
        width: 4px;
        height: 4px;
        margin: 0 3px;
        display: none;
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select {
        width: 100% !important;
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle {
        background-color: transparent;
        width: 75%;
        height: auto;
        line-height: 15px;
        outline: 0 !important;
        border: 0;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle {
            width: 100%;
            text-align: center;
            padding: 0;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle {
            padding: 0;
            width: 100%;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle .filter-option .filter-option-inner-inner {
        text-align: center;
        -webkit-font-smoothing: antialised;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle::after {
        position: absolute;
        left: auto;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #000f;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle::after {
            right: 0;
            left: 70px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle::after {
            right: 0;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle::after {
            right: 0;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle::after {
            right: 0;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle:focus {
        outline: 0 !important;
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select.show button.dropdown-toggle::after {
        position: absolute;
        left: auto;
        content: "\e91d";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select.show button.dropdown-toggle::after {
            right: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select.show button.dropdown-toggle::after {
            right: 0;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-rate-enquiry form .bootstrap-select.show button.dropdown-toggle::after {
            right: 0;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select .dropdown-menu li {
        -webkit-font-smoothing: antialised;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select .dropdown-menu li a.dropdown-item.active {
        color: #888787;
        background-color: #e6e6e6;
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select .dropdown-menu li a.dropdown-item:hover {
        color: #f08c8c;
        background-color: #e6e6e6;
    }

    .shipping_tracking .shipping-rate-enquiry form .bootstrap-select .dropdown-menu li a.dropdown-item:focus {
        color: #888787;
        background-color: #e6e6e6;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-actions {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        margin: 0 auto;
        text-align: center;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-actions .custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
        width: 80%;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form #edit-actions .custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-rate-enquiry form #edit-actions .custom-all-btn {
            font-size: 15px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-actions .custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form #edit-actions .custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-rate-enquiry form #edit-actions .custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking .shipping-rate-enquiry form #edit-actions .custom-all-btn {
            padding: 10px !important;
            width: 82%;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
        width: 50%;
        float: left;
        position: relative;
        padding: 10px;
        margin-bottom: 10px;
        padding-left: 0;
        margin-top: 10px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            float: none;
            padding-left: 10px;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            width: 60%;
            float: none;
            padding-left: 0;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            padding-left: 15px;
        }
    }

    @media screen and (min-width:1200px) and (max-width:1495px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            padding-left: 0;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            padding-left: 30px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country select {
        border: 0;
        width: 80%;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: transparent;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country select:focus {
        outline: 0;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::before {
        content: "from";
        color: #A0A0A0;
        font-size: 12px;
        text-transform: uppercase;
        bottom: -12px;
        left: 65px;
        position: absolute;
        display: none;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::before {
            left: 25px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::after {
        position: absolute;
        content: "";
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/globe.png);
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: auto auto;
        -moz-background-size: auto;
        -o-background-size: auto;
        background-size: auto;
        width: 20px;
        height: 20px;
        left: 20px;
        top: 15px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::after {
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 20px;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::after {
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::after {
            left: -10px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::after {
            left: 15px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
        width: 50%;
        float: left;
        position: relative;
        padding: 10px;
        margin-bottom: 10px;
        padding-left: 0;
        margin-top: 10px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            float: none;
            padding-left: 0;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            width: 60%;
            float: none;
            padding-left: 0;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            padding-left: 15px;
        }
    }

    @media screen and (min-width:1200px) and (max-width:1495px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            padding-left: 0;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            padding-left: 30px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country select {
        border: 0;
        width: 80%;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: transparent;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country select:focus {
        outline: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country select {
            width: 100%;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::before {
        content: "to";
        color: #A0A0A0;
        font-size: 12px;
        text-transform: uppercase;
        bottom: -12px;
        left: 60px;
        position: absolute;
        display: none;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::before {
            left: 30px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
        position: absolute;
        content: "";
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/Arrow_grh01.png);
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: auto auto;
        -moz-background-size: auto;
        -o-background-size: auto;
        background-size: auto;
        width: 60px;
        height: 20px;
        left: -25px;
        top: 15px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
            -webkit-background-size: 100% 100%;
            -moz-background-size: 100%;
            -o-background-size: 100%;
            background-size: 100%;
            width: 30px;
            left: -10px !important;
            right: 0 !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
            left: -20px;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
            left: -15px;
        }
    }

    @media screen and (min-width:1200px) and (max-width:1495px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
            left: -25px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight {
        width: 80%;
        margin: 0 auto;
        position: relative;
        padding: 10px;
        margin-bottom: 0;
        -webkit-font-smoothing: antialised;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight {
            width: 80%;
            float: none;
            margin: 0 auto;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text {
        border: 0;
        width: 80%;
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/weight.png);
        background-position: 12% 50%;
        background-repeat: no-repeat;
        -webkit-background-size: auto auto;
        -moz-background-size: auto;
        -o-background-size: auto;
        background-size: auto;
        border: 1px solid #B277FF;
        padding: 10px 10px 10px 40px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        margin: 0 auto;
        font-weight: 600;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text::-webkit-input-placeholder {
        font-weight: 400;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text:-moz-placeholder {
        font-weight: 400;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text::-moz-placeholder {
        font-weight: 400;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text:-ms-input-placeholder {
        font-weight: 400;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text {
            background-position: 2% 50%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text {
            padding: 0 0 0 30px;
            width: 100%;
            height: 45px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight::before {
        content: "weight";
        color: #A0A0A0;
        font-size: 12px;
        text-transform: uppercase;
        bottom: -12px;
        left: 55px;
        position: absolute;
        display: none;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to {
        width: 50%;
        float: left;
        position: relative;
        padding: 10px;
        margin-bottom: 0;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to {
            width: 80%;
            float: none;
            margin: 0 auto;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text {
        border: 0;
        width: 80%;
        border: 1px solid #B277FF;
        padding: 10px 10px 10px 10px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        margin: 0 auto;
        font-weight: 600;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text::-webkit-input-placeholder {
        font-weight: 400;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text:-moz-placeholder {
        font-weight: 400;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text::-moz-placeholder {
        font-weight: 400;
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text:-ms-input-placeholder {
        font-weight: 400;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text {
            padding-left: 30px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text {
            padding: 0 10px 0 10px;
            width: 100%;
            height: 45px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to::before {
        content: "weight";
        color: #A0A0A0;
        font-size: 12px;
        text-transform: uppercase;
        bottom: -12px;
        left: 55px;
        position: absolute;
        display: none;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type {
        width: 100%;
        float: left;
        position: relative;
        padding: 5px 10px 0;
        text-align: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 6px;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type label {
        display: inline-block;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form #edit-weight-type {
            margin-top: 0;
            margin-bottom: 60px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type select {
        border: 0;
        width: 80%;
        padding-left: 50px;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check {
        width: 28%;
        float: left;
        padding-left: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check {
            width: 50%;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check input {
        width: 50px;
        height: 30px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        -webkit-appearance: none;
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        position: relative;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check input::before {
        border: 1px solid transparent;
        position: absolute;
        width: 50px;
        height: 30px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        left: 0;
        background-color: #F9F9F9;
        cursor: pointer;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        color: #A0A0A0;
        line-height: 25px;
        content: "";
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check input:focus {
        outline: 0;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check input[type="radio"]:checked::before {
        background-color: #c8b2e6;
        border: 1px solid #c8b2e6;
        cursor: pointer;
        color: #A686D2;
        text-align: center;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check input[type="radio"]::-ms-check::before {
        background-color: #c8b2e6;
        border: 1px solid #c8b2e6;
        cursor: pointer;
        color: #A686D2;
        text-align: center;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check label {
        position: absolute;
        left: 0;
        right: 0;
        top: 6px;
        text-align: center;
        margin: 0 auto;
        line-height: 1.5;
        font-size: 12px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check label:focus {
        outline: 0;
    }

    .shipping_tracking .shipping-rate-enquiry form #edit-weight-type #edit-weight-type-lbs::before {
        content: "";
    }

    .shipping_tracking .shipping-rate-enquiry img {
        height: 95px;
        margin-top: 15px;
    }

    #mailchimp-signup-subscribe-block-subscribe-form #mailchimp-signup-subscribe-block-subscribe-form .alert-danger,
    .alert-warning,
    #mailchimp-signup-subscribe-block-subscribe-form .alert-success {
        position: absolute;
        top: -60px;
        font-size: 12px;
    }

    #mailchimp-signup-subscribe-block-subscribe-form .form-actions .btn-primary:focus {
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #mailchimp-signup-subscribe-block-subscribe-form input#edit-mergevars-email {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    html[dir="rtl"] body {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] .full_width_banner {
        direction: ltr;
    }

    html[dir="rtl"] .full_width_banner .banner-content h1 span {
        font-family: "Tajawal", sans-serif !important;
    }

    html[dir="rtl"] .full_width_banner .banner-content h1 span::after {
        -webkit-transform: rotate(-180deg) scale(1, -1);
        -moz-transform: rotate(-180deg) scale(1, -1);
        -ms-transform: rotate(-180deg) scale(1, -1);
        -o-transform: rotate(-180deg) scale(1, -1);
        transform: rotate(-180deg) scale(1, -1);
    }

    html[dir="rtl"] .full_width_banner .banner-content span {
        font-family: "Tajawal", sans-serif !important;
    }

    html[dir="rtl"] .full_width_banner .view-welcome-slider .owl-wrapper-outer .owl-wrapper.owl-carousel {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] .shipping_tracking .shipping-track-box:first-child {
        margin-right: 0;
    }

    html[dir="rtl"] .shipping_tracking .shipping-track-box:last-child {
        margin-right: 4%;
    }

    html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
        padding: 10px 28px;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            padding: 10px 15px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            padding: 8px !important;
            width: 82%;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section a.custom-all-btn {
            padding: 10px !important;
            width: 82%;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section ul li a {
        font-family: 'Cairo', sans-serif;
        padding: 0 13px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }

    html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center p.shipping-service-center-text {
        padding: 103px 45px 58px;
        line-height: 1.5;
        font-size: 15px;
        color: #6c757d;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center p.shipping-service-center-text {
            padding: 66px 45px 58px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center p.shipping-service-center-text {
            padding: 90px 45px 58px;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        html[dir="rtl"] .shipping_tracking .shipping-track-box .shipping-track-section.shipping-service-center p.shipping-service-center-text {
            padding: 90px 45px 58px;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-track-box h2 {
        font-family: 'Cairo', sans-serif;
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form label {
            left: 52px;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form label {
            left: 40px;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
        padding-left: 0;
        padding-right: 65px;
        float: right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            padding-left: 0;
            padding-right: 0;
            float: none;
            width: 60%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            padding-right: 28px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            padding-right: 52px;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            padding-right: 55px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country label {
            left: -20px;
            right: 0;
            top: 30px;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::before {
        left: auto;
        right: 65px;
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::after {
        left: auto;
        right: 30px;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::after {
            right: 0;
            left: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country::after {
            right: 0 !important;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
        padding-left: 0;
        padding-right: 65px;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            padding-left: 0;
            padding-right: 0;
            width: 60%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            padding-right: 28px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            padding-right: 45px;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            padding-right: 55px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country label {
            left: -8px;
            right: 0;
            top: 30px;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::before {
        left: auto;
        right: 60px;
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
        left: 0;
        -webkit-transform: rotate(-175deg) scale(1, -1);
        -moz-transform: rotate(-175deg) scale(1, -1);
        -ms-transform: rotate(-175deg) scale(1, -1);
        -o-transform: rotate(-175deg) scale(1, -1);
        transform: rotate(-175deg) scale(1, -1);
        right: 0;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
            right: -5px !important;
        }
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight {
            float: none;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text {
        padding-left: 0;
        padding-right: 40px;
        background-position: 90% 50%;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight .form-text {
            background-position: 97% 50%;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to {
        float: right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to {
            float: none;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text {
        padding-left: 0;
        padding-right: 30px;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .js-form-item-weight-to .form-text {
            background-position: 97% 50%;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check {
        float: right;
        padding-right: 0;
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form #edit-weight-type .form-check input::before {
        left: 0;
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle {
        padding: 0;
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle::after {
        left: 0;
        right: auto;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle::after {
            right: 70px;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle .filter-option {
        text-align: right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle .filter-option {
            text-align: center !important;
        }
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .bootstrap-select .dropdown-menu {
        left: auto !important;
    }

    html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form .bootstrap-select .dropdown-menu li a.dropdown-item {
        text-align: right;
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators {
        right: auto;
        left: -20%;
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators {
            left: -15%;
        }
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li::before {
        left: auto;
        right: 0;
        top: 0;
        height: 0;
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li::after {
        bottom: 0;
        height: 0;
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li.active a {
        color: #fff;
        -webkit-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        transition: all ease 0.5s;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        padding-left: 30px;
        font-size: 20px;
        position: relative;
        background-position: right bottom;
        right: -20%;
        left: auto;
        width: 120%;
        border-bottom: 0;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li.active a {
            font-size: 14px;
            border: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li.active a {
            font-size: 14px;
        }
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li.active a::before {
        display: none;
        top: 50%;
        left: -50px;
        width: 15px;
        height: 2px;
        background-color: #fff;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li.active a::before {
            top: 48%;
        }
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li a {
        font-family: 'Cairo', sans-serif;
        padding: 20px 30px 20px 20px;
        background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), color-stop(50%, #FF6700));
        background: -webkit-linear-gradient(left, #fff 50%, #FF6700 50%);
        background: -moz-linear-gradient(left, #fff 50%, #FF6700 50%);
        background: -o-linear-gradient(left, #fff 50%, #FF6700 50%);
        background: linear-gradient(to right, #fff 50%, #FF6700 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: left bottom;
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li a:hover {
        width: 120%;
        background-position: right bottom;
        margin-right: -60px;
        margin-bottom: 0;
        border-color: #FF6700;
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li a::before {
        right: 0;
        left: auto;
    }

    html[dir="rtl"] #block-servicescarouselblock .carousel .carousel-indicators li a::after {
        right: 10px;
        left: auto;
        -webkit-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    html[dir="rtl"] #block-servicescarouselblock h2 {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-1 .industries .view-header {
        margin-right: 0;
        margin-left: 15px;
    }

    html[dir="rtl"] #block-views-block-industries-block-1 .industries .view-header a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-1 .industries .view-content.row {
        direction: ltr;
    }

    html[dir="rtl"] #block-views-block-industries-block-1 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-2 .industries .view-header {
        margin-right: 0;
        margin-left: 15px;
    }

    html[dir="rtl"] #block-views-block-industries-block-2 .industries .view-header a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-2 .industries .view-content.row {
        direction: ltr;
    }

    html[dir="rtl"] #block-views-block-industries-block-2 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-3 .industries .view-header {
        margin-right: 0;
        margin-left: 15px;
    }

    html[dir="rtl"] #block-views-block-industries-block-3 .industries .view-header a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-3 .industries .view-content.row {
        direction: ltr;
    }

    html[dir="rtl"] #block-views-block-industries-block-3 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-4 .industries .view-header {
        margin-right: 0;
        margin-left: 15px;
    }

    html[dir="rtl"] #block-views-block-industries-block-4 .industries .view-header a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-4 .industries .view-content.row {
        direction: ltr;
    }

    html[dir="rtl"] #block-views-block-industries-block-4 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-5 .industries .view-header {
        margin-right: 0;
        margin-left: 15px;
    }

    html[dir="rtl"] #block-views-block-industries-block-5 .industries .view-header a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-5 .industries .view-content.row {
        direction: ltr;
    }

    html[dir="rtl"] #block-views-block-industries-block-5 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-6 .industries .view-header {
        margin-right: 0;
        margin-left: 15px;
    }

    html[dir="rtl"] #block-views-block-industries-block-6 .industries .view-header a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-industries-block-6 .industries .view-content.row {
        direction: ltr;
    }

    html[dir="rtl"] #block-views-block-industries-block-6 .industries .view-content .owl-wrapper-outer .owl-wrapper .owl-item .views-field-title .field-content a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-header a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content.row {
        direction: ltr;
    }

    html[dir="rtl"] #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content .owl-wrapper-outer {
        float: left;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content .owl-wrapper-outer {
            float: none;
        }
    }

    html[dir="rtl"] #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content .owl-controls .owl-buttons {
        left: 0;
        right: 20%;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #block-views-block-smsa-express-news-block-1 .view-smsa-express-news .view-content .owl-controls .owl-buttons {
            left: 0;
            right: 0;
        }
    }

    html[dir="rtl"] #block-businessmetricsblock h2 {
        margin-bottom: 50px;
    }

    html[dir="rtl"] #block-businessmetricsblock .business-metrix {
        float: left;
    }

    html[dir="rtl"] #block-businessmetricsblock .business-metrix .metrix-box.even-block::after {
        bottom: -98px;
        right: 200px;
        -webkit-transform: rotate(-175deg) scale(1, -1);
        -moz-transform: rotate(-175deg) scale(1, -1);
        -ms-transform: rotate(-175deg) scale(1, -1);
        -o-transform: rotate(-175deg) scale(1, -1);
        transform: rotate(-175deg) scale(1, -1);
    }

    html[dir="rtl"] #block-businessmetricsblock .business-metrix .metrix-box.odd-block::before {
        top: -85px;
        right: 185px;
        -webkit-transform: rotate(190deg) scale(1, -1);
        -moz-transform: rotate(190deg) scale(1, -1);
        -ms-transform: rotate(190deg) scale(1, -1);
        -o-transform: rotate(190deg) scale(1, -1);
        transform: rotate(190deg) scale(1, -1);
    }

    html[dir="rtl"] #block-businessmetricsblock .business-metrix .metrix-box .text-right {
        right: auto;
        left: 10px;
    }

    html[dir="rtl"] #block-businessmetricsblock .business-metrix .metrix-box h3 {
        text-align: right;
    }

    html[dir="rtl"] #block-views-block-case-studies-block-1 .case-study-section {
        margin-right: 0 !important;
        margin-left: 10%;
    }

    html[dir="rtl"] #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content {
        right: auto !important;
        left: -8%;
    }

    html[dir="rtl"] #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content a {
        font-family: "Tajawal", sans-serif !important;
        direction: ltr;
        display: inline-block;
    }

    html[dir="rtl"] #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content a::before {
        right: 0;
        left: auto !important;
    }

    html[dir="rtl"] #block-views-block-case-studies-block-1 .case-study-section .case-box .case-content a:hover::before {
        right: 0;
        left: auto !important;
    }

    html[dir="rtl"] #block-mobileappblock .row .mobile-app {
        width: 100%;
    }

    html[dir="rtl"] #block-mobileappblock .row .mobile-app::before {
        border-left: 0;
        border-right: 440px solid transparent;
        right: auto;
        left: 0;
        display: none;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #block-mobileappblock .row .mobile-app::before {
            right: 0;
        }
    }

    html[dir="rtl"] #block-mobileappblock .row .mobile-app .mobile-app-section a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
        text-align: right !important;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
            text-align: right !important;
        }
    }

    html[dir="rtl"] .bootstrap-select .dropdown-menu {
        right: 0 !important;
    }

    .view-case-studies .views-view-grid .row:first-child .col-lg-6:nth-child(2) .case-content {
        -webkit-transition-duration: 1.8s !important;
        -moz-transition-duration: 1.8s !important;
        -o-transition-duration: 1.8s !important;
        transition-duration: 1.8s !important;
    }

    .view-case-studies .views-view-grid .row:nth-child(2) .col-lg-6:first-child .case-content {
        -webkit-transition-duration: 2.1s !important;
        -moz-transition-duration: 2.1s !important;
        -o-transition-duration: 2.1s !important;
        transition-duration: 2.1s !important;
    }

    .view-case-studies .views-view-grid .row:nth-child(2) .col-lg-6:nth-child(2) .case-content {
        -webkit-transition-duration: 2.6s !important;
        -moz-transition-duration: 2.6s !important;
        -o-transition-duration: 2.6s !important;
        transition-duration: 2.6s !important;
    }

    nav#navbar-top {
        padding: 0;
    }

    nav#navbar-top .region-top-header {
        width: 100%;
        margin: 0;
        text-align: center;
    }

    .alert_block_on_top #hider {
        float: right;
        width: 30px;
        position: absolute;
        top: auto;
        right: 0;
        color: #0f0f0f;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: none;
        cursor: pointer;
        background-color: #efefef;
        bottom: 0;
        height: 20px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .alert_block_on_top #hider:hover {
        background-color: #fff;
        cursor: pointer;
    }

    .alert_block_on_top #hider b {
        font-weight: 500;
        font-size: 12px !important;
        color: #0f0f0f;
    }

    @media (max-width:991px) {
        .alert_block_on_top #hider {
            float: none !important;
            margin: 0 auto;
        }
    }

    .alert_block_on_top {
        background: -webkit-repeating-linear-gradient(145deg, #dedede, #dedede 10px, #d7d7d7 10px, #d7d7d7 20px);
        background: -moz-repeating-linear-gradient(145deg, #dedede, #dedede 10px, #d7d7d7 10px, #d7d7d7 20px);
        background: -o-repeating-linear-gradient(145deg, #dedede, #dedede 10px, #d7d7d7 10px, #d7d7d7 20px);
        background: repeating-linear-gradient(-55deg, #dedede, #dedede 10px, #d7d7d7 10px, #d7d7d7 20px);
        background: -webkit-repeating-linear-gradient(145deg, rgba(160, 128, 204, 0.22), rgba(89, 32, 166, 0.15) 10px, #baa3da40 10px, #d7d7d773 20px);
        background: -moz-repeating-linear-gradient(145deg, rgba(160, 128, 204, 0.22), rgba(89, 32, 166, 0.15) 10px, #baa3da40 10px, #d7d7d773 20px);
        background: -o-repeating-linear-gradient(145deg, rgba(160, 128, 204, 0.22), rgba(89, 32, 166, 0.15) 10px, #baa3da40 10px, #d7d7d773 20px);
        background: repeating-linear-gradient(-55deg, rgba(160, 128, 204, 0.22), rgba(89, 32, 166, 0.15) 10px, #baa3da40 10px, #d7d7d773 20px);
        background: #dc3545;
    }

    .alert_block_on_top,
    .mobile_app_banner {
        width: 100%;
        padding: 10px 20px;
        z-index: 999999;
    }

    .mobile_app_banner {
        display: none;
        background: #bcbabd;
    }

    @media (max-width:767px) {
        .mobile_app_banner {
            display: block;
        }
    }

    .mobile_app_banner img {
        margin-right: 20px;
    }

    .alert_block_on_top p,
    .mobile_app_banner p {
        margin-bottom: 0;
        color: #0f0f0f;
        font-size: 16px;
        font-weight: 500;
    }

    .alert_block_on_top p {
        color: #fff;
    }

    @media (max-width:991px) {
        .alert_block_on_top p {
            margin-bottom: 25px;
        }
    }

    #block-views-block-welcome-slider-block-1 .owl-controls {
        position: absolute !important;
        width: 100%;
        top: 45%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-welcome-slider-block-1 .owl-controls {
            top: 33%;
        }
    }

    @media (max-width:767px) {
        #block-views-block-welcome-slider-block-1 .owl-controls {
            top: 24%;
        }
    }

    #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons {
        position: absolute;
        width: 100%;
    }

    #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons .owl-prev {
        color: #FFF;
        display: inline-block;
        zoom: 1;
        *display: inline;
        margin: 5px;
        padding: 3px 10px;
        font-size: 12px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        background: #ff6600;
        filter: Alpha(Opacity=50);
        opacity: 0.5;
        left: 40px;
        width: 30px;
        height: 30px;
        position: absolute;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons .owl-prev i::before {
            position: absolute;
            left: 0px;
            right: 2px;
            top: 9px;
            text-align: center;
        }
    }

    @media (max-width:767px) {
        #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons .owl-prev {
            width: 20px;
            height: 20px;
            text-align: center;
        }

        #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons .owl-prev i::before {
            position: absolute;
            left: 0;
            right: 0;
            top: 4px;
            text-align: center;
        }
    }

    #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons .owl-next {
        color: #FFF;
        display: inline-block;
        zoom: 1;
        *display: inline;
        margin: 5px;
        padding: 3px 10px;
        font-size: 12px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        background: #ff6600;
        filter: Alpha(Opacity=50);
        opacity: 0.5;
        right: 40px;
        width: 30px;
        height: 30px;
        position: absolute;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons .owl-next i::before {
            position: absolute;
            left: 2px;
            right: 0;
            top: 9px;
            text-align: center;
        }
    }

    @media (max-width:767px) {
        #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons .owl-next {
            width: 20px;
            height: 20px;
            text-align: center;
        }

        #block-views-block-welcome-slider-block-1 .owl-controls .owl-buttons .owl-next i::before {
            position: absolute;
            left: 0;
            right: 0;
            top: 4px;
            text-align: center;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .user-logged-in .shipping_tracking .shipping-rate-enquiry form label {
            left: 18px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .user-logged-in .shipping_tracking .shipping-rate-enquiry form label {
            left: 52px;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .user-logged-in .shipping_tracking .shipping-rate-enquiry form label {
            left: 52px;
        }
    }

    .user-logged-in .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
        padding-left: 45px;
    }

    .user-logged-in .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country::after {
        left: -22px;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .user-logged-in .shipping_tracking .shipping-rate-enquiry form .js-form-item-to-country {
            padding-left: 0;
        }
    }

    .user-logged-in .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
        padding-left: 45px;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .user-logged-in .shipping_tracking .shipping-rate-enquiry form .js-form-item-from-country {
            padding-left: 0;
        }
    }

    .user-logged-in .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle {
        padding: 0 !important;
    }

    .user-logged-in .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle .filter-option .filter-option-inner-inner {
        font-size: 12px;
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .user-logged-in .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle .filter-option .filter-option-inner-inner {
            text-align: left;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        .user-logged-in .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle .filter-option .filter-option-inner-inner {
            text-align: left;
        }
    }

    @media (min-width:1200px) {
        .user-logged-in .shipping_tracking .shipping-rate-enquiry form .bootstrap-select button.dropdown-toggle .filter-option .filter-option-inner-inner {
            text-align: left;
        }
    }

    .user-logged-in .shipping_tracking .shipping-track-box {
        opacity: 1;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        transform: translateZ(0);
    }

    .user-logged-in #block-searchcontactlocation {
        position: inherit !important;
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .user-logged-in .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
            text-align: left;
        }
    }

    html[dir="rtl"] #navbar-main .navbar-form .region-header-form #block-searchcontactlocation .dropdown-menu .dropdown-item {
        margin-right: 0;
    }

    html[dir="rtl"] .alert_block_on_top #hider {
        float: left;
        left: 0;
        right: auto;
    }

    @media (max-width:991px) {
        html[dir="rtl"] .alert_block_on_top #hider {
            float: none !important;
            position: relative;
            margin: 0 auto;
        }
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form {
        width: 100%;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea {
        text-align: right;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea::-webkit-input-placeholder {
        text-align: right !important;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea:-moz-placeholder {
        text-align: right !important;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea::-moz-placeholder {
        text-align: right !important;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea:-ms-input-placeholder {
        text-align: right !important;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea::-ms-input-placeholder {
        text-align: right !important;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea::placeholder {
        text-align: right !important;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea:-ms-input-placeholder {
        text-align: right !important;
    }

    html[dir="rtl"] .shipping_tracking #block-awbtrackingblock .shipping-track-section form.awb-tracking-form .form-textarea-wrapper textarea::-ms-input-placeholder {
        text-align: right !important;
    }

    @media screen and (min-device-width:1024px) and (max-device-width:1366px) and (orientation:portrait) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 90%;
        }

        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 90%;
        }
    }

    @media screen and (min-device-width:800px) and (max-device-width:1280px) and (orientation:landscape) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 82%;
        }

        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 82%;
        }
    }

    @media screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 90%;
        }

        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 90%;
        }
    }

    @media screen and (min-device-width:461px) and (max-device-width:570px) and (orientation:portrait) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 82%;
        }

        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 82%;
        }
    }

    @media screen and (min-device-width:320px) and (max-device-width:460px) and (orientation:portrait) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 72%;
        }

        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 72%;
        }
    }

    @media screen and (min-device-width:360px) and (max-device-width:640px) and (orientation:landscape) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 82%;
        }

        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 82%;
        }
    }

    @media screen and (min-device-width:414px) and (max-device-width:767px) and (orientation:landscape) {
        #block-servicescarouselblock .carousel .carousel-control-prev {
            width: 84%;
        }

        #block-servicescarouselblock .carousel .carousel-control-next {
            width: 84%;
        }
    }

    .faq-page .ui-state-active .ui-icon,
    .ui-button:active .ui-icon {
        background-image: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/ui-icons_444444_256x240.png) !important;
        position: absolute;
        right: 12px;
        top: 54%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .faq-page .ui-icon.ui-icon-plus {
        position: relative;
        float: right;
        background-image: none !important;
    }

    .faq-page .ui-icon {
        width: 20px !important;
        height: 20px !important;
        text-indent: 0 !important;
        overflow: visible !important;
        margin-top: 0;
    }

    .faq-page .ui-icon.ui-icon-plus:before {
        content: '\e91c' !important;
        position: absolute;
        font-family: icomoon !important;
        right: 0;
        font-size: 18px;
        color: #000 !important;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 999;
        left: 0;
    }

    span.ui-accordion-header-icon.ui-icon.ui-icon-minus {
        position: relative;
        float: right;
        background-image: none !important;
        top: auto !important;
        right: auto !important;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
    }

    span.ui-accordion-header-icon.ui-icon.ui-icon-minus:before {
        position: absolute;
        right: 0;
        font-size: inherit;
        color: #000 !important;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 999;
        left: 0;
        width: 18px;
        height: 2px;
        content: "";
        content: '';
        background: #000;
    }

    #block-faqbanner.custom-banner-block .field--name-body {
        padding: 0;
    }

    #block-faqbanner.custom-banner-block .field--name-body h2 {
        margin-top: -10px;
        padding: 0 !important;
    }

    .faq-page .view-content>div {
        width: 100%;
    }

    .faq-page .view-content>div>div {
        margin-bottom: 20px;
    }

    .faq-page .view-content .views-accordion-header {
        color: #4D4D4F;
        border: 1px solid #A0A0A0;
        padding: 20px;
        background-color: #ffffff;
    }

    .faq-page .view-content .views-accordion-header .ui-state-active .ui-icon {
        background-image: none !important;
    }

    .faq-page .view-content .views-accordion-header.ui-accordion-header-active.ui-state-active {
        background-color: #f9f9f9;
    }

    .faq-page .view-content .views-accordion-header:focus {
        outline: 0;
    }

    .faq-page .view-content .views-accordion-header span.field-content {
        font-size: 18px;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #4D4D4F;
    }

    @media (max-width:767px) {
        .faq-page .view-content .views-accordion-header span.field-content {
            font-size: 16px;
        }
    }

    .faq-page .view-content .ui-accordion-content {
        margin-bottom: 22px;
        line-height: 20px;
        border-color: #A0A0A0;
        padding: 20px;
    }

    .faq-page .view-content .ui-accordion-content .field-content p {
        font-size: 16px;
        line-height: 27px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #4D4D4F;
    }

    .faq-page form.views-exposed-form {
        display: none;
    }

    #block-faqfilterblock.faq-search-box-show {
        position: relative;
        bottom: -10px;
        left: 0;
        right: 0;
        width: 35%;
        margin: 0 auto;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        #block-faqfilterblock.faq-search-box-show {
            width: 90%;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        #block-faqfilterblock.faq-search-box-show {
            width: 60%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-faqfilterblock.faq-search-box-show {
            width: 70%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #block-faqfilterblock.faq-search-box-show {
            width: 50% !important;
        }
    }

    @media screen and (min-width:1025px) and (max-width:1199px) {
        #block-faqfilterblock.faq-search-box-show {
            width: 50%;
        }
    }

    @media screen and (min-width:1200px) and (max-width:1495px) {
        #block-faqfilterblock.faq-search-box-show {
            width: 50%;
        }
    }

    #block-faqfilterblock.faq-search-box-show form.faq-filter-form {
        width: 100%;
    }

    #block-faqfilterblock.faq-search-box-show form.faq-filter-form>fieldset input {
        padding: 15px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        #block-faqfilterblock.faq-search-box-show form.faq-filter-form>fieldset input {
            padding: 5px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-faqfilterblock.faq-search-box-show form.faq-filter-form>fieldset input {
            padding: 10px;
        }
    }

    #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions {
        position: absolute;
        right: 10px;
        margin: 0;
        padding: 0;
        top: 15px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions {
            top: 3px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions {
            top: 6px;
        }
    }

    #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions::before {
        content: "\e908";
        color: #FF6700;
        position: absolute;
        left: 0;
        right: 0;
        top: 4px;
        font-family: "icomoon" !important;
        font-size: 20px;
        cursor: pointer;
        text-align: center;
        pointer-events: none;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions::before {
            font-size: 14px;
            top: 7px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions::before {
            top: 3px;
        }
    }

    #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions input.btn-primary {
        color: transparent;
        background-color: transparent;
        border-color: transparent;
    }

    #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions input.btn-primary:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    @media (device-height:568px) and (device-width:320px) and (-webkit-min-device-pixel-ratio:2) {
        #block-faqfilterblock.faq-search-box-show form.faq-filter-form>fieldset input {
            bottom: 60px;
            width: 100%;
        }
    }

    html[dir="rtl"] .faq-page .view-content .views-accordion-header span.field-content {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-faqfilterblock.faq-search-box-show form.faq-filter-form #edit-actions {
        right: auto;
        left: 10px;
    }

    html[dir="rtl"] #block-faqfilterblock.faq-search-box-show form.faq-filter-form>fieldset input {
        text-align: right;
    }

    html[dir="rtl"] span.ui-accordion-header-icon.ui-icon.ui-icon-minus,
    html[dir="rtl"] .faq-page .ui-icon.ui-icon-plus {
        float: none;
        left: 15px !important;
        right: auto !important;
        position: absolute;
    }

    html[dir="rtl"] .faq-page .view-content .views-accordion-header span.field-content {
        float: none;
    }

    .node--type-news #block-smsa-page-title h1 {
        font-size: 28px;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        padding-bottom: 20px;
        border-bottom: 1px solid #707070;
    }

    .node--type-news #block-smsa-content .node--type-news .field--type-image {
        margin: 0 4% 0 0;
        width: 30%;
    }

    @media (max-width:767px) {
        .node--type-news #block-smsa-content .node--type-news .field--type-image {
            width: 100%;
            height: auto;
        }
    }

    .node--type-news #block-smsa-content .node--type-news .field--type-image img {
        width: 100%;
        height: auto;
    }

    .node--type-news #block-smsa-content .node--type-news .field--name-body {
        float: left;
        width: 66%;
    }

    @media (max-width:767px) {
        .node--type-news #block-smsa-content .node--type-news .field--name-body {
            width: 100%;
        }
    }

    .node--type-news #block-smsa-content .node--type-news .field--name-body p {
        font-size: 18px;
        font-weight: 300;
        font-family: "Montserrat", sans-serif;
        line-height: 27px;
    }

    .news-page .news-section {
        padding: 50px 0;
        margin-right: 10%;
    }

    @media (max-width:767px) {
        .news-page .news-section {
            margin-right: 0;
            padding: 40px 0;
        }
    }

    .news-page .news-section:hover .news-box .news-img img {
        -webkit-transform: scale(1.03);
        -moz-transform: scale(1.03);
        -ms-transform: scale(1.03);
        -o-transform: scale(1.03);
        transform: scale(1.03);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        opacity: 0.8;
        -webkit-filter: grayscale(0.5);
        -moz-filter: grayscale(0.5);
        -ms-filter: grayscale(0.5);
        -o-filter: grayscale(0.5);
        filter: grayscale(0.5);
    }

    .news-page .news-section:hover .news-box .news-content {
        bottom: -6%;
    }

    .news-page .news-section .news-box {
        position: relative;
    }

    .news-page .news-section .news-box .news-img {
        overflow: hidden;
    }

    .news-page .news-section .news-box .news-img img {
        max-width: 100%;
        max-height: 275px;
        overflow: hidden;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        overflow: hidden;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        height: 100%;
    }

    .news-page .news-section .news-box .news-content {
        position: absolute;
        background-color: #ffffff;
        bottom: -9%;
        right: -8%;
        width: 330px;
        padding: 15px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid #ffffff;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-bottomright: 5px;
        border-bottom-right-radius: 5px;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
    }

    @media (max-width:767px) {
        .news-page .news-section .news-box .news-content {
            position: static;
            right: 0;
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .news-page .news-section .news-box .news-content {
            width: 350px;
        }
    }

    .news-page .news-section .news-box .news-content h4 {
        color: #43009B;
        font-size: 18px !important;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
    }

    @media (max-width:767px) {
        .news-page .news-section .news-box .news-content h4 {
            font-size: 16px;
        }
    }

    .news-page .news-section .news-box .news-content p {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-height: 66px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .news-page .news-section .news-box .news-content a {
        color: #FF6700;
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        position: relative;
        font-weight: 500;
    }

    .news-page .news-section .news-box .news-content a:hover:before {
        width: 100px;
    }

    .news-page .news-section .news-box .news-content a:before {
        position: absolute;
        content: "";
        bottom: -3px;
        left: 0;
        width: 0;
        height: 1px;
        background: #FF6700;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    .news-page .view-footer {
        text-align: center;
    }

    .news-page .view-footer a.custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
    }

    @media (max-width:767px) {
        .news-page .view-footer a.custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .news-page .view-footer a.custom-all-btn {
            font-size: 15px;
        }
    }

    .news-page .view-footer a.custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    html[dir="rtl"] .news-page .news-section .news-box .news-content {
        right: auto;
        left: -8%;
    }

    html[dir="rtl"] .news-page .news-section .news-box .news-content a {
        font-family: 'Cairo', sans-serif;
        direction: ltr;
        display: inline-block;
    }

    html[dir="rtl"] .news-page .news-section .news-box .news-content a:hover::before {
        width: 100%;
        right: 0;
        left: auto !important;
    }

    html[dir="rtl"] .news-page .news-section .news-box .news-content a:before {
        right: 0;
        left: auto !important;
    }

    html[dir="rtl"] .news-page .view-footer a.custom-all-btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] .node--type-news #block-smsa-content .node--type-news .field--name-body p {
        text-align: right;
    }

    html[dir="rtl"] .node--type-news #block-smsa-content .node--type-news .field--type-image {
        margin: 0 0 0 4%;
    }

    .path-services .view-content .views-row {
        width: 47%;
        margin-right: 6%;
        float: left;
    }

    @media (max-width:767px) {
        .path-services .view-content .views-row {
            width: 100%;
            margin-right: 0;
        }
    }

    .path-services .view-content .views-row:nth-child(2n) {
        margin-right: 0;
    }

    .path-services .view-content .views-row:hover .service_item .service_img {
        overflow: hidden;
    }

    .path-services .view-content .views-row:hover .service_item .service_img img {
        -webkit-transform: scale(1.03);
        -moz-transform: scale(1.03);
        -ms-transform: scale(1.03);
        -o-transform: scale(1.03);
        transform: scale(1.03);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        opacity: 0.8;
        -webkit-filter: grayscale(0.5);
        -moz-filter: grayscale(0.5);
        -ms-filter: grayscale(0.5);
        -o-filter: grayscale(0.5);
        filter: grayscale(0.5);
    }

    .path-services .view-content .views-row:hover .service_item .service_text_wrap {
        margin: -120px auto 100px;
    }

    .path-services .view-content .views-row .service_item {
        position: relative;
    }

    .path-services .view-content .views-row .service_item .service_img {
        overflow: hidden;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .path-services .view-content .views-row .service_item .service_img {
            max-height: 200px;
        }
    }

    @media (max-width:1199px) {
        .path-services .view-content .views-row .service_item .service_img {
            max-height: 200px;
        }
    }

    .path-services .view-content .views-row .service_item .service_img img {
        width: 100%;
        max-height: 300px;
        overflow: hidden;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    .path-services .view-content .views-row .service_item .service_text_wrap {
        width: 90%;
        position: relative;
        top: -50%;
        background: #ffffff;
        padding: 20px;
        margin: -100px auto 80px;
        left: 0;
        right: 0;
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    .path-services .view-content .views-row .service_item .service_text_wrap .sr_heading a {
        color: #400099;
        margin-bottom: 10px;
        display: inline-block;
        font-weight: 600;
        font-size: 22px;
        font-family: "Montserrat", sans-serif;
        margin-top: 15px;
    }

    @media (max-width:767px) {
        .path-services .view-content .views-row .service_item .service_text_wrap .sr_heading a {
            font-size: 18px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .path-services .view-content .views-row .service_item .service_text_wrap .sr_heading a {
            font-size: 16px;
        }
    }

    .path-services .view-content .views-row .service_item .service_text_wrap p {
        font-size: 18px;
        color: #4D4D4F;
    }

    .path-services .view-content .views-row .service_item .service_text_wrap p a {
        font-size: 15px;
        color: #FF6700;
        display: block;
        margin: 30px 0 0px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        position: relative;
        display: block;
    }

    .path-services .view-content .views-row .service_item .service_text_wrap p a:hover:before {
        width: 100px;
    }

    .path-services .view-content .views-row .service_item .service_text_wrap p a:before {
        position: absolute;
        content: "";
        bottom: -3px;
        left: 0;
        width: 0;
        height: 1px;
        background: #FF6700;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    .path-services .view-content .views-row .service_item .service_text_wrap a {
        font-size: 15px;
        color: #FF6700;
        display: block;
        margin: 30px 0 0px;
    }

    .node--type-service .node__content h3 {
        font-size: 22px;
        margin-bottom: 20px;
        font-family: "Montserrat", sans-serif;
    }

    @media (max-width:767px) {
        .node--type-service .node__content h3 {
            font-size: 18px;
        }
    }

    .node--type-service .node__content ul {
        padding-left: 15px;
    }

    .node--type-service .node__content ul li {
        font-size: 16px;
        list-style-type: none;
        line-height: 25px;
        margin-bottom: 15px;
        position: relative;
    }

    .node--type-service .node__content ul li:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 1px;
        background: #4D4D4F;
        top: 12px;
        left: -15px;
    }

    .node--type-service .node__content img {
        height: auto;
    }

    @media (max-width:767px) {
        .node--type-service .node__content img {
            width: 100%;
            float: none !important;
            margin: 30px 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .node--type-service .node__content img {
            width: 100%;
        }
    }

    @media (max-width:1199px) {
        .node--type-service .node__content img {
            width: 100%;
        }
    }

    .node--type-service .node__content p {
        font-size: 16px;
    }

    @media (max-width:767px) {
        .node--type-service .node__content p {
            font-size: 16px !important;
        }
    }

    .node--type-service .node__content .field--name-field-content-area-a {
        padding: 43px 10px;
        margin: 50px 0;
        position: relative;
        width: 100%;
        float: left;
    }

    @media (max-width:767px) {
        .node--type-service .node__content .field--name-field-content-area-a {
            margin: 0;
        }
    }

    .node--type-service .node__content .field--name-field-content-area-a:before {
        position: absolute;
        content: "";
        right: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -9;
    }

    .node--type-service .node__content .field--name-field-content-area-a:after {
        position: absolute;
        content: "";
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -9;
    }

    .node--type-service .node__content .field--name-field-content-area-a p {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .node--type-service .node__content .field--name-field-content-area-a p:last-child {
        margin-bottom: 0;
    }

    .service-col-text1 {
        width: 100%;
        float: left;
        padding: 0 15px;
    }

    .service-col-text1.half-col {
        width: 50%;
    }

    @media (max-width:767px) {
        .service-col-text1.half-col {
            width: 100%;
        }
    }

    .service-col-img {
        width: 50%;
        margin: 0 !important;
        float: left;
    }

    @media (max-width:767px) {
        .service-col-img {
            width: 100%;
        }
    }

    .service-col-img img {
        max-width: 100%;
    }

    html[dir="rtl"] .path-services .view-content .views-row {
        margin-left: 6%;
        margin-right: 0;
    }

    html[dir="rtl"] .path-services .view-content .views-row:nth-child(2n) {
        margin-left: 0;
    }

    html[dir="rtl"] .path-services .view-content .views-row .service_text_wrap {
        text-align: right;
    }

    html[dir="rtl"] .path-services .view-content .views-row .service_text_wrap p {
        font-size: 18px;
        color: #4D4D4F;
    }

    html[dir="rtl"] .path-services .view-content .views-row .service_text_wrap p a:before {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] .path-services .view-content .views-row .service_item .service_text_wrap .sr_heading a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] .path-services .view-content .views-row .service_item .service_text_wrap p a {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] .path-services .view-content .views-row .service_item .service_text_wrap p a::before {
        position: absolute;
        content: "";
        bottom: -3px;
        left: 0;
        width: 0;
        height: 1px;
        background: #FF6700;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    html[dir="rtl"] .path-services .view-content .views-row .service_item .service_text_wrap p a:hover::before {
        width: 60px;
    }

    html[dir="rtl"] .node--type-service .node__content ul {
        padding-left: 0;
        padding-right: 15px;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .node--type-service .node__content ul {
            padding-right: 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .node--type-service .node__content ul {
            padding-right: 15px;
        }
    }

    html[dir="rtl"] .node--type-service .node__content ul li::before {
        left: auto;
        right: -15px;
    }

    html[dir="rtl"] .service-col-text1 {
        float: right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .service-col-text1 {
            padding: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .service-col-text1 {
            padding: 0;
        }
    }

    .path-industries .view-content .views-row {
        width: 22%;
        text-align: center;
        margin: 2% 1.5% 3%;
        padding: 20px 15px;
        background: #ffffff;
        -webkit-transform: 0.5s all;
        -moz-transform: 0.5s all;
        -ms-transform: 0.5s all;
        -o-transform: 0.5s all;
        transform: 0.5s all;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    @media (max-width:991px) {
        .path-industries .view-content .views-row {
            width: 30.3%;
        }
    }

    @media (max-width:767px) {
        .path-industries .view-content .views-row {
            width: 47%;
        }
    }

    @media screen and (max-width:600px) {
        .path-industries .view-content .views-row {
            width: 100%;
        }
    }

    .path-industries .view-content .views-row:hover {
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        padding: 10px 15px 30px;
        -webkit-transform: all 0.3s;
        -moz-transform: all 0.3s;
        -ms-transform: all 0.3s;
        -o-transform: all 0.3s;
        transform: all 0.3s;
        cursor: pointer;
    }

    .path-industries .view-content .views-row:hover .views-field-title a {
        color: #FF6700;
    }

    .path-industries .view-content .views-row .views-field-title a {
        color: #4D4D4F;
        font-size: 18px;
        margin-top: 30px;
        height: 60px;
        display: inline-block;
        font-weight: 500;
    }

    .path-industries .view-content .views-row .views-field-title a:hover {
        color: #FF6700;
    }

    .node--type-industries .node__content h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .node--type-industries .node__content ul {
        padding-left: 15px;
    }

    .node--type-industries .node__content ul li {
        font-size: 16px;
        list-style-type: none;
        line-height: 25px;
        margin-bottom: 15px;
        position: relative;
    }

    .node--type-industries .node__content ul li:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 1px;
        background: #4D4D4F;
        top: 12px;
        left: -15px;
    }

    @media (max-width:767px) {
        .node--type-industries .node__content img {
            width: 100%;
            float: none !important;
            margin: 30px 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .node--type-industries .node__content img {
            width: 100%;
        }
    }

    @media (max-width:1199px) {
        .node--type-industries .node__content img {
            width: 100%;
        }
    }

    .node--type-industries .node__content p {
        font-size: 16px;
    }

    @media (max-width:767px) {
        .node--type-industries .node__content p {
            font-size: 16px !important;
        }
    }

    .node--type-industries .node__content .field--name-field-content-area-a {
        padding: 43px 10px;
        margin: 50px 0;
        position: relative;
        width: 100%;
        float: left;
    }

    @media (max-width:767px) {
        .node--type-industries .node__content .field--name-field-content-area-a {
            margin: 0;
        }
    }

    .node--type-industries .node__content .field--name-field-content-area-a:before {
        position: absolute;
        content: "";
        right: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -9;
    }

    .node--type-industries .node__content .field--name-field-content-area-a:after {
        position: absolute;
        content: "";
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -9;
    }

    .node--type-industries .node__content .field--name-field-content-area-a p {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .node--type-industries .node__content .field--name-field-content-area-a p:last-child {
        margin-bottom: 0;
    }

    .node--type-industries .node__content .field--name-field-content-area-b {
        width: 100%;
        float: left;
    }

    @media (max-width:767px) {
        .node--type-industries .node__content .field--name-field-content-area-b {
            padding: 30px 0;
        }
    }

    .service-banner-image {
        display: none;
    }

    html[dir="rtl"] .node--type-industries .node__content p {
        text-align: right;
    }

    .shdw-box {
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        height: 100%;
        padding: 30px;
    }

    .shdw-box h3 {
        color: #FF5E2C;
        font-size: 22px;
        margin-bottom: 25px;
        font-family: "Montserrat", sans-serif;
    }

    @media (max-width:767px) {
        .shdw-box h3 {
            font-size: 18px;
        }
    }

    .shdw-box ul li {
        color: #4D4D4F;
        font-size: 16px;
        line-height: 40px;
        position: relative;
        font-family: "Montserrat", sans-serif;
        list-style-type: none;
    }

    .shdw-box ul li:before {
        content: '';
        position: absolute;
        left: -40px;
        top: 0;
        width: 20px;
        height: 20px;
        font-family: "icomoon" !important;
        color: #FF5E2C;
        font-size: 20px;
    }

    .shdw-box ul li:before a {
        color: #4D4D4F;
    }

    .shdw-box ul li:before a:hover {
        color: #FF5E2C;
    }

    .shdw-box ul .address-icon:before {
        content: '\e914';
    }

    .shdw-box ul .phone-icon:before {
        content: '\e915';
    }

    .shdw-box ul .fax-icon:before {
        content: '\e916';
    }

    .shdw-box ul .email-icon:before {
        content: '\e913';
        font-size: 15px;
    }

    #block-gotaquestionblock {
        float: left;
        width: 50%;
    }

    @media (max-width:767px) {
        #block-gotaquestionblock {
            width: 100%;
            float: none;
            margin-bottom: 60px;
        }
    }

    #block-gotaquestionblock .got-quote h3 {
        color: #43009B;
        font-weight: 700;
        font-size: 28px;
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 25px;
        font-family: "Montserrat", sans-serif;
    }

    @media (max-width:767px) {
        #block-gotaquestionblock .got-quote h3 {
            font-size: 18px;
        }
    }

    #block-gotaquestionblock .got-quote h3:before {
        content: "";
        position: absolute;
        width: 100px;
        height: 8px;
        border: 1px solid #707070;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 9;
    }

    #block-gotaquestionblock .got-quote h3:after {
        content: "";
        position: absolute;
        width: 280px;
        height: 1px;
        background: #707070;
        bottom: 3px;
        left: 0;
    }

    #block-gotaquestionblock .got-quote .orange-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 30px;
        font-size: 15px;
        text-transform: uppercase;
        width: auto;
        font-family: "Montserrat", sans-serif;
        margin-top: 20px;
        display: inline-block;
    }

    #block-gotaquestionblock .got-quote .orange-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
    }

    #block-contactblock {
        float: left;
        width: 50%;
        padding-left: 15px;
    }

    @media (max-width:767px) {
        #block-contactblock {
            width: 100%;
            float: none;
            padding-left: 0;
        }
    }

    #block-contactblock h2 {
        color: #43009B;
        font-weight: 700;
        font-size: 28px;
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 25px;
    }

    #block-contactblock h2:before {
        content: "";
        position: absolute;
        width: 100px;
        height: 8px;
        border: 1px solid #707070;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 9;
    }

    #block-contactblock h2:after {
        content: "";
        position: absolute;
        width: 280px;
        height: 1px;
        background: #707070;
        bottom: 3px;
        left: 0;
    }

    #block-contactblock label {
        display: none;
    }

    #block-contactblock .form-control {
        border: 0;
        border-bottom: 1px solid #E1E1E1;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    #block-contactblock #edit-subject-wrapper {
        padding: 0;
    }

    #block-contactblock #edit-message-wrapper {
        padding: 0;
    }

    #block-contactblock #edit-actions .btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 30px;
        font-size: 14px;
        text-transform: uppercase;
        width: auto;
        font-family: "Montserrat", sans-serif;
        margin: 50px 0;
    }

    #block-contactblock #edit-actions .btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
    }

    #block-contactblock #edit-preview {
        display: none;
    }

    html[dir="rtl"] .shdw-box ul li::before {
        left: auto;
        right: -40px;
    }

    html[dir="rtl"] .shdw-box h3 {
        text-align: right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #block-contactblock h2 {
            font-size: 20px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #block-contactblock h2 {
            font-size: 20px;
        }
    }

    html[dir="rtl"] #block-contactblock h2::before {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] #block-contactblock h2::after {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] #block-contactblock #edit-actions .btn {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #block-gotaquestionblock .got-quote h3::before {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] #block-gotaquestionblock .got-quote h3::after {
        left: auto;
        right: 0;
    }

    @media (max-width:767px) {
        html[dir="rtl"] #block-gotaquestionblock .got-quote h3 {
            font-size: 20px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] #block-gotaquestionblock .got-quote h3 {
            font-size: 20px;
        }
    }

    html[dir="rtl"] #block-gotaquestionblock .got-quote .orange-btn {
        font-family: 'Cairo', sans-serif;
    }

    .path-career .views-row {
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        height: 100%;
        padding: 30px;
        width: 47%;
        margin-right: 6%;
        margin-bottom: 50px;
        background: #fff;
    }

    .path-career .views-row:nth-child(2n) {
        margin-right: 0;
    }

    @media (max-width:767px) {
        .path-career .views-row {
            width: 100%;
            margin-right: 0;
        }
    }

    .path-career .views-row .views-field-title a {
        color: #43009B;
        font-size: 18px;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 20px;
        display: inline-block;
    }

    .path-career .views-row .views-field-body p {
        font-size: 16px;
    }

    .path-career .views-row .views-field-field-link a {
        color: #FF5E2C;
        font-size: 18px;
        position: relative;
    }

    .path-career .views-row .views-field-field-link a:before {
        content: '\e90a';
        position: absolute;
        right: -20px;
        top: 0px;
        width: 10px;
        height: 10px;
        font-family: "icomoon" !important;
        color: #FF5E2C;
        font-size: 10px;
    }

    html[dir="rtl"] .path-career .views-row {
        width: 47%;
        margin-left: 6%;
        margin-right: 0;
    }

    html[dir="rtl"] .path-career .views-row:nth-child(2n) {
        margin-left: 0;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .path-career .views-row {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    html[dir="rtl"] .path-career .views-row .views-field-field-link a:before {
        right: auto;
        left: -22px;
        top: 22px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .path-about-us .title {
        color: #43009B;
        font-size: 28px;
        padding: 3px 0 25px 0;
        margin-bottom: 30px;
        position: absolute;
        display: block !important;
    }

    @media (max-width:767px) {
        .path-about-us .title {
            position: relative;
        }
    }

    .path-about-us .title:before {
        content: "";
        position: absolute;
        width: 100px;
        height: 8px;
        border: 1px solid #707070;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 9;
    }

    .path-about-us .title:after {
        content: "";
        position: absolute;
        width: 280px;
        height: 1px;
        background: #707070;
        bottom: 3px;
        left: 0;
    }

    .path-about-us .tabs {
        width: 30%;
        float: left;
        padding-right: 5%;
    }

    @media (max-width:767px) {
        .path-about-us .tabs {
            width: 100%;
            margin-right: 0;
        }
    }

    .path-about-us .tabs .nav-tabs {
        border-bottom: none;
        margin-top: 100px;
    }

    @media (max-width:767px) {
        .path-about-us .tabs .nav-tabs {
            margin-top: 10px;
        }
    }

    .path-about-us .tabs .nav-tabs li {
        width: 100%;
    }

    .path-about-us .tabs .nav-tabs li .nav-link {
        border: 1px solid transparent;
        -webkit-border-top-left-radius: 0;
        -moz-border-radius-topleft: 0;
        border-top-left-radius: 0;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topright: 0;
        border-top-right-radius: 0;
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        line-height: normal;
        color: #4D4D4F;
        font-weight: 400;
        padding: 15px 10px 15px 40px;
        position: relative;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    @media (max-width:991px) {
        .path-about-us .tabs .nav-tabs li .nav-link {
            font-size: 16px;
        }
    }

    .path-about-us .tabs .nav-tabs li .nav-link:before {
        content: '\e90a';
        position: absolute;
        left: 0;
        top: 22px;
        width: 10px;
        height: 10px;
        font-family: "icomoon" !important;
        color: #4D4D4F;
        font-size: 10px;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    .path-about-us #block-smsa-content {
        float: left;
        width: 70%;
    }

    @media (max-width:767px) {
        .path-about-us #block-smsa-content {
            width: 100%;
        }
    }

    .path-about-us #block-smsa-content h2.node__title a {
        color: #43009B;
        font-size: 28px;
        margin-bottom: 30px;
        display: inline-block;
    }

    @media (max-width:767px) {
        .path-about-us #block-smsa-content h2.node__title a {
            font-size: 18px;
        }
    }

    .path-about-us #block-smsa-content p {
        font-size: 16px;
    }

    .path-about-us .nav-tabs .nav-item.show .nav-link,
    .path-about-us .nav-tabs .nav-link.active,
    .path-about-us .nav-tabs .nav-link:hover {
        background-color: #fff;
        border-color: transparent;
        background: rgba(255, 94, 44, 0.16);
        color: #FF5E2C !important;
        font-weight: 500 !important;
        padding: 15px 10px 15px 50px !important;
    }

    .path-about-us .nav-tabs .nav-link.active:before,
    .path-about-us .nav-tabs .nav-link:hover:before {
        color: #FF5E2C !important;
        left: 15px !important;
    }

    .path-about-us #main h1.title {
        display: block !important;
    }

    html[dir="rtl"] .path-about-us .tabs {
        width: 30%;
        float: right;
        padding-right: 0;
        padding-left: 5%;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .path-about-us .tabs {
            width: 100%;
        }
    }

    html[dir="rtl"] .path-about-us .tabs .nav-tabs {
        border-bottom: none;
        margin-top: 100px;
        padding-right: 0;
        text-align: right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .path-about-us .tabs .nav-tabs {
            margin-top: 10px;
        }
    }

    html[dir="rtl"] .path-about-us .tabs .nav-tabs li {
        width: 100%;
    }

    html[dir="rtl"] .path-about-us .tabs .nav-tabs li .nav-link {
        padding: 15px 40px 15px 10px;
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] .path-about-us .tabs .nav-tabs li .nav-link:before {
        left: auto;
        right: 4px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    html[dir="rtl"] .path-about-us .title:before {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] .path-about-us .title:after {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] .path-about-us .nav-tabs .nav-item.show .nav-link,
    html[dir="rtl"] .path-about-us .nav-tabs .nav-link.active,
    html[dir="rtl"] .path-about-us .nav-tabs .nav-link:hover {
        padding: 15px 50px 15px 10px !important;
    }

    html[dir="rtl"] .path-about-us .nav-tabs .nav-link.active:before,
    html[dir="rtl"] .path-about-us .nav-tabs .nav-link:hover:before {
        left: auto !important;
        right: 17px !important;
    }

    .node--type-case-studies #block-smsa-content .node--type-case-studies .field--type-image {
        margin: 0 4% 0 0;
        width: 30%;
        float: left;
    }

    @media (max-width:767px) {
        .node--type-case-studies #block-smsa-content .node--type-case-studies .field--type-image {
            width: 100%;
            float: none;
        }
    }

    .node--type-case-studies #block-smsa-content .node--type-case-studies .field--type-image img {
        width: 100%;
        height: auto;
    }

    .node--type-case-studies #block-smsa-content .node--type-case-studies .field--type-image .field__label {
        display: none;
    }

    .node--type-case-studies #block-smsa-content .node--type-case-studies .field--name-body {
        float: right;
        width: 66%;
    }

    @media (max-width:767px) {
        .node--type-case-studies #block-smsa-content .node--type-case-studies .field--name-body {
            width: 100%;
            float: none;
        }
    }

    .node--type-case-studies #block-smsa-content .node--type-case-studies .field--name-body p {
        font-size: 18px;
        font-weight: 300;
        font-family: "Montserrat", sans-serif;
        line-height: 27px;
    }

    html[dir="rtl"] .node--type-case-studies #block-smsa-content .node--type-case-studies .field--name-body {
        float: left;
    }

    html[dir="rtl"] .node--type-case-studies #block-smsa-content .node--type-case-studies .field--name-body p {
        text-align: right;
    }

    html[dir="rtl"] .node--type-case-studies #block-smsa-content .node--type-case-studies .field--type-image {
        float: right;
        margin: 0 0 0 4%;
    }

    .path-search .js-form-type-search {
        padding: 0;
        font-size: 15px;
        color: #400099;
        font-family: "Montserrat", sans-serif;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        text-align: left;
        font-size: 18px;
        margin-bottom: 50px;
    }

    @media (max-width:767px) {
        .path-search .js-form-type-search {
            margin-bottom: 0;
        }
    }

    .path-search .js-form-type-search input {
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border-bottom: 1px solid #400098;
    }

    .path-search input#edit-submit {
        background-color: #400099;
        color: #ffffff;
        border: 1px solid #400099;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        padding: 10px 20px;
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        width: 150px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        right: 0;
        top: 0px;
        background-clip: inherit;
    }

    @media (max-width:767px) {
        .path-search input#edit-submit {
            width: 100%;
            margin-bottom: 50px;
            position: static;
        }
    }

    .path-search .search-advanced {
        display: none;
    }

    .path-search .search-advanced legend span {
        color: #43009B;
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-align: center;
        margin: 15px 0 30px;
    }

    .path-search .search-help-link {
        display: none;
    }

    .path-search .node_search-results li {
        margin: 15px 0;
        border: 0;
        color: #4D4D4F;
        border: 1px solid #A0A0A0;
        padding: 20px;
        background-color: #ffffff;
    }

    .path-search .node_search-results li h3 a {
        font-size: 18px;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
    }

    .path-search .node_search-results li p {
        font-size: 16px;
    }

    .path-search .content h2 {
        color: #43009B;
        font-size: 28px;
        padding: 3px 0 25px 0;
        margin-bottom: 30px;
        position: relative;
    }

    .path-search .content h2::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 8px;
        border: 1px solid #707070;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 9;
    }

    .path-search .content h2:after {
        content: "";
        position: absolute;
        width: 280px;
        height: 1px;
        background: #707070;
        bottom: 3px;
        left: 0;
    }

    .path-search .block-system-breadcrumb-block nav {
        z-index: 99;
        text-align: right;
        width: 100%;
        float: left;
        padding: 20px;
    }

    .path-search .block-system-breadcrumb-block .breadcrumb {
        font-size: 1rem;
        position: static;
        float: right;
    }

    .path-search .search-form .container-inline {
        padding: 0;
        text-align: center;
    }

    .alert-wrapper {
        position: relative !important;
    }

    html[dir="rtl"] .path-search .js-form-type-search input {
        padding-right: 170px;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .path-search .js-form-type-search input {
            padding: 15px;
        }
    }

    html[dir="rtl"] .path-search .content h2::before {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] .path-search .content h2::after {
        left: auto;
        right: 0;
    }

    html[dir="rtl"] .path-search .node_search-results {
        padding: 0;
    }

    html[dir="rtl"] .alert-dismissible .close {
        left: 0;
        right: auto;
    }

    html[dir="rtl"] .path-search input#edit-submit {
        left: 0;
        right: auto;
    }

    html[dir="rtl"] .path-search .js-form-type-search {
        text-align: right;
    }

    #main-wrapper #block-smsa-content .node__content h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        position: relative;
        display: inline-block;
        padding-bottom: 6px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    #main-wrapper #block-smsa-content .node__content h3 {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        color: #4D4D4F;
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 17px;
    }

    @media (max-width:767px) {
        #main-wrapper #block-smsa-content .node__content h3 {
            font-size: 14px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #main-wrapper #block-smsa-content .node__content h3 {
            font-size: 16px !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #main-wrapper #block-smsa-content .node__content h3 {
            font-size: 16px !important;
        }
    }

    #main-wrapper #block-smsa-content .node__content p {
        font-family: "Montserrat", sans-serif;
    }

    #main-wrapper #block-smsa-content .node__content h5 {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        font-size: 15px;
        font-weight: 500;
        color: #FF6700;
    }

    @media (max-width:767px) {
        #main-wrapper #block-smsa-content .node__content h5 {
            font-size: 14px !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #main-wrapper #block-smsa-content .node__content h5 {
            font-size: 16px !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #main-wrapper #block-smsa-content .node__content h5 {
            font-size: 16px !important;
        }
    }

    #main-wrapper #block-smsa-content .node__content ul li {
        font-family: "Montserrat", sans-serif;
    }

    @media (max-width:767px) {
        #main-wrapper #block-smsa-content .node__content ul li {
            font-size: 14px;
            line-height: 26px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        #main-wrapper #block-smsa-content .node__content ul li {
            font-size: 16px !important;
            line-height: 26px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        #main-wrapper #block-smsa-content .node__content ul li {
            font-size: 16px !important;
            line-height: 26px;
        }
    }

    #main-wrapper #block-smsa-content .node__content ol {
        list-style-type: none;
        counter-reset: li;
    }

    #main-wrapper #block-smsa-content .node__content ol li {
        counter-increment: li;
        position: relative;
    }

    #main-wrapper #block-smsa-content .node__content ol li::before {
        display: inline-block;
        width: 0;
        margin-left: -20px;
        position: absolute;
        font-weight: 600;
        content: counter(li) ".";
        left: -15px;
        right: auto;
        top: 0;
        color: #4D4D4F;
    }

    .common-internal-page .node__content {
        font-size: 16px;
    }

    .common-internal-page h1,
    .common-internal-page h2,
    .common-internal-page h3,
    .common-internal-page h4,
    .common-internal-page h5,
    .common-internal-page h6 {
        font-family: "Montserrat", sans-serif;
        color: #43009B;
        line-height: normal;
        margin: 15px 0 20px;
    }

    .common-internal-page img {
        width: 100%;
    }

    .common-internal-page ol {
        padding-left: 15px;
        list-style-type: none;
    }

    .common-internal-page ul {
        padding-left: 15px;
        list-style-type: none;
    }

    .common-internal-page li {
        font-size: 16px;
        list-style-type: none;
        line-height: 25px;
        margin-bottom: 15px;
        position: relative;
    }

    .common-internal-page li:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 1px;
        background: #4D4D4F;
        top: 12px;
        left: -15px;
    }

    .node__content img {
        width: auto;
        max-width: 100%;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #8BA4BB;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #8BA4BB;
    }

    .mCSB_inside>.mCSB_container {
        margin-right: 8px;
    }

    .mCSB_scrollTools .mCSB_draggerContainer {
        left: auto;
        right: 0;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList {
        font-size: 12px;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList ul {
        position: absolute;
        width: 50%;
        background: #FAFAFA;
        margin-top: 5px;
        border: 1px solid #DEDEDE;
        text-align: left;
        z-index: 99;
        left: auto;
        right: 10px;
        bottom: 20px;
        padding-left: 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        overflow-y: auto;
        max-height: 150px;
        height: auto;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList ul::-webkit-scrollbar {
        width: 3px;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList ul::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList ul::-webkit-scrollbar-thumb {
        background: #888;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList ul::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList ul .mCSB_inside>.mCSB_container {
        margin-right: 8px;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList ul li {
        list-style: none;
        cursor: pointer;
        line-height: 25px;
        padding-left: 10px;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList ul li:hover {
        background-color: #e2e0e0;
        color: #FF6700;
        color: #FF6700;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList .mCSB_scrollTools .mCSB_draggerContainer {
        left: auto;
        right: 0;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList .selectedOption {
        margin-left: 0;
        margin-top: 2px;
        cursor: pointer;
        height: 20px;
        position: relative;
        text-align: left;
        padding-left: 30px;
        font-weight: 600;
        line-height: 1;
    }

    @media (max-width:767px) {
        .shipping_tracking .shipping-rate-enquiry form .scrollableList .selectedOption {
            padding-left: 0;
            text-align: center;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .scrollableList .selectedOption {
            padding-left: 0;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList .selectedOption::before {
        position: absolute;
        top: 2px;
        right: 14px;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: bold;
        pointer-events: none;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping_tracking .shipping-rate-enquiry form .scrollableList .selectedOption::before {
            right: 0;
            left: auto;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping_tracking .shipping-rate-enquiry form .scrollableList .selectedOption::before {
            right: -6px;
        }
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList .selectedOption.select-up-arrow::before {
        position: absolute;
        left: auto;
        content: "\e91d";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: bold;
        pointer-events: none;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList .selectedOption:hover {
        color: #333;
    }

    .shipping_tracking .shipping-rate-enquiry form .scrollableList.active-arrow-up .selectedOption::before {
        content: "\e91d";
        position: absolute;
        top: 2px;
        right: 14px;
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: bold;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList {
        font-size: 12px;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul {
        position: absolute;
        width: 50%;
        background: #FAFAFA;
        margin-top: 5px;
        border: 1px solid #DEDEDE;
        text-align: left;
        z-index: 99;
        left: auto;
        right: 10px;
        top: 20px;
        padding-left: 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        overflow-y: auto;
        max-height: 150px;
        height: auto;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul::-webkit-scrollbar {
        width: 3px;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul::-webkit-scrollbar-thumb {
        background: #888;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul .mCSB_inside>.mCSB_container {
        margin-right: 8px;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul li {
        list-style: none;
        cursor: pointer;
        line-height: 25px;
        padding-left: 10px;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul li:hover {
        background-color: #e2e0e0;
        color: #FF6700;
        color: #FF6700;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList .mCSB_scrollTools .mCSB_draggerContainer {
        left: auto;
        right: 0;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption {
        margin-left: 0;
        margin-top: 2px;
        cursor: pointer;
        height: 20px;
        position: relative;
        text-align: left;
        padding-left: 30px;
        font-weight: 600;
        line-height: 1;
    }

    @media (max-width:767px) {
        .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption {
            padding-left: 0;
            text-align: center;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption {
            padding-left: 0;
        }
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption::before {
        position: absolute;
        top: 2px;
        right: 14px;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: bold;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption::before {
            right: 0;
            left: auto;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption::before {
            right: -6px;
        }
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption:hover {
        color: #333;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList.active-arrow-up .selectedOption::before {
        content: "\e91d";
        position: absolute;
        top: 2px;
        right: 14px;
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: bold;
    }

    .form-control:focus {
        border-color: #b277ff;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(178, 199, 255, 0.25);
        -moz-box-shadow: 0 0 0 0.2rem rgba(178, 199, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(178, 199, 255, 0.25);
    }

    .no-results {
        font-size: 20px;
        margin: 20px;
        text-align: center;
        color: #FF6700;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
    }

    .pagination .page-item .page-link {
        color: #fc6719;
        border: 1px solid #fc6719;
        margin: 0 5px;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
    }

    .hidemeplease {
        display: none;
    }

    .ajax-progress.ajax-progress-fullscreen {
        background-color: rgba(0, 0, 0, 0.7);
        height: 100vh;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0;
    }

    .ajax-throbber.sk-folding-cube .sk-cube:before {
        background-color: #fff;
    }

    .bootstrap-select .dropdown-menu.show {
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
        top: 100% !important;
    }

    .page-node-90 .layout-main-wrapper #block-smsa-content h3 a {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        color: #43009B !important;
        font-size: 20px;
        line-height: 1.4;
    }

    html[dir="rtl"] .pagination .page-item .page-link {
        color: #fc6719;
        border: 1px solid #fc6719;
        margin: 0 5px;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
    }

    html[dir="rtl"] .no-results {
        font-size: 20px;
        margin: 20px;
        text-align: center;
        color: #FF6700;
        font-family: "Tajawal", sans-serif;
        font-weight: 600;
    }

    html[dir="rtl"] #main-wrapper #block-smsa-content .node__content ul li {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] #main-wrapper #block-smsa-content .node__content ol li::before {
        right: -15px;
        left: auto;
    }

    html[dir="rtl"] button,
    html[dir="rtl"] input,
    html[dir="rtl"] optgroup,
    html[dir="rtl"] select,
    html[dir="rtl"] textarea {
        font-family: 'Cairo', sans-serif !important;
    }

    html[dir="rtl"] article,
    html[dir="rtl"] aside,
    html[dir="rtl"] figcaption,
    html[dir="rtl"] figure,
    html[dir="rtl"] footer,
    html[dir="rtl"] header,
    html[dir="rtl"] hgroup,
    html[dir="rtl"] main,
    html[dir="rtl"] nav,
    html[dir="rtl"] section {
        text-align: right;
    }

    html[dir="rtl"] .common-internal-page li::before {
        right: -15px;
        left: auto;
    }

    html[dir="rtl"] .scrollableList ul {
        padding-right: 0;
        left: 20px;
        right: auto;
        text-align: right;
    }

    html[dir="rtl"] .scrollableList ul .mCSB_inside>.mCSB_container {
        margin-right: 8px;
        margin-left: 20px;
    }

    html[dir="rtl"] .mCS-dir-rtl>.mCSB_inside>.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
        margin-left: 8px;
    }

    html[dir="rtl"] .scrollableList .mCSB_scrollTools .mCSB_draggerContainer {
        left: 0;
        right: auto;
    }

    html[dir="rtl"] .selectedOption {
        text-align: right !important;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .selectedOption {
            text-align: center !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .selectedOption {
            text-align: right !important;
        }
    }

    html[dir="rtl"] .selectedOption::after {
        left: 23px;
        right: auto;
    }

    html[dir="rtl"] .selectedOption::before {
        top: 4px;
        left: 14px;
        right: auto !important;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .selectedOption::before {
            left: 0;
            right: auto !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .selectedOption::before {
            right: auto !important;
            left: 0 !important;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .selectedOption::before {
            right: auto !important;
            left: 0 !important;
        }
    }

    @media screen and (min-width:1200px) and (max-width:1495px) {
        html[dir="rtl"] .selectedOption::before {
            right: auto !important;
            left: 14px !important;
        }
    }

    html[dir="rtl"] .scrollableList ul li {
        padding: 0 10px;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form label {
            left: 0;
            right: 0;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form label {
            left: auto;
            right: 30px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .shipping_tracking .shipping-rate-enquiry form label {
            left: 45px;
        }
    }

    html[dir="rtl"] .bootstrap-select .dropdown-menu li a {
        text-align: right;
    }

    html[dir="rtl"] .ajax-progress-fullscreen {
        right: 0 !important;
        left: 49% !important;
    }

    .map-location-filter {
        background-color: #ffffff;
        padding: 25px;
        border: 1px solid #B277FF;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-filter {
            text-align: center;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .map-location-filter {
            text-align: center;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .map-location-filter {
            text-align: center;
        }
    }

    .map-location-filter h3 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        text-transform: uppercase;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-filter h3 {
            font-size: 20px;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .map-location-filter h3 {
            font-size: 20px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .map-location-filter h3 {
            font-size: 20px;
        }
    }

    .map-location-filter p {
        color: #4D4D4F;
        font-size: 20px;
        font-family: "Montserrat", sans-serif;
        margin: 0;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-filter p {
            margin: 10px;
            font-size: 12px;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .map-location-filter p {
            margin: 10px;
            font-size: 12px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .map-location-filter p {
            margin: 10px;
            font-size: 12px;
        }
    }

    .map-location-filter form.location-filter-form {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-filter form.location-filter-form {
            display: block;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .map-location-filter form.location-filter-form {
            display: block;
        }
    }

    .map-location-filter form.location-filter-form .ajax-progress {
        position: absolute;
        right: 0;
        bottom: -30px;
        font-size: 1rem;
    }

    .map-location-filter form.location-filter-form fieldset {
        -ms-flex: 0 0 35%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 35%;
        -moz-box-flex: 0;
        flex: 0 0 35%;
        max-width: 35%;
        margin: 0;
        margin-right: 1%;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-filter form.location-filter-form fieldset {
            display: block;
            width: 100%;
            -webkit-box-flex: unset;
            -webkit-flex: unset;
            -moz-box-flex: unset;
            -ms-flex: unset;
            flex: unset;
            max-width: 100%;
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .map-location-filter form.location-filter-form fieldset {
            display: block;
            width: 100%;
            -webkit-box-flex: unset;
            -webkit-flex: unset;
            -moz-box-flex: unset;
            -ms-flex: unset;
            flex: unset;
            max-width: 100%;
            margin-bottom: 10px;
        }
    }

    .map-location-filter form.location-filter-form fieldset .bootstrap-select {
        width: 100% !important;
    }

    .map-location-filter form.location-filter-form fieldset .bootstrap-select button.dropdown-toggle {
        background-color: transparent;
        height: auto;
        line-height: 15px;
        outline: 0 !important;
        border: 1px solid #9e9aa5;
    }

    .map-location-filter form.location-filter-form fieldset .bootstrap-select button.dropdown-toggle::after {
        position: absolute;
        left: auto;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #000f;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    .map-location-filter form.location-filter-form fieldset .bootstrap-select button.dropdown-toggle:focus {
        outline: 0 !important;
    }

    .map-location-filter form.location-filter-form fieldset .bootstrap-select.show button.dropdown-toggle::after {
        position: absolute;
        left: auto;
        content: "\e91d";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    .map-location-filter form.location-filter-form fieldset .bootstrap-select .dropdown-menu li a.dropdown-item.active {
        color: #888787;
        background-color: #e6e6e6;
    }

    .map-location-filter form.location-filter-form fieldset .bootstrap-select .dropdown-menu li a.dropdown-item:hover {
        color: #888787;
        background-color: #e6e6e6;
    }

    .map-location-filter form.location-filter-form fieldset .bootstrap-select .dropdown-menu li a.dropdown-item:focus {
        color: #888787;
        background-color: #e6e6e6;
    }

    .map-location-filter form.location-filter-form fieldset select {
        width: 100%;
        padding: 8px 5px;
        background: transparent;
        background-color: #fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 0;
        color: #4D4D4F;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul {
        width: 90%;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList ul li {
        font-family: "Montserrat", sans-serif !important;
        font-weight: lighter !important;
        font-size: 15px !important;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption {
        font-family: "Montserrat", sans-serif !important;
        font-weight: lighter !important;
        font-size: 15px !important;
        color: #4D4D4F !important;
    }

    .map-location-filter form.location-filter-form fieldset .scrollableList .selectedOption::before {
        top: 5px !important;
    }

    .map-location-filter form.location-filter-form .get-cities {
        -ms-flex: 0 0 35%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 35%;
        -moz-box-flex: 0;
        flex: 0 0 35%;
        max-width: 35%;
        margin: 0;
        margin-right: 1%;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-filter form.location-filter-form .get-cities {
            -ms-flex: 0 0 100%;
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .map-location-filter form.location-filter-form .get-cities {
            -ms-flex: 0 0 100%;
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    .map-location-filter form.location-filter-form .get-cities fieldset {
        ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        margin-right: 1%;
    }

    .map-location-filter form.location-filter-form input.button {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
        margin-right: 0;
        padding: 8px 0;
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        font-size: 13px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        text-transform: uppercase;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-filter form.location-filter-form input.button {
            display: block;
            width: 100%;
            -webkit-box-flex: unset;
            -webkit-flex: unset;
            -moz-box-flex: unset;
            -ms-flex: unset;
            flex: unset;
            max-width: 100%;
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .map-location-filter form.location-filter-form input.button {
            display: block;
            width: 100%;
            -webkit-box-flex: unset;
            -webkit-flex: unset;
            -moz-box-flex: unset;
            -ms-flex: unset;
            flex: unset;
            max-width: 100%;
            margin-bottom: 10px;
        }
    }

    .map-location-filter form.location-filter-form input.button:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
    }

    .map-location-filter form.location-filter-form input.button:last-child {
        margin-right: 0;
    }

    .map-location-filter form.location-filter-form input.button:active {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
    }

    .map-location-filter .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
        line-height: 30px;
    }

    .map-location-content ul {
        border-bottom: 0;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 30px 0;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-content ul {
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .map-location-content ul {
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
    }

    .map-location-content ul li a {
        color: #FF6700;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 10px;
        padding: 10px 70px;
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .map-location-content ul li a {
            padding: 10px 20px;
        }
    }

    .map-location-content ul li a.active {
        background-color: #FF6700;
        color: #ffffff;
    }

    .map-location-content ul li a.active a {
        color: #ffffff;
    }

    .map-location-content ul li:first-child a {
        -webkit-border-top-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        border-top-left-radius: 10px;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topright: 0;
        border-top-right-radius: 0;
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-bottom-left-radius: 10px;
        -webkit-border-bottom-right-radius: 0;
        -moz-border-radius-bottomright: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    .map-location-content ul li:last-child a {
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-topright: 10px;
        border-top-right-radius: 10px;
        -webkit-border-top-left-radius: 0;
        -moz-border-radius-topleft: 0;
        border-top-left-radius: 0;
        -webkit-border-bottom-right-radius: 10px;
        -moz-border-radius-bottomright: 10px;
        border-bottom-right-radius: 10px;
        -webkit-border-bottom-left-radius: 0;
        -moz-border-radius-bottomleft: 0;
        border-bottom-left-radius: 0;
    }

    .map-location-content .tab-content {
        width: 100%;
    }

    .map-location-content .tab-content .tab-pane {
        overflow-y: auto;
        max-height: 650px;
        height: 100%;
        padding: 10px;
        border: 1px solid #B277FF;
    }

    @media (max-width:575px) {
        .map-location-content .tab-content .tab-pane {
            max-height: 840px;
            height: 100%;
        }

        .map-location-content .tab-content .tab-pane #map {
            height: 700px !important;
        }
    }

    .map-location-content .tab-content .tab-pane table {
        border-collapse: separate;
        border-spacing: 0 1em;
        padding: 0 15px;
        margin: 0;
    }

    .map-location-content .tab-content .tab-pane table thead th {
        border-bottom: 2px solid #ededed;
        background-color: #ededed;
        border: 0;
        font-family: "Montserrat", sans-serif;
        color: #4D4D4F;
    }

    .map-location-content .tab-content .tab-pane table thead th.th-address {
        width: 100%;
    }

    .map-location-content .tab-content .tab-pane table tr {
        background: none;
    }

    .map-location-content .tab-content .tab-pane table tr td {
        border: 0;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        font-family: "Montserrat", sans-serif;
        color: #4D4D4F;
        line-height: 2;
        padding: 20px 15px;
    }

    .map-location-content .tab-content .tab-pane table tr td.center-address {
        width: 30%;
        word-break: break-all;
    }

    .map-location-content .tab-content .tab-pane table tr td.center-distance span {
        border: 1px solid #43009B;
        padding: 5px;
        color: #43009B;
        font-weight: 500;
    }

    .map-location-content .tab-content .tab-pane table tr td:first-child {
        border-left: 1px solid #ddd !important;
        border-right: 0 !important;
        -webkit-border-top-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-left-radius: 5px;
    }

    .map-location-content .tab-content .tab-pane table tr td:last-child {
        border-right: 1px solid #ddd !important;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-bottomright: 5px;
        border-bottom-right-radius: 5px;
    }

    .map-location-content .tab-content ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        overflow: visible;
    }

    .map-location-content .tab-content ::-webkit-scrollbar-button {
        height: 0;
        width: 0;
    }

    .map-location-content .tab-content ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px grey;
        box-shadow: inset 0 0 5px grey;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    .map-location-content .tab-content ::-webkit-scrollbar-track:horizontal {
        border-width: 7px 0 0;
    }

    .map-location-content .tab-content ::-webkit-scrollbar-thumb {
        background: #000;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    .map-location-content .tab-content ::-webkit-scrollbar-thumb:hover {
        background: #898989;
    }

    .map-location-content .tab-content #map .gm-style .gm-style-iw-c {
        padding: 0 !important;
        max-height: none !important;
        -webkit-transform: translate(-50%, -35%) !important;
        -moz-transform: translate(-50%, -35%) !important;
        -ms-transform: translate(-50%, -35%) !important;
        -o-transform: translate(-50%, -35%) !important;
        transform: translate(-50%, -35%) !important;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .map-location-content .tab-content #map .gm-style .gm-style-iw-c {
            max-height: none !important;
            -webkit-transform: translate(-50%, -25%) !important;
            -moz-transform: translate(-50%, -25%) !important;
            -ms-transform: translate(-50%, -25%) !important;
            -o-transform: translate(-50%, -25%) !important;
            transform: translate(-50%, -25%) !important;
            max-height: 800px !important;
        }
    }

    @media (max-width:767px) {
        .map-location-content .tab-content #map .gm-style .gm-style-iw-c {
            max-height: none !important;
            -webkit-transform: translate(-50%, -25%) !important;
            -moz-transform: translate(-50%, -25%) !important;
            -ms-transform: translate(-50%, -25%) !important;
            -o-transform: translate(-50%, -25%) !important;
            transform: translate(-50%, -25%) !important;
        }
    }

    @media (max-width:575px) {
        .map-location-content .tab-content #map .gm-style .gm-style-iw-c {
            max-width: 285px !important;
        }
    }

    .map-location-content .tab-content #map .gm-style .gm-style-iw-c button.gm-ui-hover-effect {
        top: 6px !important;
        right: 0 !important;
    }

    .map-location-content .tab-content #map .gm-style .gm-style-iw-d {
        overflow: auto !important;
        overflow-y: hidden !important;
        padding: 12px 0 0;
        background-color: rgba(64, 0, 153, 0.7) !important;
        background-image: url(https://uat.smsaexpress.com/smsa/web/modules/custom/smsa_location/img/contactusbanner@3x.png);
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position: center;
        max-height: 800px !important;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .map-location-content .tab-content #map .gm-style .gm-style-iw-d {
            max-height: 800px !important;
        }
    }

    @media (max-width:767px) {
        .map-location-content .tab-content #map .gm-style .gm-style-iw-d {
            max-height: 650px !important;
        }
    }

    .map-location-content .tab-content #map .gm-style .gm-style-iw-t::after {
        background: -webkit-linear-gradient(45deg, rgba(64, 0, 153, 0.2) 50%, rgba(64, 0, 153, 0.2) 51%, rgba(64, 0, 153, 0.2) 100%) !important;
        background: -moz-linear-gradient(45deg, rgba(64, 0, 153, 0.2) 50%, rgba(64, 0, 153, 0.2) 51%, rgba(64, 0, 153, 0.2) 100%) !important;
        background: -o-linear-gradient(45deg, rgba(64, 0, 153, 0.2) 50%, rgba(64, 0, 153, 0.2) 51%, rgba(64, 0, 153, 0.2) 100%) !important;
        background: linear-gradient(45deg, rgba(64, 0, 153, 0.2) 50%, rgba(64, 0, 153, 0.2) 51%, rgba(64, 0, 153, 0.2) 100%) !important;
        -webkit-box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4) !important;
        -moz-box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4) !important;
        box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4) !important;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
        width: 0 !important;
        height: 0 !important;
        border-left: 10px solid transparent !important;
        border-right: 10px solid transparent !important;
        border-top: 13px solid rgba(64, 0, 153, 0.4) !important;
        display: none;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .map-location-content .tab-content #map .gm-style .gm-style-iw-t::after {
            display: none;
        }
    }

    @media (max-width:767px) {
        .map-location-content .tab-content #map .gm-style .gm-style-iw-t::after {
            display: none;
        }
    }

    @media (max-width:575px) {
        .map-location-content .tab-content #map .gm-style .gm-style-iw-t::after {
            display: none;
        }
    }

    .info-window {
        padding: 3px 0 8px 0;
        max-height: 200px;
        overflow-y: auto;
        max-height: 800px !important;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .info-window {
            max-height: 800px !important;
        }
    }

    @media (max-width:767px) {
        .info-window {
            max-height: 650px !important;
        }
    }

    .info-window .map-store-details {
        padding: 0 10px;
    }

    .info-window .today-open-hours {
        background-color: #fff;
        padding: 8px 15px;
        position: relative;
    }

    .info-window .today-open-hours p {
        color: #000;
        font-weight: 500;
        font-size: 14px;
    }

    .info-window .today-open-hours span {
        color: #400099;
        font-size: 14px;
        font-weight: 500;
        padding-left: 5px;
    }

    .info-window .today-open-hours a {
        position: absolute;
        content: "";
        top: 6px;
        right: 10px;
    }

    .info-window .today-open-hours a img {
        opacity: 0.2;
    }

    .info-window .show-view-more-data a#view-more-time {
        padding: 0 10px;
        font-size: 16px;
        line-height: 2;
        color: #FF6700;
        text-transform: uppercase;
        font-weight: 400;
    }

    .info-window .show-view-more-data a#view-less-time {
        background-color: #fff;
        padding: 0 10px;
        font-size: 15px;
        line-height: 2;
        color: #FF6700;
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: 400;
    }

    .info-window .map-time-hour {
        background-color: #fff;
        padding: 0 10px;
    }

    .info-window .map-time-hour span {
        font-size: 17px;
        font-weight: 400;
        color: #400099;
        line-height: 2;
    }

    .info-window .map-time-hour p.outlet.timing {
        margin-bottom: 4px;
        line-height: 2;
        font-size: 12px !important;
        font-weight: 500;
        border-bottom: 1px solid #ccc;
        padding: 5px 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        color: #000;
    }

    .info-window .map-time-hour p.outlet.timing:last-child {
        border-bottom: 0;
        margin-bottom: 0;
    }

    .info-window .map-time-hour p.outlet.timing span {
        color: #000;
        font-size: 12px;
        font-weight: 500;
    }

    .info-window .map-time-hour p.outlet.timing span.days {
        width: 25%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0 15px 0 5px;
    }

    .info-window .map-time-hour p.outlet.timing span.times {
        width: 75%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 5px 0 15px;
    }

    .info-window .map-content {
        background-color: #fff;
        padding: 10px;
    }

    .info-window h4 {
        font-size: 14px;
        line-height: 1.5;
        color: #fff;
        font-weight: 600;
    }

    .info-window h4 span {
        font-size: 18px;
        font-weight: 600;
        display: block;
        color: #fff;
    }

    .info-window h5 {
        font-size: 14px;
        line-height: 1.5;
        color: #FF6700;
    }

    .info-window h5 span {
        font-size: 18px;
        font-weight: 600;
        display: block;
        color: #43009B;
    }

    .user-current-location {
        padding: 0 15px 10px !important;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .user-current-location span.user-current-address {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        width: 260px;
    }

    html[dir="rtl"] .map-location-content ul li:first-child a {
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-topright: 10px;
        border-top-right-radius: 10px;
        -webkit-border-top-left-radius: 0;
        -moz-border-radius-topleft: 0;
        border-top-left-radius: 0;
        -webkit-border-bottom-right-radius: 10px;
        -moz-border-radius-bottomright: 10px;
        border-bottom-right-radius: 10px;
        -webkit-border-bottom-left-radius: 0;
        -moz-border-radius-bottomleft: 0;
        border-bottom-left-radius: 0;
        border: 1px solid #FF6700;
    }

    html[dir="rtl"] .map-location-content ul li:last-child a {
        -webkit-border-top-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        border-top-left-radius: 10px;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topright: 0;
        border-top-right-radius: 0;
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-bottom-left-radius: 10px;
        -webkit-border-bottom-right-radius: 0;
        -moz-border-radius-bottomright: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    html[dir="rtl"] .map-location-content .tab-content .tab-pane table thead th {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] .map-location-content .tab-content .tab-pane table tbody td {
        font-family: 'Cairo', sans-serif;
    }

    html[dir="rtl"] .map-location-content .tab-content .tab-pane table tr td:first-child {
        border-right: 1px solid #ddd !important;
        border-left: 0 !important;
    }

    html[dir="rtl"] .map-location-content .tab-content .tab-pane table tr td:last-child {
        border-left: 1px solid #ddd !important;
        border-right: 0 !important;
    }

    html[dir="rtl"] .map-location-filter form.location-filter-form fieldset .bootstrap-select button.dropdown-toggle .filter-option {
        float: right;
        left: auto;
        right: 0;
        text-align: right;
    }

    html[dir="rtl"] .map-location-filter form.location-filter-form fieldset .bootstrap-select button.dropdown-toggle::after {
        left: 15px;
        right: auto;
    }

    html[dir="rtl"] .map-location-filter form.location-filter-form .dropdown-menu.show {
        z-index: 99999;
        min-width: 100% !important;
    }

    html[dir="rtl"] .map-location-filter form.location-filter-form .ajax-progress {
        position: absolute;
        right: auto;
        left: 0;
        bottom: -30px;
        font-size: 1rem;
    }

    @media only screen and (max-width:767px) {
        .table-responsive {
            -webkit-overflow-scrolling: touch !important;
        }

        .table-responsive .table {
            max-width: none;
        }
    }

    a.my_tracking_text {
        cursor: pointer;
        color: #dc3545 !important;
        font-size: 14px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        padding: 2px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    a.my_tracking_text:hover {
        color: #43009B !important;
    }

    a.my_tracking_text:focus {
        outline: 2px solid #BAD8FC !important;
    }

    .my_tracking_form form.awb-tracking-form {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    @media (max-width:767px) {
        .my_tracking_form form.awb-tracking-form {
            display: block;
        }
    }

    .my_tracking_form form.awb-tracking-form fieldset {
        width: 60%;
        padding-right: 0;
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .my_tracking_form form.awb-tracking-form fieldset {
            width: 70%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .my_tracking_form form.awb-tracking-form fieldset {
            width: 70%;
        }
    }

    @media (max-width:767px) {
        .my_tracking_form form.awb-tracking-form fieldset {
            width: 100%;
            padding-left: 0;
        }
    }

    .my_tracking_form form.awb-tracking-form fieldset label {
        font-size: 14px;
    }

    .my_tracking_form form.awb-tracking-form fieldset .form-textarea-wrapper {
        width: 100%;
    }

    .my_tracking_form form.awb-tracking-form fieldset .form-textarea-wrapper textarea#edit-awb {
        height: 50px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        line-height: 2;
        padding: 10px 15px;
        background-color: #e0e0e0;
        border: 0;
    }

    .my_tracking_form form.awb-tracking-form fieldset small {
        color: #6c757d !important;
    }

    .my_tracking_form form #edit-actions {
        width: 15%;
        padding-left: 0;
        margin-bottom: 6px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: 0;
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .my_tracking_form form #edit-actions {
            width: 30%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .my_tracking_form form #edit-actions {
            width: 30%;
        }
    }

    @media (max-width:767px) {
        .my_tracking_form form #edit-actions {
            width: 100%;
            padding-right: 0;
        }
    }

    .my_tracking_form form #edit-actions input#edit-submit {
        height: 50px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: 0;
        background-color: #FC6719;
    }

    .tracking-box-info {
        padding: 10px 20px 5px;
        width: 100%;
        background-color: #43009B;
        margin-bottom: 50px;
    }

    .tracking-box-info .row {
        margin: 8px;
    }

    @media (max-width:767px) {
        .tracking-box-info .row {
            margin: 8px -15px;
        }
    }

    @media (max-width:767px) {
        .tracking-box-info .row .text-center {
            text-align: left !important;
        }
    }

    .tracking-box-info h2 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        color: #ffffff;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .tracking-box-info ul li {
        list-style-type: none;
        display: inline-block;
    }

    .tracking-box-info span {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        color: #FF6700;
        font-weight: 600;
    }

    .tracking-box-info p {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        color: #ffffff;
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 600;
    }

    .tracking-box-info .tracking-border-bottom {
        border-bottom: 1px solid #cccccc;
    }

    .tracking-box-info .last-bold-fnt p {
        font-size: 20px;
    }

    ol.progress[data-steps="2"] li {
        width: 49%;
    }

    @media (max-width:767px) {
        ol.progress[data-steps="2"] li {
            width: 100%;
        }
    }

    ol.progress[data-steps="3"] li {
        width: 33%;
    }

    @media (max-width:767px) {
        ol.progress[data-steps="3"] li {
            width: 100%;
        }
    }

    ol.progress[data-steps="4"] li {
        width: 25%;
    }

    @media (max-width:767px) {
        ol.progress[data-steps="4"] li {
            width: 100%;
        }
    }

    ol.progress[data-steps="5"] li {
        width: 19%;
    }

    @media (max-width:767px) {
        ol.progress[data-steps="5"] li {
            width: 100%;
        }
    }

    ol.progress[data-steps="6"] li {
        width: 16%;
    }

    @media (max-width:767px) {
        ol.progress[data-steps="6"] li {
            width: 100%;
        }
    }

    ol.progress[data-steps="7"] li {
        width: 14%;
    }

    @media (max-width:767px) {
        ol.progress[data-steps="7"] li {
            width: 100%;
        }
    }

    ol.progress[data-steps="8"] li {
        width: 12%;
    }

    @media (max-width:767px) {
        ol.progress[data-steps="8"] li {
            width: 100%;
        }
    }

    ol.progress[data-steps="9"] li {
        width: 11%;
    }

    @media (max-width:767px) {
        ol.progress[data-steps="9"] li {
            width: 100%;
        }
    }

    .progress {
        width: 100%;
        list-style: none;
        list-style-image: none;
        margin: auto;
        padding: 0;
        overflow: visible;
        display: block;
        height: 0;
        font-size: unset;
        background-color: transparent;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    .progress li {
        float: left;
        text-align: center;
        position: relative;
    }

    @media (max-width:767px) {
        .progress li {
            margin-bottom: 34px;
        }
    }

    .progress li img {
        max-width: 100%;
        display: none;
    }

    .progress li .name {
        display: block;
        vertical-align: bottom;
        text-align: center;
        margin-bottom: 1em;
        color: #FF6700;
    }

    @media (max-width:767px) {
        .progress li .name {
            display: none;
        }
    }

    .progress li .step {
        color: #FF6700;
        border: 3px solid #FF6700;
        background-color: #ffffff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -moz-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        z-index: 0;
    }

    .progress li .step::before {
        content: "";
        display: block;
        background-color: #FF6700;
        height: 0.4em;
        width: 50%;
        position: absolute;
        bottom: 60%;
        -webkit-transform: translateY(-60%);
        -moz-transform: translateY(-60%);
        -ms-transform: translateY(-60%);
        -o-transform: translateY(-60%);
        transform: translateY(-60%);
        left: 0;
        z-index: -1;
    }

    @media (max-width:767px) {
        .progress li .step::before {
            height: 50%;
            width: 10px;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            left: 49%;
            top: -50%;
        }
    }

    .progress li .step::after {
        content: "";
        display: block;
        background-color: #FF6700;
        height: 0.4em;
        width: 50%;
        position: absolute;
        bottom: 60%;
        -webkit-transform: translateY(-60%);
        -moz-transform: translateY(-60%);
        -ms-transform: translateY(-60%);
        -o-transform: translateY(-60%);
        transform: translateY(-60%);
        right: 0;
        z-index: -1;
    }

    @media (max-width:767px) {
        .progress li .step::after {
            display: none;
        }
    }

    .progress li:first-of-type .step::before {
        display: none;
    }

    .progress li:last-of-type .step::after {
        display: none;
    }

    .progress li.active .name {
        opacity: 1;
        color: #FF6700;
    }

    .progress li.active .step {
        border: 3px solid #FF6700;
        background-color: #ffffff;
        color: #FF6700;
    }

    .progress li.active .step span {
        opacity: 1;
    }

    .progress li.active .step::before {
        background-color: #43009B;
    }

    .progress li.done .name {
        color: #43009B;
    }

    .progress li.done .step {
        border: 3px solid #43009B;
        background-color: #ffffff;
        color: #43009B;
    }

    .progress li.done .step::before {
        background-color: #43009B;
    }

    .progress li.done .step::after {
        background-color: #43009B;
    }

    .progress li.done.picked-up::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e91f";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #43009B;
    }

    .progress li.done.in-transit::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e922";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #43009B;
    }

    .progress li.done.out-delivery::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e921";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #43009B;
    }

    .progress li.done.delivered::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e920";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #43009B;
    }

    .progress li.active.picked-up::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e91f";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #FF6700;
    }

    .progress li.active.in-transit::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e922";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #FF6700;
    }

    .progress li.active.out-delivery::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e921";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #FF6700;
    }

    .progress li.active.delivered::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e920";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #FF6700;
    }

    .progress li.picked-up::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e91f";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #FF6700;
    }

    .progress li.in-transit::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e922";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #FF6700;
    }

    .progress li.out-delivery::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e921";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #FF6700;
    }

    .progress li.delivered::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font-family: "icomoon" !important;
        content: "\e920";
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        margin: 0 auto;
        color: #FF6700;
    }

    .tracking-timeline {
        width: 80%;
        margin: 30px auto;
        border-bottom: 1px solid #ccc;
        padding: 30px 0;
    }

    @media (max-width:767px) {
        .tracking-timeline {
            width: 100%;
        }
    }

    .tracking-timeline:last-child {
        border-bottom: 0;
    }

    .tracking-timeline h3 {
        margin: 20px auto;
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        color: #4D4D4F;
    }

    .tracking-timeline .tracking-details-container {
        position: relative;
    }

    .tracking-timeline .tracking-details-container::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 28%;
        width: 0;
        border-left: 2px dashed #cccccc;
        border-left-width: medium;
        height: 100%;
        z-index: 1;
        -webkit-transform: translateX(-28%);
        -moz-transform: translateX(-28%);
        -ms-transform: translateX(-28%);
        -o-transform: translateX(-28%);
        transform: translateX(-28%);
    }

    @media (max-width:767px) {
        .tracking-timeline .tracking-details-container::before {
            display: none;
        }
    }

    .tracking-timeline .tracking-details-container .timeline-image {
        position: relative;
        z-index: 100;
    }

    .tracking-timeline .tracking-details-container .timeline-image::before {
        content: ' ';
        background: #ffffff;
        display: inline-block;
        position: absolute;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 3px solid #43009B;
        top: 50%;
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -ms-transform: translate(-50%);
        -o-transform: translate(-50%);
        transform: translate(-50%);
        left: 42%;
        width: 20px;
        height: 20px;
        z-index: 400;
    }

    @media (max-width:767px) {
        .tracking-timeline .tracking-details-container .timeline-image::before {
            display: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .tracking-timeline .tracking-details-container .timeline-image::before {
            left: 48%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .tracking-timeline .tracking-details-container .timeline-image::before {
            left: 45%;
        }
    }

    .tracking-timeline .tracking-details-container .timeline-image.active::before {
        background-color: #43009B;
        border: 4px double #ffffff;
    }

    .tracking-timeline .tracking-details-container .date-wrap h4 {
        font-size: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #43009B;
        line-height: 30px;
        color: #FF6700;
        font-weight: 600;
        margin-bottom: 0;
    }

    @media (max-width:767px) {
        .tracking-timeline .tracking-details-container .date-wrap h4 {
            font-size: 20px;
        }
    }

    @media (max-width:1199px) {
        .tracking-timeline .tracking-details-container .date-wrap h4 {
            font-size: 18px;
        }
    }

    .tracking-timeline .tracking-details-container .date-wrap span {
        color: #000;
    }

    .tracking-timeline .tracking-details-container .tracking-details {
        background-color: #d1d0d2;
        padding: 20px;
    }

    @media (max-width:767px) {
        .tracking-timeline .tracking-details-container .tracking-details {
            margin: 10px auto;
        }
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap {
        margin-bottom: 25px;
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap h4 {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        color: #000;
        font-weight: 700;
    }

    @media (max-width:767px) {
        .tracking-timeline .tracking-details-container .tracking-details .trk-wrap h4 {
            font-size: 15px;
        }
    }

    @media (max-width:1199px) {
        .tracking-timeline .tracking-details-container .tracking-details .trk-wrap h4 {
            font-size: 15px;
        }
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content {
        width: 80%;
        display: inline-block;
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-left {
        float: left;
        width: 30%;
        padding-left: 24px;
    }

    @media (max-width:767px) {
        .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-left {
            width: 100%;
            float: none;
            padding-left: 20px;
        }
    }

    @media (max-width:1199px) {
        .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-left {
            width: 100%;
            float: none;
            padding-left: 20px;
        }
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-left span {
        font-size: 16px;
        position: relative;
        line-height: 20px;
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-left span::before {
        content: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/watch.png);
        position: absolute;
        width: 15px;
        height: 15px;
        left: -24px;
        right: 0;
        top: 0;
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-right {
        float: right;
        width: 70%;
        padding-left: 40px;
    }

    @media (max-width:767px) {
        .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-right {
            width: 100%;
            float: none;
            padding-left: 20px;
            margin-top: 15px;
        }
    }

    @media (max-width:1199px) {
        .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-right {
            width: 100%;
            float: none;
            padding-left: 20px;
            margin-top: 15px;
        }
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-right span {
        font-size: 16px;
        position: relative;
        line-height: 20px;
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-right span::before {
        content: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/location.png);
        position: absolute;
        width: 15px;
        height: 15px;
        left: -24px;
        right: 0;
        top: 0;
    }

    .tracking-timeline .tracking-details-container .tracking-details .trk-wrap:last-child {
        margin-bottom: 0;
    }

    html[dir="rtl"] .tracking-timeline .tracking-details-container::before {
        left: auto;
        right: 28%;
    }

    html[dir="rtl"] .tracking-timeline .tracking-details-container .timeline-image::before {
        left: 0;
        right: 5%;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .tracking-timeline .tracking-details-container .timeline-image::before {
            left: 0;
            right: -40%;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        html[dir="rtl"] .tracking-timeline .tracking-details-container .timeline-image::before {
            left: 0;
            right: -8%;
        }
    }

    html[dir="rtl"] .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-left {
        padding-left: 0;
        padding-right: 40px;
    }

    html[dir="rtl"] .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-left span::before {
        left: 0;
        right: -24px;
    }

    html[dir="rtl"] .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-right {
        padding-left: 0;
        padding-right: 40px;
    }

    html[dir="rtl"] .tracking-timeline .tracking-details-container .tracking-details .trk-wrap .trk-wrap-content .trk-wrap-content-right span::before {
        left: 0;
        right: -24px;
    }

    html[dir="rtl"] .progress li {
        float: right;
    }

    html[dir="rtl"] .progress li:before {
        -webkit-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    html[dir="rtl"] .progress li .step::after {
        left: 0;
        right: auto;
    }

    html[dir="rtl"] .progress li .step::before {
        left: auto;
        right: 0;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .progress li .step::before {
            height: 50%;
            width: 10px;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            left: 49%;
            top: -50%;
            right: auto;
        }
    }

    html[dir="rtl"] .my_tracking_form form.awb-tracking-form fieldset {
        padding-right: 15px;
        padding-left: 0;
    }

    html[dir="rtl"] .my_tracking_form form #edit-actions {
        padding-left: 15px;
        padding-right: 0;
    }

    .shipping-details .shipping-info {
        background-color: #ffffff;
        padding: 15px;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
    }

    .shipping-details .shipping-info .shipping-country-details {
        display: block;
        margin: -15px -15px 15px;
        padding: 15px;
        background: #43009B;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form h3 {
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form img {
        display: none;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form .form-row>div {
        -webkit-box-flex: 1;
        -webkit-flex: auto;
        -moz-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form .form-row label {
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 6px;
        color: #ffffff;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form .form-row .submit-btn {
        margin-top: 30px;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form .form-row .submit-btn button {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        padding: 5px 20px;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form .form-row .submit-btn button:hover {
        background-color: #ffffff;
        color: #FF6700;
        border: 1px solid #FF6700;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset {
        width: 49%;
        float: left;
        display: block;
        margin-right: 1%;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset {
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset {
            padding: 0 5px;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset:last-child {
        margin-right: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset label {
        color: #fff;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select {
        width: 100% !important;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select button.dropdown-toggle {
        padding: 9px 15px !important;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select button.dropdown-toggle::after {
        position: absolute;
        left: auto;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #000f;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select button.dropdown-toggle:focus {
        outline: 0 !important;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select.show button.dropdown-toggle::after {
        position: absolute;
        left: auto;
        content: "\e91d";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select .dropdown-menu li a.dropdown-item.active {
        color: #888787;
        background-color: #e6e6e6;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select .dropdown-menu li a.dropdown-item:hover {
        color: #888787;
        background-color: #e6e6e6;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select .dropdown-menu li a.dropdown-item:focus {
        color: #888787;
        background-color: #e6e6e6;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .scrollableList .selectedOption {
        color: #fff;
        padding-left: 0;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .scrollableList .selectedOption {
            text-align: left;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .scrollableList .selectedOption::before {
        color: #fff;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .scrollableList ul {
        width: 90%;
        left: auto;
        right: auto;
        z-index: 999;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .form-text {
        margin-top: 0;
        width: 100%;
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/weight.png);
        background-position: 5% 50%;
        background-repeat: no-repeat;
        -moz-background-size: auto;
        -o-background-size: auto;
        -webkit-background-size: auto auto;
        background-size: auto;
        border: 1px solid #B277FF;
        padding: 10px 10px 10px 40px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        margin: 0 auto;
        background-color: #fff;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type {
        width: 20%;
        float: left;
        position: relative;
        margin-top: 2px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type {
            width: 46%;
            float: none;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type {
            width: 46%;
            float: none;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type {
            width: 35%;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check {
        width: 49%;
        float: left;
        margin-right: 1%;
        margin-top: 5px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check {
            padding: 0;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check:focus {
        outline: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check:last-child {
        margin-right: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check label {
        position: absolute;
        left: auto;
        right: auto;
        bottom: 4px;
        text-align: center;
        margin: 0 auto;
        font-size: 12px;
        text-transform: capitalize;
        cursor: pointer;
        width: 50px;
        color: #4D4D4F;
        padding: 0;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check label {
            right: 10px;
            display: block;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check label {
            right: 18px;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check label:focus {
        outline: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check input {
        width: 50px;
        height: 30px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        -webkit-appearance: none;
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        position: relative;
        display: block;
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check input {
            width: 50%;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check input:focus {
        outline: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check input::before {
        border: 1px solid transparent;
        position: absolute;
        width: 50px;
        height: 30px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        left: 0;
        background-color: #F9F9F9;
        cursor: pointer;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        color: #A0A0A0;
        line-height: 25px;
        content: "";
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check input::before {
            width: 50px;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check input[type="radio"]:checked::before {
        background-color: #c8b2e6;
        border: 1px solid #c8b2e6;
        cursor: pointer;
        color: #A686D2;
        text-align: center;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions {
        width: 30%;
        float: left;
        display: block;
        margin-bottom: 0;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions {
            width: 100% !important;
            float: left;
            display: block;
            margin: 15px auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions {
            width: auto;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions {
            width: 27%;
            float: right;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions .custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
        padding: 10px 0;
        margin-top: 0;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions .custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions .custom-all-btn {
            font-size: 15px;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions .custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions .custom-all-btn:hover {
        background-color: #fff;
        border: 1px solid #FF6700;
        color: #FF6700;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset {
        width: 49%;
        float: left;
        display: block;
        margin-right: 1%;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset {
            width: 100%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset {
            padding: 0 5px;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .inner.show {
        overflow-x: auto;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .dropdown-menu.show {
        z-index: 99999;
        width: 100% !important;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
        top: 100% !important;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset:last-child {
        margin-right: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset label {
        color: #fff;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select {
        width: 100% !important;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select button.dropdown-toggle {
        padding: 9px 15px !important;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select button.dropdown-toggle::after {
        position: absolute;
        left: auto;
        content: "\e909";
        font-family: "icomoon" !important;
        color: #000f;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select button.dropdown-toggle:focus {
        outline: 0 !important;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select.show button.dropdown-toggle::after {
        position: absolute;
        left: auto;
        content: "\e91d";
        font-family: "icomoon" !important;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        border: 0;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin: 0 auto;
        text-align: center;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select .dropdown-menu li a.dropdown-item.active {
        color: #888787;
        background-color: #e6e6e6;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select .dropdown-menu li a.dropdown-item:hover {
        color: #888787;
        background-color: #e6e6e6;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select .dropdown-menu li a.dropdown-item:focus {
        color: #888787;
        background-color: #e6e6e6;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .scrollableList .selectedOption {
        color: #fff;
        padding-left: 0;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .scrollableList .selectedOption {
            text-align: left;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .scrollableList .selectedOption::before {
        color: #fff;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .scrollableList ul {
        width: 90%;
        left: auto;
        right: auto;
        z-index: 999;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .form-text {
        margin-top: 0;
        width: 100%;
        background: url(https://www.smsaexpress.com/themes/custom/smsa/app/images/weight.png);
        background-position: 5% 50%;
        background-repeat: no-repeat;
        -moz-background-size: auto;
        -o-background-size: auto;
        -webkit-background-size: auto auto;
        background-size: auto;
        border: 1px solid #B277FF;
        padding: 10px 10px 10px 40px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        margin: 0 auto;
        background-color: #fff;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type {
        width: 20%;
        float: left;
        position: relative;
        margin-top: 2px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type {
            width: 46%;
            float: none;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type {
            width: 46%;
            float: none;
            margin: 0 auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type {
            width: 35%;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check {
        width: 49%;
        float: left;
        margin-right: 1%;
        margin-top: 5px;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check {
            padding: 0;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check:focus {
        outline: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check:last-child {
        margin-right: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check label {
        position: absolute;
        left: auto;
        right: auto;
        bottom: 4px;
        text-align: center;
        margin: 0 auto;
        font-size: 12px;
        text-transform: capitalize;
        cursor: pointer;
        width: 50px;
        color: #4D4D4F;
        padding: 0;
    }

    @media screen and (min-width:320px) and (max-width:575px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check label {
            right: 10px;
            display: block;
        }
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check label {
            right: 18px;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check label:focus {
        outline: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check input {
        width: 50px;
        height: 30px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        -webkit-appearance: none;
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        position: relative;
        display: block;
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check input {
            width: 50%;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check input:focus {
        outline: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check input::before {
        border: 1px solid transparent;
        position: absolute;
        width: 50px;
        height: 30px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        left: 0;
        background-color: #F9F9F9;
        cursor: pointer;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        color: #A0A0A0;
        line-height: 25px;
        content: "";
    }

    @media screen and (min-width:575px) and (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check input::before {
            width: 50px;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check input[type="radio"]:checked::before {
        background-color: #c8b2e6;
        border: 1px solid #c8b2e6;
        cursor: pointer;
        color: #A686D2;
        text-align: center;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions {
        width: 30%;
        float: left;
        display: block;
        margin-bottom: 0;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions {
            width: 100% !important;
            float: left;
            display: block;
            margin: 15px auto;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions {
            width: auto;
            margin-left: -12px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions {
            width: 27%;
            float: right;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions .custom-all-btn {
        background-color: #FF6700;
        color: #ffffff;
        border: 1px solid #FF6700;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 10px 70px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        z-index: 9;
        overflow: hidden;
        font-weight: 500;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, #FF6700), color-stop(50%, #400099));
        background: -webkit-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -moz-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: -o-linear-gradient(right, #FF6700 50%, #400099 50%);
        background: linear-gradient(to left, #FF6700 50%, #400099 50%);
        -webkit-background-size: 200% 100%;
        -moz-background-size: 200% 100%;
        -o-background-size: 200% 100%;
        background-size: 200% 100%;
        background-position: right bottom;
        padding: 10px 0;
        margin-top: 0;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions .custom-all-btn {
            font-size: 14px;
        }
    }

    @media screen and (min-width:992px) and (max-width:1024px) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions .custom-all-btn {
            font-size: 15px;
        }
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions .custom-all-btn:hover {
        background-color: #43009B;
        color: #ffffff;
        border: 1px solid #43009B;
        background-position: left bottom;
        -webkit-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        -moz-box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        box-shadow: 5px 5px 10px rgba(64, 0, 153, 0.3);
        border: 0;
    }

    .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions .custom-all-btn:hover {
        background-color: #fff;
        border: 1px solid #FF6700;
        color: #FF6700;
    }

    .shipping-details .shipping-info .delivery-details {
        margin-top: 40px;
    }

    .shipping-details .shipping-info .delivery-details h3 {
        font-size: 18px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #43009B;
        line-height: 22px;
        color: #4D4D4F;
        font-weight: 500;
        padding: 0 20px 10px;
    }

    .shipping-details .shipping-info .delivery-details .delivery-details__options .form-check {
        padding: 0 20px;
    }

    .shipping-details .shipping-info .delivery-details .delivery-details__options .form-check label {
        width: 100%;
    }

    .shipping-details .shipping-info .delivery-details .delivery-details__options label {
        position: relative;
        cursor: pointer;
        color: #4D4D4F;
        font-size: 16px;
        width: 100%;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .delivery-details .delivery-details__options label {
            font-size: 12px;
            padding: 0;
        }
    }

    .shipping-details .shipping-info .delivery-details .delivery-details__options input[type="radio"] {
        position: absolute;
        right: 9000px;
        left: auto;
    }

    .shipping-details .shipping-info .delivery-details .delivery-details__options input[type="radio"]+div.active::before {
        content: "\f10c";
        font-family: "FontAwesome";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-right: 5px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-size: 20px;
    }

    .shipping-details .shipping-info .delivery-details .delivery-details__options input[type="radio"]:checked+div.active {
        background: #FF6700;
        color: #fff;
        border-color: #FF6700;
    }

    .shipping-details .shipping-info .delivery-details .delivery-details__options input[type="radio"]:checked+div.active::before {
        content: "\f192";
        color: #fff;
        -webkit-animation: effect 250ms ease-in;
        -moz-animation: effect 250ms ease-in;
        -o-animation: effect 250ms ease-in;
        animation: effect 250ms ease-in;
        vertical-align: middle;
    }

    .shipping-details .shipping-info .delivery-details .delivery-details__options .active {
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 8px 5px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 15px;
        color: #4D4D4F;
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 10px 15px;
        margin-bottom: 30px;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .delivery-details .shipping_heading {
            display: block;
        }
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .country_from {
        font-size: 25px;
        color: #FF6700;
        margin: 0 10px;
        line-height: 1.2;
        text-align: center;
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .country_from span {
        display: block;
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .city_from {
        font-size: 25px;
        color: #FF6700;
        margin: 0 10px;
        line-height: 1.2;
        text-align: center;
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .ship_icon {
        position: relative;
        width: 50px;
        height: 25px;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .delivery-details .shipping_heading .ship_icon {
            margin: 0 auto;
        }
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .ship_icon::after {
        position: absolute;
        left: 0;
        font-family: "icomoon" !important;
        content: "\2192";
        right: 0;
        text-align: center;
        top: -5px;
        font-size: 50px;
        color: #FF6700;
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .ship_icon_right {
        position: relative;
        width: 50px;
        height: 25px;
    }

    @media (max-width:767px) {
        .shipping-details .shipping-info .delivery-details .shipping_heading .ship_icon_right {
            margin: 0 auto;
        }
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .ship_icon_right::after {
        position: absolute;
        left: 0;
        font-family: "icomoon" !important;
        content: "\2190";
        right: 0;
        text-align: center;
        top: -5px;
        font-size: 50px;
        color: #FF6700;
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .country_to {
        font-size: 25px;
        color: #FF6700;
        margin: 0 10px;
        line-height: 1.2;
        text-align: center;
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .country_to span {
        display: block;
    }

    .shipping-details .shipping-info .delivery-details .shipping_heading .city_to {
        font-size: 25px;
        color: #FF6700;
        margin: 0 10px;
        line-height: 1.2;
        text-align: center;
    }

    .shipping-details .shipping-country-map {
        margin: -15px -15px 15px;
        padding: 15px;
    }

    .shipping-details .shipping-charges-details-summary {
        background-color: #ffffff;
        padding: 15px;
        -moz-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        -webkit-box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        box-shadow: 0 38px 48px rgba(185, 132, 255, 0.1);
        font-size: 15px;
        z-index: 999;
        width: 100%;
        -webkit-transition: 0.4s all;
        -o-transition: 0.4s all;
        -moz-transition: 0.4s all;
        transition: 0.4s all;
    }

    .smsaappcss {
        width: 100%;
        background-repeat: no-repeat;
        background-image: url("https://www.smsaexpress.com/modules/custom/smsa_widget/img/smsaappimage_eng.jpg");
    }

    @media (max-width:767px) {
        .shipping-details .shipping-charges-details-summary {
            margin-top: 15px;
            margin: 0 auto;
            width: 100%;
        }
    }

    @media (max-width:767px) {
        .shipping-details .shipping-charges-details-summary.shrink {
            margin-top: 15px;
        }
    }

    .shipping-details .shipping-charges-details-summary .shipping-charges-details-summary--content h3 {
        color: #4D4D4F;
        font-size: 18px;
    }

    .shipping-details .shipping-charges-details-summary .shipping-charges-details-summary--content p {
        color: #4D4D4F;
        font-size: 15px;
        line-height: 20px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }

    .shipping-details .shipping-rates-details {
        max-height: 180px;
        overflow-y: auto;
        height: 100%;
        padding: 10px 0;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .form-text {
        padding-left: 0;
        padding-right: 40px;
        background-position: 96% 50%;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry fieldset .bootstrap-select button.dropdown-toggle::after {
        left: 15px;
        right: auto;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry .scrollableList .selectedOption {
        text-align: right;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry .scrollableList .selectedOption::before {
        left: 0;
        right: auto;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type {
            width: 45%;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type {
            float: right;
            margin-bottom: 20px;
        }
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions {
        float: right;
        padding: 0;
        width: 25%;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions .custom-all-btn {
            width: 100% !important;
            padding: 10px 0 !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-actions .custom-all-btn {
            padding: 10px !important;
        }
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset {
        float: right;
        margin-right: 0;
        margin-left: 1%;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset {
            margin-bottom: 10px;
        }
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset:last-child {
        margin-right: 0;
        margin-left: 0;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .form-text {
        padding-left: 0;
        padding-right: 40px;
        background-position: 96% 50%;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main fieldset .bootstrap-select button.dropdown-toggle::after {
        left: 15px;
        right: auto;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main .scrollableList .selectedOption {
        text-align: right;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main .scrollableList .selectedOption::before {
        left: 0;
        right: auto;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type {
        float: right;
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type {
            width: 45%;
            float: none;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type {
            float: right;
            margin-bottom: 20px;
        }
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check {
        float: right;
        margin-right: 0;
        margin-top: 5px;
        margin-left: 1%;
        padding-left: 0;
        padding-right: 0.5rem;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check:last-child {
        margin-right: 0;
        margin-left: 0;
    }

    html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions {
        float: left;
        padding: 0;
        width: 25%;
    }

    @media (min-width:992px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions {
            margin-right: 0;
            margin-left: 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions {
            width: auto;
            float: right;
            margin-right: 0;
            display: block;
            margin-left: 0;
        }
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions .custom-all-btn {
            width: 100% !important;
            padding: 10px 0 !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-actions .custom-all-btn {
            padding: 10px !important;
        }
    }

    @media (max-width:767px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
            text-align: right !important;
        }
    }

    html[dir="rtl"] .shipping-details .shipping-info .delivery-details .delivery-details__options input[type="radio"] {
        position: absolute;
        left: 9000px;
        right: auto;
    }

    @media only screen and (min-device-width:320px) and (max-device-width:568px) and (-webkit-min-device-pixel-ratio:2) {
        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check label {
            right: 0;
            display: block;
            left: 0;
            margin: 0 auto;
            width: 100%;
        }

        .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check label {
            right: 0;
            display: block;
            left: 0;
            margin: 0 auto;
            width: 100%;
        }
    }

    @media screen and (min-width:515px) and (max-width:767px) {
        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check input {
            width: 50%;
            display: inline-block;
            vertical-align: middle;
        }

        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check input {
            width: 50%;
            display: inline-block;
            vertical-align: middle;
        }

        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type .form-check {
            margin-right: 0;
            margin-left: 5%;
            padding-right: 0;
            padding-left: 0;
            text-align: center;
        }

        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type .form-check {
            margin-right: 0;
            margin-left: 5%;
            padding-right: 0;
            padding-left: 0;
            text-align: center;
        }

        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry #edit-weight-type {
            float: left;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
        }

        html[dir="rtl"] .shipping-details .shipping-info .shipping-country-details .book-shippment-form form#shipping-rate-enquiry-main #edit-weight-type {
            float: none;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            padding-right: 1.5rem;
        }
    }

    .express-section {
        margin: -15px;
        position: relative;
    }

    .express-section::before {
        content: "";
        background-color: #400099;
        height: 200px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;
    }

    .express-section>.row {
        margin-left: 0;
        margin-right: 0;
    }

    .express-section__content {
        width: 97%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        background-color: #fff;
        height: auto;
        margin: 15px auto;
        -webkit-box-shadow: 0px 18px 36px 9px rgba(64, 0, 153, 0.1);
        -moz-box-shadow: 0px 18px 36px 9px rgba(64, 0, 153, 0.1);
        box-shadow: 0px 18px 36px 9px rgba(64, 0, 153, 0.1);
        padding: 15px 0;
        background-image: url(https://uat.smsaexpress.com/sites/default/files/map001.png);
        background-repeat: no-repeat;
        -webkit-background-size: 80% 80%;
        -moz-background-size: 80%;
        -o-background-size: 80%;
        background-size: 80%;
        background-position: 200px 50px;
        height: 560px;
        overflow-y: auto;
    }

    @media (max-width:991px) {
        .express-section__content {
            display: block;
            background-image: url(https://uat.smsaexpress.com/sites/default/files/map001.png);
            background-repeat: no-repeat;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            -o-background-size: contain;
            background-size: contain;
            background-position: center;
        }
    }

    .express-section__content .express-bg {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%;
        display: none;
    }

    @media (max-width:991px) {
        .express-section__content .express-bg {
            display: none;
        }
    }

    .express-section__content .express-bg img {
        max-width: 76% !important;
        opacity: 0.5;
        width: 100% !important;
    }

    .express-section__content .express-box {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        height: 100%;
    }

    @media (max-width:991px) {
        .express-section__content .express-box {
            display: block;
        }
    }

    .express-section__content h2 {
        font-size: 16px !important;
        font-family: "Montserrat", sans-serif;
        font-weight: 600 !important;
        color: #4D4D4F !important;
        line-height: 30px !important;
        text-align: left !important;
        margin-bottom: 15px !important;
        text-transform: capitalize !important;
        border-bottom: 2px solid #FC6719;
        width: 100%;
    }

    @media (max-width:991px) {
        .express-section__content h2 {
            margin-top: 20px;
        }
    }

    .express-section__content ul {
        list-style: none;
        padding-left: 20px !important;
    }

    .express-section__content ul li {
        line-height: 1.2 !important;
        font-size: 14px !important;
        font-family: "Montserrat", sans-serif !important;
        color: #1d2124;
        position: relative !important;
        margin-bottom: 12px !important;
        font-weight: 500;
    }

    .express-section__content ul li a {
        font-size: 14px;
        color: #1d2124;
    }

    .express-section__content ul li::before {
        content: '';
        position: absolute;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        width: 5px !important;
        height: 5px !important;
        top: 11px !important;
        left: -20px !important;
        -webkit-transform: translateY(-50%) rotate(-45deg);
        -moz-transform: translateY(-50%) rotate(-45deg);
        -ms-transform: translateY(-50%) rotate(-45deg);
        -o-transform: translateY(-50%) rotate(-45deg);
        transform: translateY(-50%) rotate(-45deg);
        background: transparent !important;
    }

    .express-section__img {
        width: 100%;
    }

    @media (max-width:991px) {
        .express-section__img {
            margin: 0 !important;
        }
    }

    html[dir="rtl"] .express-section__content .express-box h2 {
        text-align: right !important;
    }

    html[dir="rtl"] .express-section__content ul li::before {
        -webkit-transform: translateY(-50%) rotate(135deg);
        -moz-transform: translateY(-50%) rotate(135deg);
        -ms-transform: translateY(-50%) rotate(135deg);
        -o-transform: translateY(-50%) rotate(135deg);
        transform: translateY(-50%) rotate(135deg);
    }
}

.order-track {
    border-top: 1px dashed #2c3e50;
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
}

.order-track-step {
    display: flex;
    height: 7rem;
}

.order-track-step:last-child {
    overflow: hidden;
    height: 4rem;
}

.order-track-step:last-child .order-track-status span:last-of-type {
    display: none;
}

.order-track-status {
    margin-right: 1.5rem;
    position: relative;
}

.order-track-status-dot {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background: #7368df;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: auto;
    font-weight: 800;
}

.order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #7368df;
}

.order-track-text-stat {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 3px;
}

.order-track-text-sub {
    font-size: 1rem;
    font-weight: 300;
}

.order-track {
    transition: all .3s height 0.3s;
    transform-origin: top center;
}

.boxBarcodeShipping {
    width: 100%;
    height: 100px;
    border: 1px dashed #6868aa;
    background: url(https://www.kindpng.com/picc/m/612-6126364_mailer-box-icon-hd-png-download.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 10px;

    a {
        margin: 2px;
        background: #6868aaa8;
        margin-top: 60px;
        width: 96%;
        font-size: 22px;
        padding: 0;
        color: #fff !important;
    }

    .numberBoxLength {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 26px;
        border: 1px solid #fefeff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        text-align: center;
        background: #6868abc7;
        color: #fff;
        padding-top: 7px;
        -webkit-box-shadow: 1px 1px 4px #393997;
        box-shadow: 1px 1px 4px #393997;
    }
}





.batchNumberBarcode {
    position: absolute;
    top: 0px;
    left: 81px;
    width: 100px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #fb0;
    -webkit-box-shadow: 1px 1px 8px #fb0;
    box-shadow: 1px 1px 8px #fb0;
    background: #fb0;
    color: #000;
    font-family: sans-serif;
    font-size: 17px;
}


.kivenView {
    width: 100%;
    height: 70vh;

    #connectContainer label {
        font-size: .9em;
        font-weight: 400;
        margin-left: 10px;
        text-align: left
    }

    #Login {
        background-color: rgba(0, 0, 0, .85);
        height: 100vh;
        position: absolute;
        text-align: center;
        width: 100vw;
        z-index: 2
    }

    .closePath {
        fill: #fff
    }

    #connectContainer {
        background-color: var(--dark-bg-color) !important;
        border: 1px solid #fff;
        border-radius: 20px !important;
        box-shadow: var(--dark-shadow);
        color: var(--main-txt-color) !important;
        margin-top: 50vh;
        min-width: 350px;
        padding: 20px 30px 60px !important;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    #connectContainer>section>div {
        display: block
    }

    #rememberMe input {
        height: auto !important;
        margin: 0 auto 40px
    }

    #toggleDisplayPass {
        cursor: pointer;
        float: right;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin-right: 40px
    }

    #loginBtn {
        margin-top: 50px
    }

    #closeBtn {
        height: 30px;
        padding: 5px !important;
        position: absolute;
        right: 10px;
        text-align: center;
        width: 30px
    }

    #closeBtn:hover {
        cursor: pointer
    }

    #connectContainer button {
        background-color: #1a4f92;
        border: 1px solid #add8e6;
        border-radius: 10px;
        color: #fff;
        display: block;
        margin: 50px auto 0;
        padding: 20px;
        position: relative;
        width: 100%
    }

    #UserStatus {
        background-color: green;
        left: 25%;
        position: absolute;
        text-align: center;
        top: auto;
        width: 50%
    }

    #newUser {
        text-align: left
    }

    #newUser>span>a {
        color: #fff;
        text-align: left
    }

    #connectContainer input {
        border: none;
        border-radius: 10px;
        padding: 15px !important
    }

    #connectContainer>section>div>* {
        display: block;
        width: 100%
    }

    #connectContainer>section>div>h1 {
        margin-bottom: 20px
    }

    #connectContainer>section>div>label {
        margin-bottom: 5px
    }

    #connectContainer>section>div>input#password {
        padding-right: 30px !important
    }

    .st0 {
        fill-rule: evenodd;
        fill: #80bc00
    }

    .st0,
    .st1 {
        clip-rule: evenodd
    }

    .st1 {
        fill-rule: evenodd;
        fill: #d9d8d6
    }

    .st2 {
        fill-rule: evenodd;
        fill: #1ecad3
    }

    .st2,
    .st3 {
        clip-rule: evenodd
    }

    .st3 {
        fill-rule: evenodd;
        fill: #b6dce1
    }

    .st4 {
        fill-rule: evenodd;
        fill: #194f90
    }

    .st4,
    .st5 {
        clip-rule: evenodd
    }

    .st5 {
        fill-rule: evenodd;
        fill: #3a8dde
    }

    .st6 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: url(#SVGID_1_);
        opacity: .5
    }

    #chatBox {
        display: block;
        margin-right: 10px;
        position: relative
    }

    #chatBox>iframe {
        border: 0;
        height: calc(100vh - 150px);
        width: 100%
    }

    .specsMobile {
        padding: 0 17px
    }

    .specsMobile>header>h3 {
        text-align: left;
        width: 100%
    }

    .specsMobile>header>h3>span {
        font-size: 14px
    }

    .specsMobile>.flexContainer>.flexItem>h4 {
        margin-bottom: 12px
    }

    .specsMobile>header {
        border-bottom: var(--spec-mobile-border);
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        padding-bottom: 15px
    }

    .specsMobile>header>.SpecificationMember>div {
        background: var(--light-bg-color);
        border-radius: 5px;
        cursor: pointer;
        padding: 2px 5px;
        text-align: center
    }

    .specsMobile>header>.SpecificationMember>div:last-child {
        margin-top: 10px
    }

    .specsMobile>.flexContainer {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 280px);
        justify-content: normal;
        overflow: auto
    }

    .specsMobile>.flexContainer>.flexItem>.containerSpec {
        display: flex
    }

    .specsMobile>.flexContainer .labels {
        font-size: 9px;
        margin-top: 16px;
        min-width: 62px;
        width: 62px
    }

    .specsMobile>.flexContainer .labels>.labelItem {
        align-items: center;
        display: flex;
        height: 15px;
        letter-spacing: -.3px;
        overflow: hidden;
        white-space: nowrap
    }

    .flexContainer>.flexItem:first-child {
        margin-top: 20px
    }

    .flexContainer>.flexItem:first-child .containerTable>table {
        margin-bottom: 20px
    }

    .specsMobile>div.flexContainer>div>div>div {
        width: 100%
    }

    .specsMobile>div.flexContainer>div>div>div>table {
        border-spacing: 0;
        font-size: 9px;
        margin: auto;
        width: 99%
    }

    .specsMobile>.flexContainer>.flexItem:first-child>.containerSpec>.containerTable>table {
        width: calc(60% - 2px)
    }

    .specsMobile>.flexContainer>.flexItem {
        margin-bottom: 25px
    }

    .specsMobile>.flexContainer>.flexItem.arcade table {
        margin-bottom: 10px
    }

    .specsMobile>.flexContainer>.flexItem:last-child {
        margin-bottom: 0
    }

    .specsMobile table>tbody>tr>td,
    .specsMobile table>thead>tr>th {
        border-left: var(--spec-mobile-border);
        height: 15px;
        letter-spacing: -.3px;
        min-height: 15px;
        min-width: 20px;
        text-align: center
    }

    .specsMobile table>thead>tr:first-child>th {
        border-bottom: var(--spec-mobile-border);
        border-top: var(--spec-mobile-border)
    }

    .specsMobile table>tbody>tr:last-child>td {
        border-bottom: var(--spec-mobile-border)
    }

    .specsMobile table>tbody>tr>td>.token {
        background-color: #fff;
        border-radius: 50%;
        display: block;
        height: 6px;
        margin-left: 50%;
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
        width: 6px
    }

    .specsMobile table>tbody>tr>td>.token0 {
        margin: 4px 50% 0
    }

    .specsMobile table>tbody>tr>td>.token1 {
        margin: 2px 50%
    }

    .specsMobile table>tbody>tr>td>.token2 {
        margin: 0 50% 4px
    }

    .specsMobile table>tbody>tr>td.token {
        height: 15px;
        min-height: 15px
    }

    .specsMobile table tr>td:last-child,
    .specsMobile table tr>th:last-child {
        border-right: var(--spec-mobile-border)
    }

    .specsMobile table>tbody>tr.line-separator {
        height: 10px;
        min-height: 10px
    }

    .specsMobile table>tbody>tr.line-separator>td {
        border: none;
        border-bottom: var(--spec-mobile-border);
        border-top: var(--spec-mobile-border)
    }

    .specsMobile table>tbody>tr.line-separator>td.separator {
        border-bottom: none;
        border-top: none
    }

    .specsMobile table>tbody>tr>td.separator,
    .specsMobile table>thead>tr>th.separator {
        border-bottom: none;
        border-top: none;
        min-width: 8px;
        width: 8px
    }

    .specsMobile.mouvements>.flexContainer {
        padding-top: 25px
    }

    .specsMobile.measures>.flexContainer>.flexItem>.containerSpec>.labels {
        margin-top: 18px;
        max-width: 125px;
        min-width: 125px
    }

    .specsMobile.measures>.flexContainer>.flexItem.small table {
        margin: initial;
        max-width: 240px;
        width: 50%
    }

    .specsMobile.measures>.flexContainer>.flexItem.small table>tbody>tr>td,
    .specsMobile.measures>.flexContainer>.flexItem.small table>thead>tr>th {
        min-width: 50%;
        width: 50%
    }

    .specsMobile.measures>.flexContainer>.flexItem table>tbody>tr>td.no-border,
    .specsMobile.measures>.flexContainer>.flexItem table>thead>tr>th.no-border {
        border: 0;
        text-align: left
    }

    .specsMobile.measures>.flexContainer>.flexItem:last-child table tr>td.no-border {
        max-width: 125px;
        min-width: 125px;
        width: 125px
    }

    .specsDesktop {
        display: flex;
        min-height: 100%;
        overflow: hidden;
        padding: 15px 0 20px 20px
    }

    .specsDesktop>.SpecificationMember {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100px
    }

    .specsDesktop>table {
        width: 100%
    }

    .specsDesktop>.SpecificationMember>div {
        background: var(--light-bg-color);
        border-radius: 5px;
        cursor: pointer;
        margin: 10px 0;
        padding: 2px 5px;
        text-align: center;
        width: 100%
    }

    .specsDesktop .SpecificationTooth {
        padding: 10px;
        width: 50%
    }

    .specsDesktop .SpecificationTooth>table {
        border-collapse: separate;
        width: 100%
    }

    .specsDesktop .SpecificationTooth>table>thead>tr>th:not(:first-child) {
        min-width: 30px;
        padding: 3px
    }

    .specsDesktop .SpecificationTooth>table>tbody>tr {
        border: 0;
        border-spacing: 0;
        height: 20px;
        margin: 0;
        padding: 0
    }

    .specsDesktop .SpecificationTooth>table>tbody>tr>td:first-child {
        padding: 0 3px
    }

    .specsDesktop .SpecificationTooth>table>tbody>tr>td:not(:first-child) {
        height: 22px;
        padding: 3px;
        text-align: center
    }

    .specsDesktop .SpecificationTooth>table>tbody>tr:last-child>td:not(:first-child) {
        padding: 3px
    }

    .specsDesktop .SpecificationTooth>table>tbody>tr>td>.token {
        background-color: #fff;
        border-radius: 5px;
        display: block;
        height: 10px;
        margin: auto;
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
        width: 10px
    }

    .specsDesktop .SpecificationTooth>table>tbody>tr>td>.token0 {
        margin: 10px 50% 0
    }

    .specsDesktop .SpecificationTooth>table>tbody>tr>td>.token1 {
        margin: 5px 50%
    }

    .specsDesktop .SpecificationTooth>table>tbody>tr>td>.token2 {
        margin: 0 50% 10px
    }

    .specsDesktop .SpecificationTooth>table>tbody>.empty {
        height: 2px
    }

    .specsDesktop .SpecificationTooth>table>tbody>.att {
        height: 20px
    }

    .specsDesktop #globalMeasureTable,
    .specsDesktop #measuresTable,
    .specsDesktop #toothMeasures {
        border-collapse: separate;
        border-spacing: 20px 0;
        width: 100%
    }

    .specsDesktop #globalMeasureTable table>tbody>tr>td {
        padding: 0 10px
    }

    .specsDesktop.movements>table>tbody>tr>td {
        white-space: nowrap
    }

    .specsDesktop #globalMeasureTable>tbody>tr>td:first-child>div>table>tbody>tr>td {
        padding: 0 2px;
        text-align: left
    }

    .specsDesktop .measureTable,
    .specsDesktop .speMeasures {
        border: 1px solid;
    }

    .specsDesktop .boltonTable,
    .specsDesktop .speMeasure {
        display: inline-block;
        height: 100%;
        padding: 0 5px;
        vertical-align: top
    }

    .specsDesktop .speMeasure>table>tbody>tr>td:first-child {
        text-align: left
    }

    .specsDesktop .speMeasure>.measuresSum {
        padding-top: 10px
    }

    .specsDesktop .speMeasure,
    .specsDesktop .speMeasure>table {
        height: 100%
    }

    .specsDesktop .speMeasure>table>thead>tr>th,
    .specsDesktop .tableHead>tr>th {
        padding: 0 0 5px
    }

    .specsDesktop .measureTable,
    .specsDesktop .speMeasures {
        padding-bottom: 10px;
        padding-top: 30px;
        text-align: center;
        vertical-align: top
    }

    .specsDesktop .measureTable>h3,
    .specsDesktop .speMeasures>h3 {
        margin: 8px 0;
        position: absolute;
        top: 20px;
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        font-size: 1.17em;
    }

    .specsDesktop .speMeasures:first-child {
        width: 30%
    }

    .specsDesktop .speMeasures:nth-child(2) {
        vertical-align: top;
        width: 17%
    }

    .specsDesktop .measureTable {
        vertical-align: top;
        width: 15%
    }

    .specsDesktop .speMeasures:nth-child(3) {
        vertical-align: top;
        width: 35%
    }

    .specsDesktop .sumMeasure {
        display: block;
        padding: 10px 0 0
    }

    #ButtonSpec {
        bottom: 21px;
        left: 15px;
        position: absolute;
        z-index: 2
    }

    #ButtonSpec>button {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px
    }

    #ButtonSpec>button>svg {
        pointer-events: none;
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    #arrayButton {
        border: 1px solid var(--light-btn-color);
        border-radius: 3px;
        cursor: pointer;
        height: 30px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 30px
    }

    #arrayButton>div {
        background: var(--light-btn-color);
        height: 1px;
        margin-left: 4.5px;
        margin-top: 4.5px
    }

    #DotsSpec {
        align-items: center;
        bottom: 0;
        display: flex;
        height: 20px;
        justify-content: center;
        margin: auto auto 24px;
        position: absolute;
        width: 100%
    }

    #DotsSpec>.dot {
        border: .25px solid var(--light-btn-color);
        border-radius: 50%;
        box-sizing: border-box;
        cursor: pointer;
        height: 10px;
        margin: 0 10px;
        width: 10px
    }

    #DotsSpec>.dot.activated {
        background: var(--light-btn-color)
    }

    #Logo {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%
    }

    #Logo>div {
        border-radius: 5px;
        padding: 30px
    }

    #Logo>div h2 {
        margin: 0 0 20px 10px
    }

    #Logo button {
        padding: 5px 15px
    }

    #Project>div>table {
        width: 600px
    }

    #Project>div>table select {
        padding: 15px;
        width: 200px
    }

    .desc {
        vertical-align: baseline
    }

    .desc p {
        margin: 0
    }

    .valid {
        text-align: right
    }

    #LandscapeAlert {
        align-items: center;
        background-image: var(--gradient-bg);
        display: none;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 3
    }

    #LandscapeAlert,
    #Validation {
        height: 100%;
        text-align: center
    }

    #Validation {
        background-color: #fff;
        color: #000;
        font-size: 25px;
        padding: 50px
    }

    #Validation>* {
        margin-left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    #Validation>table {
        border-collapse: separate;
        border-spacing: 20px 0;
        font-size: 1rem;
        margin-top: 60px;
        min-width: 700px;
        text-align: left;
        width: 40%
    }

    #Validation>div {
        min-width: 600px;
        padding: 40px 20px;
        width: 25%
    }

    #ConsultSection {
        padding: 0 20px !important
    }

    #Validation>div>span {
        display: block;
        padding: 10px 40px
    }

    #Validation>div button {
        border: none;
        border-radius: 10px;
        color: #fff;
        font-size: 25px;
        min-width: 200px;
        padding: 20px
    }

    #consultButton {
        background-color: #000;
        width: 100%
    }

    #validButton {
        background-color: #499749;
        width: 48%
    }

    #refuseButton {
        background-color: #a5491f;
        width: 48%
    }

    #Validation>div>span:not(:first-child)>button:first-child {
        margin-right: 2%
    }

    #Validation>div>span:not(:first-child)>button:not(:first-child) {
        margin-left: 2%
    }

    #Validation>#disclamer {
        font-size: 15px
    }

    .arrow {
        border: solid grey;
        border-width: 0 1px 2px 0;
        display: inline-block;
        padding: 2px 3px
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg)
    }

    .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg)
    }

    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg)
    }

    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg)
    }

    #loader-1 #loader {
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        border-color: #3498db transparent;
        border-radius: 50%;
        border-style: solid;
        border-width: 3px;
        height: 20vw;
        left: 50%;
        margin: -10vw 0 0 -10vw;
        position: absolute;
        top: 50%;
        width: 20vw;
        z-index: 2
    }

    #loader-1 #loader:before {
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
        border: 3px solid transparent;
        border-radius: 50%;
        border-top-color: #db213a;
        bottom: 2%;
        content: "";
        left: 2%;
        position: absolute;
        right: 2%;
        top: 2%;
        z-index: 2
    }

    #loader-1 #loader:after {
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
        border: 3px solid transparent;
        border-radius: 50%;
        border-top-color: #dec52d;
        bottom: 5%;
        content: "";
        left: 5%;
        position: absolute;
        right: 5%;
        top: 5%;
        z-index: 2
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }

        50% {
            -webkit-transform: rotate(1turn);
            transform: rotate(180deg)
        }

        to {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn)
        }
    }

    @keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }

        50% {
            -webkit-transform: rotate(1turn);
            transform: rotate(180deg)
        }

        to {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn)
        }
    }

    #silverLogo {
        margin-right: -10px;
        margin-top: -5px;
        position: relative;
        width: 60px
    }

    #silverLogo.desktop {
        bottom: 12px;
        position: fixed;
        right: 12px
    }

    #silverLogo.mobile {
        display: block;
        float: left;
        position: relative
    }

    #silverLogo:before {
        content: "Powered by";
        font-size: 10px;
        left: -50px;
        position: absolute;
        top: 20px
    }

    #account {
        background-color: var(--dark-bg-color);
        padding: 10px;
        width: 100%
    }

    #account>div {
        width: 33%
    }

    #account>div:not(:first-child) {
        border-left: 1px solid var(--light-bg-color)
    }

    #account>div,
    #account>div>* {
        display: inline-block;
        padding: 5px;
        text-align: top;
        vertical-align: top
    }

    #account>div>div,
    #account>div>table>tbody>tr>td:not(:first-child) {
        padding-left: 20px
    }

    #account>div>div>button {
        background-color: var(--dark-bg-color);
        border: 1px solid var(--main-txt-color);
        border-radius: 5px;
        color: var(--main-txt-color);
        display: block;
        margin: 3px 50%;
        padding: 5px 10px;
        width: 110px
    }

    #connection>div>input {
        margin: 0 10px
    }

    #loginBtn {
        padding: 5px 10px
    }

    #HeaderTop {
        width: 100%
    }

    #accountTabs {
        border-top: 7px solid var(--dark-bg-color)
    }

    #accountTabs div {
        border-radius: 0 0 7px 7px;
        display: inline-block;
        padding: 5px 16px
    }

    #accountTabs>div#ConnexionInformation {
        float: right
    }

    #ConnexionInformation {
        cursor: pointer
    }

    #ViewportHeader {
        display: flex;
        justify-content: center;
        padding: 17px 17px 0;
        width: 100%
    }

    #PatientMenu {
        background: var(--light-btn-color);
        border: 1px solid var(--main-li-color);
        border-radius: 5px;
        height: 40px;
        min-width: 40px
    }

    #PatientMenu a {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%
    }

    #PatientResume tbody>tr>td:first-child {
        width: 62px
    }

    #PatientResume.desktop {
        border: 1px solid var(--main-li-color);
        border-radius: 5px;
        padding: 3px
    }

    #PatientResume.mobile {
        font-size: 11px;
        width: inherit
    }

    #PatientResume td.date {
        min-width: 80px;
        text-align: right
    }

    #viewModeSelector {
        margin-left: 20px
    }

    #viewModeSelector select {
        background: transparent;
        border: 1px solid var(--main-li-color);
        border-radius: 5px;
        color: var(--main-li-color);
        margin-top: 5px;
        padding: 3px
    }

    #viewActions {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        width: 100%
    }

    #viewActions>div>button {
        background-color: transparent;
        border: none;
        float: right;
        padding: 5px
    }

    #viewActions>div>button:first-child {
        padding-right: 0
    }

    #stepCounter.desktop {
        margin-left: 100px;
        padding: 5px 0
    }

    #stepCounter.mobile {
        font-size: 30px;
        padding-bottom: 10px;
        padding-top: 20px;
        text-align: center
    }

    #loadingScene {
        font-size: 16px;
        margin-top: -20px;
        text-align: center;
        top: 50%
    }

    #loadingScene,
    #Player {
        position: absolute;
        width: 100%
    }

    #Player {
        bottom: 0;
        display: flex;
        justify-content: center;
        margin: auto;
        padding-bottom: 14px;
        padding-top: 10px
    }

    #PlayerButtonsContainer,
    #PlayerResetContainer,
    #PlayerTimelineContainer {
        align-items: center;
        justify-content: center;
        position: relative
    }

    #PlayerTimelineContainer {
        width: 260px
    }

    #PlayerButtonsContainer {
        justify-content: space-between;
        width: 165px
    }

    #PlayerResetContainer {
        width: 26px
    }

    #Player button {
        align-items: center;
        background: none;
        border: 1px solid var(--light-btn-color);
        border-radius: 50%;
        display: flex;
        height: 33px;
        justify-content: center;
        margin: 2px;
        width: 33px
    }

    #Player #playButton {
        height: 52px;
        width: 52px
    }

    #Player #playButton svg {
        margin-left: 2px
    }

    #Player button svg {
        fill: var(--light-btn-color);
        overflow: visible;
        overflow: initial;
        width: 14px
    }

    #timeline {
        -webkit-appearance: none;
        background: transparent;
        background-image: linear-gradient(180deg, #fff, #999, #fff);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100% 25%;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        font: inherit;
        height: 10px;
        opacity: .8;
        outline: none;
        padding: 0;
        transition: opacity .2s;
        width: 100%
    }

    #timeline::-webkit-slider-runnable-track {
        background-color: transparent;
        border: none;
        border-radius: 0;
        height: 100%
    }

    #timeline::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: linear-gradient(#888, #fff) content-box, linear-gradient(-90deg, #888, #ddd) border-box;
        border: 1px solid #888;
        border-radius: 50%;
        box-shadow: inset 0 0 .5em #fff;
        height: 10px;
        padding: .25px;
        width: 10px
    }

    #timeline::-moz-range-track {
        background-color: transparent;
        border: none;
        border-radius: 0;
        height: 100%
    }

    #timeline::-moz-range-thumb {
        background: currentColor;
        background: var(--selected-btn-color);
        border: none;
        border-radius: 50%;
        height: 12px;
        padding: .25px;
        width: 12px
    }

    #timeline::-moz-range-progress {
        background: transparent;
        height: 0
    }

    #timeline::-ms-track {
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: transparent;
        height: 100%
    }

    #timeline::-ms-thumb {
        background: linear-gradient(#888, #fff) content-box, linear-gradient(-90deg, #888, #ddd) border-box;
        border: 1px solid #888;
        border-radius: 50%;
        box-shadow: inset 0 0 .5em #fff;
        height: 10px;
        padding: .25px;
        position: relative;
        width: 10px
    }

    #timeline::-ms-tooltip {
        display: none
    }

    #timeline::-ms-fill-lower,
    #timeline::-ms-fill-upper {
        background: transparent
    }

    #timelineOutput {
        position: absolute;
        top: 30px
    }

    #PlayerTimelineContainer:after,
    #PlayerTimelineContainer:before {
        font-size: 10px;
        position: absolute;
        top: -5px
    }

    #PlayerTimelineContainer:before {
        content: "INITIAL";
        left: 14px
    }

    #PlayerTimelineContainer:after {
        content: "SETUP";
        right: 14px
    }

    #timeline:after,
    #timeline:before {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #6868ac;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        top: 10px;
        width: 0
    }

    #timeline:before {
        left: 15px
    }

    #timeline:after {
        right: 15px
    }

    #RightPanel {
        bottom: 16px;
        padding-top: 24px;
        position: absolute;
        right: 0;
    }

    #RightPanel svg {
        fill: var(--main-txt-color);
        height: 44px;
        padding: 10px;
        width: 44px;

    }

    #RightPanel button {
        background: none;
        border: none
    }

    main {
        align-content: stretch
    }

    main,
    main>section {
        display: flex;
        height: 100%;
        position: relative;
        width: 100%
    }

    main>section {
        flex-direction: column
    }

    main>section>#viewer {
        height: calc(100vh - 150px)
    }

    main>section>#viewer>#sceneViewer {
        outline: none
    }

    main>section>#live,
    main>section>#viewer>#gnomonViewer {
        position: absolute;
        right: 50px;
        top: 70px
    }

    main>section>#live {
        height: 100%;
        text-align-last: end;
        width: 650px
    }

    main>nav {
        width: 50px
    }

    #specTabs {
        border-bottom: 7px solid var(--dark-bg-color);
        bottom: 0;
        flex-direction: row;
        margin-bottom: auto;
        position: relative;
        width: 100%
    }

    #specTabs>div {
        border-radius: 5px 5px 0 0;
        display: inline-block;
        margin: 0 2px;
        padding: 3px 15px;
        border: 0.1px dashed #4a2ca1 !important;
        border-bottom: 0 !important;
    }

    #footerContainer {
        background-image: var(--gradient-bg);
        height: 100%;
        left: 0;
        min-width: 320px;
        position: fixed;
        top: 0;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 100%
    }

    #dropzone {
        float: right;
        margin-right: 130px !important
    }

    #chat {
        float: right;
        margin-right: auto !important
    }

    header {
        align-content: flex-start
    }

    footer,
    header {
        display: flex;
        flex-direction: column
    }

    footer {
        align-content: flex-end;
        bottom: 0;
        margin-bottom: 0;
        position: absolute;
        width: 100%
    }

    th {
        font-weight: 400
    }

    @media (min-width:0px) {

        #PlayerResetContainer,
        #PlayerTimelineContainer {
            display: none
        }

        #PlayerButtonsContainer {
            display: flex
        }

        #HeaderTop,
        #viewModeSelector {
            display: none
        }
    }

    @media (min-width:768px) {
        main>section>#viewer {
            height: calc(100% - 240px)
        }

        #RightPanel {
            bottom: auto
        }

        #Player {
            justify-content: space-between;
            left: 50%;
            margin-bottom: 40px;
            margin-left: -210px;
            padding-bottom: 50px;
            width: 420px
        }

        #PlayerButtonsContainer,
        #PlayerResetContainer,
        #PlayerTimelineContainer {
            display: flex
        }

        #PlayerButtonsContainer {
            width: 100px
        }

        #Player button {
            height: 22px;
            width: 22px
        }

        #Player button svg {
            width: 10px
        }

        #Player #playButton {
            height: 40px;
            width: 40px
        }

        #ViewportHeader {
            padding: 20px 55px 0 40px
        }

        #viewModeSelector {
            display: inline;
            display: initial
        }

        #PatientResume {
            margin-left: 20px
        }

        #PatientResume tr td:first-child {
            width: 70px
        }

        #PatientResume tr:first-child td:nth-of-type(2) {
            position: absolute
        }

        #PatientResume tr:nth-of-type(2) td:nth-of-type(2) {
            min-width: 100px
        }

        #HeaderTop {
            display: inline-block
        }

        #footerContainer {
            background-color: #000;
            background-image: none;
            display: block;
            height: 230px;
            overflow: hidden;
            position: relative;
            -webkit-user-select: initial;
            -ms-user-select: initial;
            user-select: auto
        }

        .specsDesktop .SpecificationTooth>table {
            border-spacing: 0
        }

        .specsDesktop>table {
            font-size: 11px
        }

        .specsDesktop .SpecificationTooth>table>tbody>tr>td:last-child {
            border-right: 1px solid;
        }

        .specsDesktop .SpecificationTooth>table>tbody>tr>td:not(:first-child) {
            border-left: 1px solid;
        }

        .specsDesktop .SpecificationTooth>table>tbody>tr:last-child>td:not(:first-child) {
            border-bottom: 1px solid;
            ;
            border-left: 1px solid;
        }

        .specsDesktop .SpecificationTooth>table>tbody>.empty>td:not(:first-child) {
            border: 1px solid;
            ;
            border-left: 0;
            border-right: 0
        }

        .specsDesktop .SpecificationTooth>table>thead>tr>th:not(:first-child) {
            border: 1px solid;
            ;
            border-right: 0
        }

        .specsDesktop .SpecificationTooth>table>thead>tr>th:last-child {
            border-right: 1px solid;
        }
    }

    @media (min-width:950px) {
        .specsDesktop .SpecificationTooth>table {
            border-spacing: 5px 0
        }

        .specsDesktop>table {
            font-size: 12px
        }

        .specsDesktop .SpecificationTooth>table>tbody>tr>td:first-child {
            border-right: 0
        }

        .specsDesktop .SpecificationTooth>table>tbody>tr>td {
            border-right: 1px solid;
        }

        .specsDesktop .SpecificationTooth>table>thead>tr>th:not(:first-child) {
            border-bottom: 0;
            border-right: 1px solid;
        }
    }

    @media (min-width:1650px) {
        .specsDesktop>table {
            font-size: 13.5px
        }

        .specsDesktop.movements>table {
            font-size: 12px
        }

        .specsDesktop.measures {
            padding: 15px 0 20px
        }
    }

    @media (orientation:landscape) and (max-height:412px) and (max-width:812px) {
        #LandscapeAlert {
            display: flex
        }
    }

    :root {
        --light-bg-color: #3a3a3a;
        --dark-bg-color: #000;
        --light-btn-color: #cecece;
        --selected-btn-color: #00c3ff;
        --main-txt-color: #cecece;
        --dark-txt-color: #3a3a3a;
        --main-li-color: #e9e9e9;
        --dark-li-color: #e9e9e993;
        --medium-border-color: #444;
        --spec-mobile-border: 1px solid var(--medium-border-color);
        --gradient-bg: linear-gradient(#000, #222)
    }

    input {
        -moz-appearance: initial
    }

    .black {
        background-color: #000 !important;
        background-color: var(--dark-bg-color) !important;
        color: #cecece !important;
        color: var(--main-txt-color) !important
    }

    .selected {
        background: #4a2ca1 !important;
        color: #3a3a3a !important;
        color: var(--dark-txt-color) !important
    }

    .full-width {
        width: 100% !important
    }

    .rightAlign {
        float: right
    }

    .leftAlign {
        float: left
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        text-align: left;
        color: #fff;
    }

    #root,
    .App,
    body,
    html {
        height: 100%;
        min-width: 320px
    }

    .App {
        cursor: default;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: linear-gradient(#000, #222);
    background-image: var(--gradient-bg);
    color: #cecece;
    color: var(--main-txt-color);
    font-family: -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
    font-size: 90%;
    margin: 0;

    overflow: hidden code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
    }

    .pointer,
    button,
    select {
        cursor: pointer
    }

    #HeaderTop {
        display: none;
    }

    #PatientMenu {
        display: none;
    }

    #PatientResume {
        display: none;
    }


    #RightPanel {
        button {
            svg path {
                fill: #fff;
            }
        }
    }

    #Player {
        svg {
            fill: #fff !important;
        }
    }

    #viewActions {
        svg path {
            stroke: #fff;
        }
    }
}

.kivenView #timeline {
    -webkit-appearance: none;
    background: 0 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(#999), to(#fff));
    background-image: linear-gradient(180deg, #ffffff, #ffffff, #ffffff);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 25%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font: inherit;
    height: 10px;
    opacity: .8;
    outline: 0;
    padding: 0;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    width: 100%;
}

.kivenView {
    background: none;
    position: relative;
    z-index: 1;
    color: #fff;
}

.kivenView #footerContainer {
    background-color: #31315b;
    color: #fff;
}

.kivenView #Player {
    padding-bottom: 0;
}

@media (min-width: 768px) {
    .kivenView main>section>#viewer {
        height: calc(100% - 180px);
    }
}

.kivenView #Logo {

    .loading-container {
        min-height: 100vh;
        min-width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-container .loading {
        height: 200px;
        width: 200px;
        border-radius: 50%;
        border-style: solid;
        border-width: var(--border-width);
        border-color: transparent #f7f7fb transparent #f6f6f9;
        position: relative;
    }

    .loading-container .loading::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: calc(100%);
        width: calc(100%);
        border-radius: 50%;
        border-style: solid;
        border-width: calc(66px);
        box-sizing: border-box;
        border-color: #ffffff transparent #ffffff transparent;
        animation: animate 3s ease-in-out infinite backwards
    }

    @keyframes animate {
        0% {
            transform: translate(-50%, -50%) rotate(-90deg);
        }

        50% {
            transform: translate(-50%, -50%) rotate(90deg);
        }

        100% {
            transform: translate(-50%, -50%) rotate(-90deg);
        }

    }
}


.NotPeermstion {

    h1 {
        font-size: 30vw;
        text-align: center;
        position: fixed;
        width: 81%;
        z-index: 1;
        color: #ffffff26;
        top: 50%;
        transform: translateY(-50%);
    }

    div {
        background: rgba(0, 0, 0, 0);
        width: 70vw;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        padding: 30px 30px 10px;
        box-shadow: 0 0 150px -20px rgba(0, 0, 0, 0.5);
        z-index: 3;
    }

    P {
        font-family: "Share Tech Mono", monospace;
        color: #f5f5f5;
        margin: 0 0 20px;
        font-size: 17px;
        line-height: 1.2;
    }

    span {
        color: #f0c674;
    }

    i {
        color: #8abeb7;
    }

    div a {
        text-decoration: none;
    }

    b {
        color: #81a2be;
    }

    a.avatar {
        position: fixed;
        bottom: 15px;
        right: -100px;
        animation: slide 0.5s 4.5s forwards;
        display: block;
        z-index: 4
    }

    a.avatar img {
        border-radius: 100%;
        width: 44px;
        border: 2px solid white;
    }

    @keyframes slide {
        from {
            right: -100px;
            transform: rotate(360deg);
            opacity: 0;
        }

        to {
            right: 15px;
            transform: rotate(0deg);
            opacity: 1;
        }
    }

    height: 78vh;
}


#appointment {
    .nav {
        padding: 10px;
        user-select: none;
        position: relative;
        display: block;

        .items {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .item {
            border: 1px solid #7d7dbb80;
            cursor: pointer;
            margin-top: 10px;
            text-align: center;
            border-radius: 10px;
            padding: 10px;
            min-width: 100px;
            margin-left: 10px;

        }

        .item:hover {
            background-color: rgb(104, 104, 172);
            color: #fff;
        }

        .Active {
            background: rgb(104, 104, 172);
            color: #fff;
        }
    }
}

.cbct {
    .kiven.SucsessFile {
        display: none;
    }

    &:hover {
        .kiven.SucsessFile {
            position: absolute;
            top: 0;
            left: 11px;
            width: 90%;
            height: 99%;
            text-align: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: inline-flex !important;
            display: inline-flex;
            border-radius: 11px;
            background: #00000078;
            justify-content: center;

            .btnSuccessfuly {
                background: green;
                color: #fff;
                padding: 5px 13px;
                border-radius: 5px;
                margin-right: 15px;
                border: 1px solid;
                position: relative;
                z-index: 99999999999;
                cursor: pointer;
            }

            .btnRemove {
                background: rgb(201, 4, 37);
                color: #fff;
                padding: 5px 13px;
                border-radius: 5px;
                border: 1px solid;
                position: relative;
                z-index: 99999999999;
                cursor: pointer;
            }

        }
    }
}